import React from "react";

type T = HTMLDivElement;

const handleCopyToClipboard = async (elementRef: React.RefObject<T>) => {
  if (elementRef.current) {
    const tempElement = document.createElement("div");
    tempElement.style.position = "absolute";
    tempElement.style.left = "-9999px";
    tempElement.innerHTML = elementRef.current.innerHTML;
    document.body.appendChild(tempElement);

    const range = document.createRange();
    range.selectNodeContents(tempElement);
    const selection = window.getSelection();
    if (selection) {
      selection.removeAllRanges();
      selection.addRange(range);

      try {
        await navigator.clipboard.write([
          new ClipboardItem({
            "text/html": new Blob([tempElement.innerHTML], {
              type: "text/html",
            }),
            "text/plain": new Blob([tempElement.innerText], {
              type: "text/plain",
            }),
          }),
        ]);
      } catch (err) {
        alert(`Failed to copy text; error: ${err}`);
      }

      selection.removeAllRanges();
    }

    document.body.removeChild(tempElement);
  }
};

export default handleCopyToClipboard;
