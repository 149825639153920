import type { ProColumns, RowEditableConfig } from "@ant-design/pro-components";
import { EditableProTable } from "@ant-design/pro-components";
import { GetRowKey, TableLocale } from "antd/es/table/interface";
import React from "react";

interface EditableTableProps<T> {
  className?: string | undefined;
  rowKey?: string | keyof T | GetRowKey<T> | undefined;
  showHeader?: boolean | undefined;
  tableStyle?: React.CSSProperties | undefined;
  tableLayout?: "auto" | "fixed" | undefined;
  loading?: boolean | undefined;
  columns?: ProColumns<T, "text">[] | undefined;
  value?: readonly T[] | undefined;
  locale?: TableLocale | undefined;
  editable?: RowEditableConfig<T> | undefined;
  recordCreatorProps?: any;
}

const EditableTable = <T extends Record<string, any>>(
  props: EditableTableProps<T>
) => (
  <EditableProTable<T>
    className={props.className}
    rowKey={props.rowKey}
    showHeader={props.showHeader}
    tableStyle={props.tableStyle}
    tableLayout={props.tableLayout}
    loading={props.loading}
    recordCreatorProps={props.recordCreatorProps}
    columns={props.columns}
    locale={props.locale}
    editable={props.editable}
    value={props.value}
  />
);

export default EditableTable;
