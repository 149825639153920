import React, { useContext } from "react";
import DOMPurify from "dompurify";
import { FaCircle } from "react-icons/fa";
import { FaUserPlus } from "react-icons/fa6";
import { Button } from "antd";
import { IoIosMore } from "react-icons/io";
import Dropdown from "react-bootstrap/Dropdown";
import {
  MdOutlineMarkEmailRead,
  MdOutlineMarkEmailUnread,
} from "react-icons/md";
import { LuMailX } from "react-icons/lu";

import styles from "./TeamInvitationDeclinedNotification.module.css";
import Notification_ from "../../data/Notification_";
import MainContext from "../../contexts/MainContext";
import timeSince from "../../helpers/timeSince";

interface TeamInvitationDeclinedNotificationProps {
  notification: Notification_;
}

const TeamInvitationDeclinedNotification: React.FC<
  TeamInvitationDeclinedNotificationProps
> = ({ notification }) => {
  const mainContext = useContext(MainContext);
  const notificationName = DOMPurify.sanitize(notification.name);
  const notificationDescription = DOMPurify.sanitize(notification.description);

  return (
    <div
      onClick={(e) => {
        if (notification.active)
          mainContext?.updateNotificationsStatus([notification.id], false);
        e.stopPropagation();
      }}
      className={styles.content}>
      <div className={styles.left_icon}>
        <FaUserPlus
          style={{ color: "var(--deloitte-green)", width: 30, height: 30 }}
        />
      </div>
      <div className={styles.body} key={notification.id}>
        <div className={styles.title}>
          <div
            className={styles.title_name}
            dangerouslySetInnerHTML={{ __html: notificationName }}
          />
        </div>
        <div style={{ height: 6 }} />
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{ __html: notificationDescription }}
        />
        <div style={{ height: 6 }} />
        <div className={styles.time}>{timeSince(notification.updated_at)}</div>
      </div>
      <div className={styles.actions}>
        <Dropdown>
          <Dropdown.Toggle
            id="dropdown-custom-components"
            as={React.forwardRef<
              HTMLButtonElement,
              {
                onClick?: React.MouseEventHandler<HTMLElement>;
                children?: React.ReactNode;
              }
            >(({ children, onClick }, ref) => (
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  onClick && onClick(e);
                }}
                ref={ref}
                type="text"
                icon={<IoIosMore />}>
                {children}
              </Button>
            ))}></Dropdown.Toggle>

          <Dropdown.Menu
            style={{ backgroundColor: "#1f1f1f", border: "1px solid #9c9c9c" }}>
            <Dropdown.Item
              as={React.forwardRef<
                HTMLButtonElement,
                {
                  onClick?: React.MouseEventHandler<HTMLElement>;
                  children?: React.ReactNode;
                }
              >(({ children, onClick }, ref) => (
                <Button
                  ref={ref}
                  icon={
                    notification.active ? (
                      <MdOutlineMarkEmailRead />
                    ) : (
                      <MdOutlineMarkEmailUnread />
                    )
                  }
                  style={{
                    display: "flex",
                    borderRadius: 0,
                    width: "100%",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                  type="text"
                  onClick={(e) => {
                    e.preventDefault();
                    onClick && onClick(e);
                  }}>
                  {children}
                </Button>
              ))}
              onClick={
                notification.active
                  ? () =>
                      mainContext?.updateNotificationsStatus(
                        [notification.id],
                        false
                      )
                  : () =>
                      mainContext?.updateNotificationsStatus(
                        [notification.id],
                        true
                      )
              }>
              {notification.active ? (
                <div>Mark as read</div>
              ) : (
                <div>Mark as unread</div>
              )}
            </Dropdown.Item>
            <Dropdown.Item
              as={React.forwardRef<
                HTMLButtonElement,
                {
                  onClick?: React.MouseEventHandler<HTMLElement>;
                  children?: React.ReactNode;
                }
              >(({ children, onClick }, ref) => (
                <Button
                  ref={ref}
                  icon={<LuMailX />}
                  style={{
                    display: "flex",
                    borderRadius: 0,
                    width: "100%",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                  type="text"
                  onClick={(e) => {
                    e.preventDefault();
                    onClick && onClick(e);
                  }}>
                  {children}
                </Button>
              ))}
              onClick={() => mainContext?.deleteNotification(notification.id)}>
              Remove this notification
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      {notification.active ? (
        <div className={styles.right_status}>
          <FaCircle
            style={{
              width: 8,
              height: 8,
              color: notification.active
                ? "var(--deloitte-green)"
                : "#898989ab",
            }}
          />
        </div>
      ) : undefined}
    </div>
  );
};

export default TeamInvitationDeclinedNotification;
