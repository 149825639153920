import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import type { ProColumns } from "@ant-design/pro-components";
import { Button, Empty, Popconfirm, Tooltip } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { MdDeleteOutline, MdEdit } from "react-icons/md";

import TeamsContext from "../../../../../../contexts/TeamsContext";
import TypesContext from "../../../../../../contexts/ToolInputsContext";
import { ObligationContractType } from "../../../../../../data/PredefinedToolInputs";
import equalObjects from "../../../../../../helpers/equalObjects";
import generateUUID from "../../../../../../helpers/generateUUID";
import CreateEditContractTypeModal from "../CreateEditContractTypeModal/CreateEditContractTypeModal";
import EditableTable from "../EditableTable/EditableTable";
import styles from "./ObligationsExtractorInputs.module.css";

import * as TypesService from "../../services/predefinedData.service";

interface DataSourceType {
  key: React.Key;
  id: React.Key;
  obligation: string;
  description: string;
  children?: DataSourceType[];
}

const ObligationExtractorInputs: React.FC<{}> = (_) => {
  const teamsContext = useContext(TeamsContext);
  const typesContext = useContext(TypesContext);
  const [isLoading, setIsLoading] = useState(true);
  const [isTableLoading, setIsTableLoading] = useState<{
    [key: string]: boolean;
  }>({});
  const [editableKeys, setEditableRowKeys] = useState<React.Key[]>([]);

  const [showCreateEditContractTypeModal, setShowCreateEditContractTypeModal] =
    useState(false);
  const [modalContractType, setModalContractType] = useState<
    ObligationContractType | undefined
  >(undefined);

  const isAdmin = () => {
    return teamsContext?.teamSelected?.user_role === "Admin";
  };

  const columns: ({
    obligationContractTypeId,
  }: {
    obligationContractTypeId: React.Key;
  }) => ProColumns<DataSourceType>[] = ({
    obligationContractTypeId,
  }: {
    obligationContractTypeId: React.Key;
  }) => [
    {
      title: "Obligation",
      dataIndex: "obligation",
      valueType: "text",
      formItemProps: () => ({
        rules: [
          {
            required: true,
            max: 100,
            message: "Invalid input: between 0 - 100 characters.",
          },
        ],
      }),
      fieldProps: () => ({ placeholder: "Obligation name" }),
      width: "20vw",
    },
    {
      title: "Description",
      dataIndex: "description",
      valueType: "text",
      formItemProps: () => ({
        rules: [
          { max: 20000, message: "Invalid input: max 20000 characters." },
        ],
      }),
      fieldProps: () => ({ placeholder: "Obligation description" }),
      width: "40vw",
    },
    {
      title: "Actions",
      valueType: "option",
      width: "10vw",
      render: (text, record, _, action) => [
        <Tooltip key={`${record.id}_edit`} title="Edit" placement="left">
          <Button
            type="link"
            icon={<MdEdit color="var(--deloitte-green)" size="22" />}
            onClick={(e) => {
              e.preventDefault();
              action?.startEditable?.(record.id);
            }}
          />
        </Tooltip>,
        <Tooltip key={`${record.id}_delete`} title="Delete" placement="right">
          <Popconfirm
            title="Are you sure you want to delete this item?"
            okText="Yes"
            cancelText="No"
            onConfirm={async () => {
              handleTableLoading(obligationContractTypeId, true);
              await TypesService?.deleteObligationType(record.id.toString());
              typesContext?.fetchObligationContractTypes(
                teamsContext?.teamSelected?.id!
              );
              handleTableLoading(obligationContractTypeId, false);
            }}>
            <Button
              type="link"
              icon={<MdDeleteOutline color="var(--beautiful-red)" size="22" />}
            />
          </Popconfirm>
        </Tooltip>,
      ],
      hidden: !isAdmin(),
    },
  ];

  useEffect(() => {
    const fetchToolsInput = async () => {
      await typesContext?.fetchObligationContractTypes(
        teamsContext?.teamSelected?.id!
      );
      setIsLoading(false);
    };
    setIsLoading(true);
    fetchToolsInput();
  }, [teamsContext?.teamSelected]);

  const handleTableLoading = (id: React.Key, loading: boolean) => {
    setIsTableLoading((prevState) => ({
      ...prevState,
      [id.toString()]: loading,
    }));
  };

  return isLoading ? (
    <div
      style={{
        flex: 1,
        display: "flex",
        alignSelf: "center",
        justifyContent: "center",
      }}>
      <LoadingOutlined
        style={{ fontSize: 20, color: "var(--deloitte-green)" }}
      />
    </div>
  ) : (
    <div className={styles.content}>
      {isAdmin() ? (
        <div className={styles.add_contract_type}>
          <Button
            onClick={() => {
              setModalContractType(undefined);
              setShowCreateEditContractTypeModal(true);
            }}
            icon={<PlusOutlined />}
            type="primary">
            Add document type
          </Button>
        </div>
      ) : undefined}
      {typesContext?.obligationContractTypes.map((obligationContractType) => (
        <div
          key={obligationContractType.id}
          className={styles.contract_type_item_content}>
          <div className={styles.contract_type_item_title}>
            <div>{obligationContractType.name}</div>
            {isAdmin() ? (
              <Tooltip title="Edit document type name" placement="top">
                <Button
                  type="link"
                  style={{ marginLeft: 16 }}
                  icon={<MdEdit color="var(--deloitte-green)" size="22" />}
                  onClick={() => {
                    setModalContractType(obligationContractType);
                    setShowCreateEditContractTypeModal(true);
                  }}
                />
              </Tooltip>
            ) : undefined}
            {isAdmin() ? (
              <Tooltip title="Delete document type" placement="top">
                <Popconfirm
                  title="Are you sure you want to delete this item?"
                  okText="Yes"
                  cancelText="No"
                  onConfirm={async () => {
                    await TypesService?.deleteObligationContractType(
                      obligationContractType.id
                    );
                    typesContext?.fetchObligationContractTypes(
                      teamsContext?.teamSelected?.id!
                    );
                  }}>
                  <Button
                    type="link"
                    icon={
                      <MdDeleteOutline color="var(--beautiful-red)" size="22" />
                    }
                  />
                </Popconfirm>
              </Tooltip>
            ) : undefined}
          </div>
          <EditableTable<DataSourceType>
            className={styles.contract_type_item}
            rowKey="id"
            showHeader
            tableStyle={{ top: 30 }}
            tableLayout="fixed"
            loading={isTableLoading[obligationContractType.id] || false}
            recordCreatorProps={
              isAdmin()
                ? {
                    position: "bottom",
                    record: () => ({
                      id: generateUUID(),
                    }),
                    creatorButtonText: "Add new obligation type",
                  }
                : false
            }
            columns={columns({
              obligationContractTypeId: obligationContractType.id,
            })}
            value={obligationContractType.obligation_types.map(
              (obligationType, index) => ({
                key: index,
                id: obligationType.id,
                obligation: obligationType.name,
                description: obligationType.description,
              })
            )}
            editable={{
              type: "multiple",
              editableKeys: editableKeys,
              onSave: async (key, data, row) => {
                const isNew = row.key === undefined;
                if (isNew) {
                  handleTableLoading(obligationContractType.id, true);
                  await TypesService?.createObligationType(
                    obligationContractType.id,
                    { name: data.obligation, description: data.description }
                  );

                  typesContext?.fetchObligationContractTypes(
                    teamsContext?.teamSelected?.id!
                  );
                  handleTableLoading(obligationContractType.id, false);
                  return;
                }
                if (equalObjects(data, row)) return;
                handleTableLoading(obligationContractType.id, true);
                await TypesService?.editObligationType(data.id.toString(), {
                  name: data.obligation,
                  description: data.description,
                });
                typesContext?.fetchObligationContractTypes(
                  teamsContext?.teamSelected?.id!
                );
                handleTableLoading(obligationContractType.id, false);
              },
              onChange: setEditableRowKeys,
              onlyAddOneLineAlertMessage:
                "Please add only one item per contract type at a time.",
              cancelText: <div style={{ color: "#c7c7c7" }}>Cancel</div>,
              saveText: (
                <div style={{ color: "var(--deloitte-green)" }}>Save</div>
              ),
              actionRender: (_, __, dom) => [dom.save, dom.cancel],
            }}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="No obligations in this contract type"
                />
              ),
            }}
          />
        </div>
      ))}
      <CreateEditContractTypeModal
        isOpen={showCreateEditContractTypeModal}
        closeModal={async () => {
          typesContext?.fetchObligationContractTypes(
            teamsContext?.teamSelected?.id!
          );
          setShowCreateEditContractTypeModal(false);
        }}
        contractTypeToEdit={modalContractType}
        createContractTypeFn={TypesService.createObligationContractType}
        editContractTypeFn={
          TypesService.editObligationContractType
        }></CreateEditContractTypeModal>
    </div>
  );
};

export default ObligationExtractorInputs;
