import React, { useContext, ReactNode } from "react";
import ToolsContext from "../../../../../../contexts/ToolsContext";
import { ToolTypes } from "../../../../../../enums/toolTypes.enum";

import styles from "./ToolButton.module.css";
import { useNavigate } from "react-router-dom";

interface ToolButtonProps {
  toolType: ToolTypes;
  icon: ReactNode;
  title: string;
  inDevMode?: boolean;
}

const ToolButton: React.FC<ToolButtonProps> = ({
  toolType,
  icon,
  title,
  inDevMode,
}) => {
  const toolsContext = useContext(ToolsContext);

  const onToggleTool = () => {
    toolsContext?.setSelectedTools((prev) =>
      prev.includes(toolType)
        ? prev.filter((tool) => tool !== toolType)
        : [...prev, toolType]
    );
  };

  return (
    <div
      className={`${inDevMode ? styles.tool_button_in_dev : ""} ${
        styles.tool_button
      } ${
        toolsContext?.selectedTools.includes(toolType)
          ? styles.tool_button_selected
          : ""
      }`}
      onClick={(event) => {
        onToggleTool();
        event.stopPropagation();
      }}
    >
      {icon}
      <p>{title}</p>
    </div>
  );
};

export default ToolButton;
