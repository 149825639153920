import { LoadingOutlined } from "@ant-design/icons";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import MainContext from "../../../../contexts/MainContext";
import styles from "./SideMenu.module.css";
import ProjectsList from "./components/ProjectList/ProjectsList";
import TeamSelector from "./components/TeamSelector/TeamSelector";

import { FaPlus } from "react-icons/fa";
import Project from "../../../../data/Project";
import CreateEditProjectModal from "./components/CreateEditProjectModal/CreateEditProjectModal";
import SiteTitle from "./components/SiteTitle/SiteTitle";

interface SideMenuProps {
  sideMenuIsOpen: boolean;
  onSideMenuToogle: () => void;
  pageIsLoading: boolean;
}

const SideMenu: React.FC<SideMenuProps> = ({
  onSideMenuToogle,
  sideMenuIsOpen,
  pageIsLoading,
}) => {
  const mainContext = useContext(MainContext);

  const navigate = useNavigate();

  const [showCreateEditProjectModal, setShowCreateEditProjectModal] =
    useState(false);

  const [modalProject, setModalProject] = useState<Project | undefined>(
    undefined
  );

  return (
    <>
      <div
        className={`${styles.side_menu} ${!sideMenuIsOpen && styles.closed}`}
      >
        <SiteTitle
          onSideMenuToogle={onSideMenuToogle}
          sideMenuIsOpen={sideMenuIsOpen}
        ></SiteTitle>

        {sideMenuIsOpen && (
          <div className={styles.menu_content}>
            {pageIsLoading ? (
              <div className={styles.loading_container}>
                <LoadingOutlined className={styles.loader_icon} />
              </div>
            ) : mainContext?.teams.length === 0 ? (
              <div className={styles.teams_empty}>
                <p className={styles.teams_empty_text}>
                  You do not have any teams yet
                </p>
                <div
                  onClick={() => {
                    navigate("/teams");
                  }}
                  color="#86bc25"
                  className={styles.manage_my_teams_anchor}
                >
                  Manage my teams
                </div>
              </div>
            ) : (
              <>
                <div
                  className={styles.new_project_button}
                  onClick={(e) => {
                    setModalProject(undefined);
                    setShowCreateEditProjectModal(true);
                  }}
                >
                  New project
                  <FaPlus className={styles.new_project_button_icon} />
                </div>

                <div className={styles.menu_list}>
                  <ProjectsList
                    onEdit={(p: Project) => {
                      setModalProject(p);
                      setShowCreateEditProjectModal(true);
                    }}
                  />
                </div>

                <TeamSelector />
              </>
            )}
          </div>
        )}
      </div>

      <CreateEditProjectModal
        isOpen={showCreateEditProjectModal}
        closeModal={() => {
          setShowCreateEditProjectModal(false);
        }}
        projectToEdit={modalProject}
      ></CreateEditProjectModal>
    </>
  );
};

export default SideMenu;
