import React, { createContext, ReactNode, useState, useEffect } from "react";
// import { Location, useNavigate, useLocation } from "react-router-dom";
import getCookie from "../helpers/getCookie";
import User from "../data/User";
import InitialLayout from "../layouts/InitialLayout";

interface AuthContextType {
  user: User | undefined;
  logout: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);
const backendUrl = process.env.REACT_APP_API_URL ?? "";

interface AuthProviderProps {
  children: ReactNode;
 }

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<User | undefined>(undefined);
  const [userAcknowledged, setUserAcknowledged] = useState<boolean>(false);
  // const [redirectPath, setRedirectPath] = useState<string | null>('/results');

  const csrfToken = getCookie("csrftoken");
  // const navigate = useNavigate();
  // const location: Location = useLocation();

  // useEffect(() => {
  //   if (!userAcknowledged) {
  //     // Capture the current path before showing the initial layout
  //     setRedirectPath(location.pathname);
  //   }
  // }, [location.pathname, userAcknowledged]);

  useEffect(() => {
    const auth = async () => {
      const response = await fetch(`${backendUrl}/api/auth/`, {
        method: "POST",
        credentials: "include",
        headers: { "X-CSRFToken": csrfToken ?? "" },
      });

      if (response.status === 403) {
        const body = await response.json();
        if (body.detail === 'You are not allowed to access this resource') {
          alert('You are blocked from the usage of the service. Contact Michal Morrison for operative support.');
        }
        // Get the current path from location
        // Remove the usage of location as it's not defined in this context
        const currentPath = window.location.pathname;
        // Append the current path to the redirect URI
        const redirectUri = encodeURIComponent(currentPath);
        window.location.href = "/.auth/login/aad?post_login_redirect_uri=" + redirectUri;
        return;
      }

      const body: {
        id: string | number;
        profile_picture: string | null | undefined;
        user: {
          username: string;
          first_name: string;
          last_name: string;
          email: string;
        };
      } = await response.json();

      setUser({
        id: body.id,
        email: body.user.email ? body.user.email : "Unknown",
        firstname: body.user.first_name ? body.user.first_name : "Unknown",
        lastname: body.user.last_name ? body.user.last_name : "Unknown",
        fullname: `${body.user.last_name ? body.user.last_name : "Unknown"}, ${body.user.first_name ? body.user.first_name : "Unknown"
          }`,
        username: body.user.username ? body.user.username : "Unknown",
        profilePicture: body.profile_picture,
      });
    };

    auth();
  }, []);

  // const handleAcknowledgment = () => {
  //   setUserAcknowledged(true);
  //   if (redirectPath) {
  //     navigate(redirectPath);
  //   }
  // };

  const logout = async () => {
    await fetch(`${backendUrl}/api/logout/`, {
      method: "POST",
      credentials: "include",
      headers: { "X-CSRFToken": csrfToken ?? "" },
    });
    window.location.href = "/.auth/logout";
  };

  return (
    <AuthContext.Provider value={{ logout, user }}>
      {/* {user && userAcknowledged ? (
        children
      ) : (
        <InitialLayout
          setUserAcknowledged={handleAcknowledgment}
          loading={user ? false : true}
        />
      )} */}
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;