import React, { ReactNode, createContext, useState } from "react";

interface ResultsContextType {
  fetchingToggle: boolean;
  setFetchingToggle: (callback: (prevState: boolean) => boolean) => void;
}

const ResultsContext = createContext<ResultsContextType | undefined>(undefined);

interface ResultsProviderProps {
  children: ReactNode;
}

export const ResultsProvider: React.FC<ResultsProviderProps> = ({
  children,
}) => {
  const [fetchingToggle, setFetchingToggle] = useState<boolean>(false);

  return (
    <ResultsContext.Provider
      value={{
        fetchingToggle,
        setFetchingToggle,
      }}
    >
      {children}
    </ResultsContext.Provider>
  );
};

export default ResultsContext;
