import React, { useState } from "react";

import { InboxOutlined } from "@ant-design/icons";
import { Form, Input, Modal, Upload } from "antd";
import { UploadFile } from "antd/lib/upload/interface";
import styles from "./AddDraftingTemplateModal.module.css";

interface _AddDraftingTemplateModalProps {
  isModalVisible: any;
  setIsModalVisible: any;
  onSubmit: (
    name: string,
    description: string,
    file: UploadFile,
    baseline?: string
  ) => void;
}

const AddDraftingTemplateModal: React.FC<_AddDraftingTemplateModalProps> = ({
  isModalVisible,
  setIsModalVisible,
  onSubmit,
}) => {
  const [form] = Form.useForm();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [fileUploaded, setFileUploaded] = useState<UploadFile<any>[]>([]);

  const handleOk = () => {
    form
      .validateFields()
      .then(async (_) => {
        onSubmit(name, description, fileUploaded[0]);
        setIsModalVisible(false);
        form.resetFields();
      })
      .catch((_) => {});
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  return (
    <Modal
      title="Add drafting template"
      open={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      okText="Submit"
      cancelText="Cancel"
      width={800}
    >
      <Form form={form} layout="vertical" name="form_in_modal">
        <Form.Item
          name="name"
          label="Name"
          rules={[
            { required: true, message: "Please input your name!" },
            { max: 100, message: "Name cannot exceed 100 characters!" },
          ]}
        >
          <Input
            value={name}
            placeholder="Input template name"
            onChange={(e) => {
              setName(e.target.value);
            }}
            maxLength={100}
          />
        </Form.Item>
        <Form.Item
          name="description"
          label="Description"
          rules={[
            {
              max: 20000,
              message: "Description cannot exceed 20000 characters!",
            },
          ]}
        >
          <Input
            value={description}
            placeholder="Input template description"
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            maxLength={20000}
          />
        </Form.Item>
        <Form.Item
          name="upload"
          label="Upload template"
          valuePropName="fileList"
          getValueFromEvent={(e) => (Array.isArray(e) ? e : e && e.fileList)}
          rules={[{ required: true, message: "Please upload a file!" }]}
        >
          <Upload.Dragger
            multiple={false}
            maxCount={1}
            name="file"
            fileList={fileUploaded}
            onChange={({ file, fileList }) => {
              const latestFileList = file ? [file] : [];
              setFileUploaded(latestFileList);
            }}
            onRemove={() => {
              console.log("remove");
              setFileUploaded([]);
            }}
            customRequest={({ file, onSuccess }) => {
              setTimeout(() => {
                if (onSuccess) onSuccess("ok");
              }, 0);
            }}
            beforeUpload={() => false}
            showUploadList={{ showRemoveIcon: true }}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag a template file to this area to upload
            </p>
          </Upload.Dragger>
        </Form.Item>
      </Form>
      <div className={styles.description}>
        Please take into considaration that template processing may take some
        time while loading it.
      </div>
    </Modal>
  );
};

export default AddDraftingTemplateModal;
