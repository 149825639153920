import { UploadedFile } from "../interfaces/uploadedFile.interface";
import { UploadFile } from "antd/lib/upload/interface";
import { GetProp, UploadProps } from "antd";
import Project from "../../../data/Project";

const backendUrl = process.env.REACT_APP_API_URL ?? "";
type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];

export const fetchFiles = async (projectId: string | number) => {
  const response = await fetch(`${backendUrl}/api/files/${projectId}`, {
    method: "GET",
    credentials: "include",
  });
  const body = await response.json();

  const fetchedFiles = body.sort((a: UploadedFile, b: UploadedFile) => {
    const dateA = new Date(a.created_at);
    const dateB = new Date(b.created_at);
    return dateB.getTime() - dateA.getTime();
  });

  return fetchedFiles;
};

export const deleteFiles = async (
  csrfToken: string,
  ids: Array<string | number>
) => {
  await fetch(`${backendUrl}/api/delete_files/`, {
    method: "DELETE",
    credentials: "include",
    headers: {
      "X-CSRFToken": csrfToken ?? "",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ ids }),
  });
};

export const createFiles = async (
  csrfToken: string,
  projectId: string | number,
  files: File[],
  isAnonymise: boolean,
  isHandwritten: boolean,
  isProcessingEnabled: boolean
) => {
  const formData = new FormData();

  formData.append("project", projectId.toString());
  formData.append("isAnonymise", isAnonymise.toString());
  formData.append("isHandwritten", isHandwritten.toString());
  formData.append("isProcessingEnabled", isProcessingEnabled.toString());
  files.forEach((file) => {
    formData.append("original_file[]", file);
  });

  await fetch(`${backendUrl}/api/create_files/`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRFToken": csrfToken ?? "",
    },
    body: formData,
  });
};

export const extractMetadata = async (
  csrfToken: string,
  ids: Array<string | number>,
  metadata: Array<{
    metadata_parameter: string;
    description: string;
    metadata_type: string;
    output_format: string;
  }>,
  language: string
) => {
  const body = JSON.stringify({
    file_ids: ids,
    metadata: metadata,
    language: language ?? "English",
  });
  fetch(`${backendUrl}/api/extract_metadata/`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRFToken": csrfToken ?? "",
      "Content-Type": "application/json",
    },
    body,
  });
};

export const extractObligations = async (
  csrfToken: string,
  ids: Array<string | number>,
  obligations: Array<{ name: string; description: string }>,
  language: string
) => {
  const body = JSON.stringify({
    file_ids: ids,
    obligations: obligations,
    language: language ?? "English",
  });
  fetch(`${backendUrl}/api/extract_obligations/`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRFToken": csrfToken ?? "",
      "Content-Type": "application/json",
    },
    body,
  });
};

export const identifyRisks = async (
  csrfToken: string,
  ids: Array<string | number>,
  risks: Array<{
    risk: string;
    task: string;
    mitigation: string;
    situation: string;
    example: string;
  }>,
  language: string
) => {
  const body = JSON.stringify({
    file_ids: ids,
    risks: risks,
    language: language ?? "English",
  });
  fetch(`${backendUrl}/api/identify_risks/`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRFToken": csrfToken ?? "",
      "Content-Type": "application/json",
    },
    body,
  });
};

export const compareDocumentsPlaybook = async (
  csrfToken: string,
  ids: Array<string | number>,
  clausesToCheck: string,
  language: string,
  fileUploaded: UploadFile
) => {
  const formData = new FormData();
  for (let idsIndex = 0; idsIndex < ids.length; idsIndex++) {
    const id = ids[idsIndex];
    formData.append("file_ids", id.toString());
  }
  formData.append("clauses_to_check", JSON.stringify(clausesToCheck ?? ""));
  formData.append("benchmark_doc_file", fileUploaded as FileType);
  formData.append("language", JSON.stringify(language ?? "English"));

  fetch(`${backendUrl}/api/compare_documents_playbook/`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRFToken": csrfToken ?? "",
    },
    body: formData,
  });
};

export const compareDocumentsContract = async (
  csrfToken: string,
  ids: Array<string | number>,
  clausesToCheck: string,
  language: string,
  fileUploaded: UploadFile
) => {
  const formData = new FormData();
  for (let idsIndex = 0; idsIndex < ids.length; idsIndex++) {
    const id = ids[idsIndex];
    formData.append("file_ids", id.toString());
  }
  formData.append("clauses_to_check", JSON.stringify(clausesToCheck ?? ""));
  formData.append("benchmark_doc_file", fileUploaded as FileType);
  formData.append("language", JSON.stringify(language ?? "English"));

  fetch(`${backendUrl}/api/compare_documents_contract/`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRFToken": csrfToken ?? "",
    },
    body: formData,
  });
};

export const compareDocumentsCustomRegulation = async (
  csrfToken: string,
  ids: Array<string | number>,
  clausesToCheck: string,
  language: string,
  fileUploaded: UploadFile
) => {
  const formData = new FormData();
  for (let idsIndex = 0; idsIndex < ids.length; idsIndex++) {
    const id = ids[idsIndex];
    formData.append("file_ids", id.toString());
  }
  formData.append("clauses_to_check", JSON.stringify(clausesToCheck ?? ""));
  formData.append("benchmark_doc_file", fileUploaded as FileType);
  formData.append("language", JSON.stringify(language ?? "English"));

  fetch(`${backendUrl}/api/compare_documents_custom_regulation/`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRFToken": csrfToken ?? "",
    },
    body: formData,
  });
};

export const compareDocumentsPredefinedRegulation = async (
  csrfToken: string,
  ids: Array<string | number>,
  clausesToCheck: string,
  language: string,
  regulation: string
) => {
  const formData = new FormData();
  for (let idsIndex = 0; idsIndex < ids.length; idsIndex++) {
    const id = ids[idsIndex];
    formData.append("file_ids", id.toString());
  }
  formData.append("clauses_to_check", JSON.stringify(clausesToCheck ?? ""));
  formData.append("selected_regulation", JSON.stringify(regulation));
  formData.append("language", JSON.stringify(language ?? "English"));

  fetch(`${backendUrl}/api/compare_documents_predefined_regulation/`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRFToken": csrfToken ?? "",
    },
    body: formData,
  });
};

export const compareDocumentsCustomChecklist = async (
  csrfToken: string,
  ids: Array<string | number>,
  clausesToCheck: string,
  language: string,
  fileUploaded: UploadFile
) => {
  const formData = new FormData();
  for (let idsIndex = 0; idsIndex < ids.length; idsIndex++) {
    const id = ids[idsIndex];
    formData.append("file_ids", id.toString());
  }
  formData.append("clauses_to_check", JSON.stringify(clausesToCheck ?? ""));
  formData.append("benchmark_doc_file", fileUploaded as FileType);
  formData.append("language", JSON.stringify(language ?? "English"));

  fetch(`${backendUrl}/api/compare_documents_custom_checklist/`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRFToken": csrfToken ?? "",
    },
    body: formData,
  });
};

export const compareDocumentsPredefinedChecklist = async (
  csrfToken: string,
  ids: Array<string | number>,
  clausesToCheck: string,
  language: string,
  checklist: string
) => {
  const formData = new FormData();
  for (let idsIndex = 0; idsIndex < ids.length; idsIndex++) {
    const id = ids[idsIndex];
    formData.append("file_ids", id.toString());
  }
  formData.append("clauses_to_check", JSON.stringify(clausesToCheck ?? ""));
  formData.append("selected_checklist", JSON.stringify(checklist));
  formData.append("language", JSON.stringify(language ?? "English"));

  fetch(`${backendUrl}/api/compare_documents_predefined_checklist/`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRFToken": csrfToken ?? "",
    },
    body: formData,
  });
};

export const analyzeTimeline = async (
  csrfToken: string,
  ids: Array<string | number>,
  userQuery: string,
  timeline: boolean,
  orderingCriteria: Array<string>,
  language: string,
  outputName: string
) => {
  const body = JSON.stringify({
    file_ids: ids,
    user_query: userQuery,
    timeline: timeline,
    ordering_criteria: orderingCriteria,
    language: language ?? "English",
    output_name: outputName,
  });

  fetch(`${backendUrl}/api/analyze_timeline/`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRFToken": csrfToken ?? "",
      "Content-Type": "application/json",
    },
    body,
  });
};

export const draftingDocumentsCustomPromptBased = async (
  csrfToken: string,
  selectedProject: Project,
  custom_prompt: string,
  tone: string,
  creativity: number,
  output_name: string,
  language: string
) => {
  const body = JSON.stringify({
    project: selectedProject!.id,
    custom_prompt,
    tone,
    creativity,
    output_name,
    language,
  });

  fetch(`${backendUrl}/api/draft_custom_based/`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRFToken": csrfToken ?? "",
      "Content-Type": "application/json",
    },
    body,
  });
};

export const draftingDocumentsTemplateBased = async (
  csrfToken: string,
  selectedProject: Project,
  custom_prompt: string,
  template: number | string,
  output_name: string,
  language: string
) => {
  const body = JSON.stringify({
    project: selectedProject!.id,
    template_id: template,
    custom_prompt,
    output_name,
    language,
  });

  fetch(`${backendUrl}/api/draft_template_based/`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRFToken": csrfToken ?? "",
      "Content-Type": "application/json",
    },
    body,
  });
};

export const draftingDocumentsManyToOne = async (
  csrfToken: string,
  ids: Array<string | number>,
  custom_prompt: string,
  template: number | string,
  output_name: string,
  languages: Array<string>
) => {
  const body = JSON.stringify({
    file_ids: ids,
    custom_prompt,
    template_id: template,
    output_name,
    languages,
  });

  fetch(`${backendUrl}/api/draft_many_to_one/`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRFToken": csrfToken ?? "",
      "Content-Type": "application/json",
    },
    body,
  });
};

export const draftingDocumentsOneToOne = async (
  csrfToken: string,
  ids: Array<string | number>,
  common_files: Array<any>,
  custom_prompt: string,
  template: number | string,
  languages: Array<string> = ["English"]
) => {
  const formData = new FormData();
  ids.forEach((id) => {
    formData.append("file_ids", id.toString());
  });
  common_files.forEach((file) => {
    formData.append("common_files", file);
  });
  formData.append("custom_prompt", custom_prompt ?? "");
  formData.append("template_id", template.toString() ?? "");
  languages.forEach((language) => {
    formData.append("languages", language);
  });

  fetch(`${backendUrl}/api/draft_one_to_one/`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRFToken": csrfToken ?? "",
    },
    body: formData,
  });
};

export const translateText = async (
  csrfToken: string,
  ids: Array<string | number>,
  language: string
) => {
  const body = JSON.stringify({
    file_ids: ids,
    language: language,
  });

  fetch(`${backendUrl}/api/translate_text/`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRFToken": csrfToken ?? "",
      "Content-Type": "application/json",
    },
    body,
  });
};

export const extractInvoiceData = async (
  csrfToken: string,
  ids: Array<string | number>
) => {
  const body = JSON.stringify({
    file_ids: ids,
  });
  fetch(`${backendUrl}/api/extract_invoice_data/`, {
    method: "POST",
    credentials: "include",
    headers: {
      "X-CSRFToken": csrfToken ?? "",
      "Content-Type": "application/json",
    },
    body,
  });
};
