import { TeamOutlined } from "@ant-design/icons";
import { Dropdown, MenuProps, Modal } from "antd";
import React, { useContext, useState } from "react";
import MainContext from "../../../../../../contexts/MainContext";
import Team from "../../../../../../data/Team";
import { useNavigate } from "react-router-dom";
import styles from "./TeamSelector.module.css";
import ToolsContext from "../../../../../../contexts/ToolsContext";

const TeamSelector: React.FC = () => {
  const mainContext = useContext(MainContext);
  const toolsContext = useContext(ToolsContext);
  const navigate = useNavigate();
  const [showConfirmChangeTeamModal, setShowConfirmChangeTeamModal] = useState<
    Team | undefined
  >(undefined);

  const items: MenuProps["items"] = mainContext!.teams.map((team, index) => ({
    label: team.name,
    key: index,
    onClick: () => {
      if (mainContext?.teamSelected?.id === team.id) return;
      if (
        toolsContext?.selectedFiles.length !== 0 ||
        toolsContext.selectedTools.length !== 0
      ) {
        setShowConfirmChangeTeamModal(team);
      } else {
        mainContext?.setTeamSelected(team);
        navigate(`/${team.id}`);
      }
    },
  }));

  return (
    <>
      <Dropdown
        menu={{ items }}
        trigger={["click"]}
        placement="topLeft"
        dropdownRender={(menu) => (
          <div className={styles.team_menu}>{menu}</div>
        )}
      >
        <div className={styles.team_button}>
          <p>
            <TeamOutlined /> Team:
          </p>
          <p
            className={styles.team_name}
            title={mainContext?.teamSelected?.name}
          >
            {mainContext?.teamSelected?.name}
          </p>
        </div>
      </Dropdown>

      <Modal
        centered
        title="Are you sure you want to change the team?"
        open={!!showConfirmChangeTeamModal}
        onOk={() => {
          mainContext?.setTeamSelected(showConfirmChangeTeamModal);
          setShowConfirmChangeTeamModal(undefined);
          // navigate(`/${mainContext?.teamSelected?.id}`)
        }}
        onCancel={() => setShowConfirmChangeTeamModal(undefined)}
        okText="Yes"
        cancelText="No"
      >
        <p>You have unsaved changes.</p>
      </Modal>
    </>
  );
};

export default TeamSelector;
