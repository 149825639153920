import React from "react";
import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import type { TableColumnsType } from "antd";
import { Tooltip, Table } from "antd";
import { DownCircleOutlined, UpCircleOutlined } from "@ant-design/icons";
import { FaCheckCircle, FaInfoCircle, FaTimesCircle } from "react-icons/fa";
import parseDateString from "../../../../helpers/parseDateString";
import formatDate from "../../../../helpers/formatDate";
import { ToolResult } from "../../interfaces/toolsResults.interface";
import { TableData } from "../../interfaces/tableData.interface";
import ResultActions from "../ResultActions/ResultActions";
import { ResultTypes } from "../../enums/resultTypes.enum";
import { ObligationsExpandedData } from "./interfaces/obligationsExpandedData.interface";
import { ToolResultProps } from "../../interfaces/toolResultProps";

import styles from "./ObligationsResults.module.css";

const ObligationsResults: React.FC<ToolResultProps> = ({
  isLoading,
  allResults,
  getColumnSearchProps,
  selectRows,
  expandedRowKeys,
  setExpandedRowKeys,
  currentPage,
  setCurrentPage
}) => {


  const navigate = useNavigate();
  const location = useLocation();

const handleExpand = (expanded: boolean, record: TableData) => {
  let newExpandedRowKeys = [...expandedRowKeys];
  if (expanded) {
    newExpandedRowKeys.push(record.key as number);
  } else {
    newExpandedRowKeys = newExpandedRowKeys.filter(key => key !== record.key);
  }
  setExpandedRowKeys(newExpandedRowKeys);

  // Update URL with the expanded rows
  const params = new URLSearchParams(window.location.search);
  if (newExpandedRowKeys.length > 0) {
    params.set("expandedRowKeys", newExpandedRowKeys.join(","));
  } else {
    params.delete("expandedRowKeys");
  }
  navigate({ search: params.toString() }, { replace: true });
};


  const columns: TableColumnsType<TableData> = [
    {
      title: "File",
      dataIndex: "file",
      key: "file",
      ...getColumnSearchProps("file"),
      sorter: (a, b) => a.file.localeCompare(b.file),
      sortDirections: ["ascend", "descend"],
      width: "66%",
    },
    {
      title: "User",
      dataIndex: "user",
      key: "user",
      ...getColumnSearchProps("user"),
      sortDirections: ["ascend", "descend"],
      width: "140px",
      render: (value) => (
        <div title={value} className={styles.user}>
          {value}
        </div>
      ),
    },
    {
      title: "Created at",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: (a, b) =>
        parseDateString(a.createdAt).getTime() -
        parseDateString(b.createdAt).getTime(),
      sortDirections: ["ascend", "descend"],
      width: "140px",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "70px",
      align: "center",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      width: "220px",
      render: (_, record, ___) => (
        <ResultActions record={record} resultType={ResultTypes.Obligations} />
      ),
      align: "center",
    },
  ];

  const checkStatus = (result: ToolResult) => {
    if (result.results.length === 0)
      return (
        <Tooltip title="Process failed.">
          <FaTimesCircle color="var(--beautiful-red)" />
        </Tooltip>
      );
    for (
      let obligationsResultIndex = 0;
      obligationsResultIndex < result.results.length;
      obligationsResultIndex++
    ) {
      const obligationsResult = result.results[obligationsResultIndex];
      const checkValues = () => {
        if (!obligationsResult.obligations_parties_results) return false;
        for (
          let valueIndex = 0;
          valueIndex < obligationsResult.obligations_parties_results.length;
          valueIndex++
        ) {
          const value =
            obligationsResult.obligations_parties_results[valueIndex];
          if (!value.value || !value.category || !value.timing) return true;
        }
        return false;
      };
      if (!obligationsResult.name || checkValues()) {
        return (
          <Tooltip title="Not all the values were extracted.">
            <FaInfoCircle color="yellow" />
          </Tooltip>
        );
      }
    }
    return (
      <Tooltip title="All obligations were extracted.">
        <FaCheckCircle color="var(--deloitte-green)" />
      </Tooltip>
    );
  };

  const expandedRowRender = (record: TableData) => {
    const expandedTableColumns: TableColumnsType<ObligationsExpandedData> = [
      {
        title: "Party",
        dataIndex: "party",
        key: "party",
      },
      {
        title: "Obligation",
        dataIndex: "obligation",
        key: "obligation",
        sorter: (a, b) => a.obligation.localeCompare(b.obligation),
        ...getColumnSearchProps("obligation"),
      },
      {
        title: "Result",
        dataIndex: "result",
        key: "result",
      },
      {
        title: "Category",
        dataIndex: "category",
        key: "category",
      },
      {
        title: "Timing",
        dataIndex: "timing",
        key: "timing",
      },
    ];

    const expandedRowResults = allResults.filter(
      (result) => result.id === record.key
    )[0].results;

    const data = [];
    for (let i = 0; i < expandedRowResults!.length; i++) {
      for (
        let j = 0;
        j < expandedRowResults![i]["obligations_parties_results"].length;
        j++
      ) {
        data.push({
          key: expandedRowResults![i].obligations_parties_results[j].id,
          party: expandedRowResults![i].error ? (
            <Tooltip title="Internal server error occurred while extracting the party.">
              <FaTimesCircle color="var(--beautiful-red)" />
            </Tooltip>
          ) : expandedRowResults![i].name ? (
            expandedRowResults![i].name
          ) : (
            <Tooltip title="Could not extract the party.">
              <FaInfoCircle color="yellow" />
            </Tooltip>
          ),
          obligation:
            expandedRowResults![i].obligations_parties_results[j].name,
          result: expandedRowResults![i].error ? (
            <Tooltip title="Internal server error occurred while extracting the party.">
              <FaTimesCircle color="var(--beautiful-red)" />
            </Tooltip>
          ) : expandedRowResults![i].obligations_parties_results[j].error ? (
            <Tooltip title="Internal server error occurred while extracting the obligation.">
              <FaTimesCircle color="var(--beautiful-red)" />
            </Tooltip>
          ) : expandedRowResults![i].obligations_parties_results[j].value ? (
            expandedRowResults![i].obligations_parties_results[j].value
          ) : (
            <Tooltip title="Could not extract the obligation.">
              <FaInfoCircle color="yellow" />
            </Tooltip>
          ),
          category: expandedRowResults![i].error ? (
            <Tooltip title="Internal server error occurred while extracting the party.">
              <FaTimesCircle color="var(--beautiful-red)" />
            </Tooltip>
          ) : expandedRowResults![i].obligations_parties_results[j].error ? (
            <Tooltip title="Internal server error occurred while extracting the obligation.">
              <FaTimesCircle color="var(--beautiful-red)" />
            </Tooltip>
          ) : expandedRowResults![i].obligations_parties_results[j].category ? (
            expandedRowResults![i].obligations_parties_results[j].category
          ) : (
            <Tooltip title="Could not extract the obligation.">
              <FaInfoCircle color="yellow" />
            </Tooltip>
          ),
          timing: expandedRowResults![i].error ? (
            <Tooltip title="Internal server error occurred while extracting the party.">
              <FaTimesCircle color="var(--beautiful-red)" />
            </Tooltip>
          ) : expandedRowResults![i].obligations_parties_results[j].error ? (
            <Tooltip title="Internal server error occurred while extracting the obligation.">
              <FaTimesCircle color="var(--beautiful-red)" />
            </Tooltip>
          ) : expandedRowResults![i].obligations_parties_results[j].timing ? (
            expandedRowResults![i].obligations_parties_results[j].timing
          ) : (
            <Tooltip title="Could not extract the obligation.">
              <FaInfoCircle color="yellow" />
            </Tooltip>
          ),
        });
      }
    }

    return expandedRowResults!.length === 0 ? (
      <div className={styles.error_result}>
        <Tooltip title="This process failed.">
          <FaTimesCircle color="var(--beautiful-red)" />
        </Tooltip>
      </div>
    ) : (
      <Table
        bordered
        columns={expandedTableColumns}
        dataSource={data}
        pagination={false}
      />
    );
  };

  return (
    <div className={styles.table}>
      <Table
        bordered
        loading={isLoading}
        sticky={{ offsetHeader: 0 }}
        rowSelection={{
          type: "checkbox",
          onChange: (val) => {
            selectRows(val);
          },
        }}
        size="middle"
        pagination={{
          defaultPageSize: 15,
          current: currentPage, // Add current page control
          onChange: (page) => setCurrentPage(page), // Update current page
        }}
        scroll={{ scrollToFirstRowOnChange: true }}
        columns={columns}
        dataSource={allResults.map((result) => ({
          key: result.id,
          file: result.file,
          user: result.user,
          status: checkStatus(result),
          createdAt: formatDate(result.created_at),
        }))}
        expandable={{
          expandedRowRender: expandedRowRender,
          onExpand: handleExpand,
          expandedRowKeys: expandedRowKeys,
          expandIcon: ({ expanded, onExpand, record }) =>
            expanded ? (
              <UpCircleOutlined
                style={{ fontSize: 16 }}
                onClick={(e) => onExpand(record, e)}
              />
            ) : (
              <DownCircleOutlined
                style={{ fontSize: 16 }}
                onClick={(e) => onExpand(record, e)}
              />
            ),
        }}
      />
    </div>
  );
};

export default ObligationsResults;
