import { ResultTypes } from "../enums/resultTypes.enum";
import { ToolResult } from "../interfaces/toolsResults.interface";
import downloadFile from "../../../helpers/downloadFile";

const backendUrl = process.env.REACT_APP_API_URL ?? "";

export const fetchToolResults = async (
  projectId: string | number,
  resultType: ResultTypes
) => {
  const response = await fetch(
    `${backendUrl}/api/results/${projectId}/${resultType}`,
    { method: "GET", credentials: "include" }
  );
  const body = await response.json();

  const fetchedResults = body.sort((a: ToolResult, b: ToolResult) => {
    const dateA = new Date(a.created_at);
    const dateB = new Date(b.created_at);
    return dateB.getTime() - dateA.getTime();
  }) as Array<ToolResult>;

  return fetchedResults;
};

export const batchExport = async (
  csrfToken: string,
  resultIds: Array<number | string | bigint>,
  url: string
) => {
  await downloadFile(
    await fetch(`${backendUrl}/api/${url}/`, {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRFToken": csrfToken ?? "",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ids: resultIds }),
    })
  );
};

export const exportFile = async (resultId: string | number, url: string) => {
  await downloadFile(
    await fetch(`${backendUrl}/api/${url}/${resultId}`, {
      method: "GET",
      credentials: "include",
    })
  );
};

export const deleteResults = async (
  csrfToken: string,
  ids: Array<string | number | bigint>
) => {
  await fetch(`${backendUrl}/api/delete_results/`, {
    method: "DELETE",
    credentials: "include",
    headers: {
      "X-CSRFToken": csrfToken ?? "",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ ids }),
  });
};
