import React, { useContext } from "react";
import { Carousel } from "antd";
import { CgDanger } from "react-icons/cg";
import { MdOutlineDataObject, MdOutlineDocumentScanner } from "react-icons/md";
import { PiHandshake } from "react-icons/pi";
import { TbLayersDifference } from "react-icons/tb";
import { LuLanguages, LuPencil } from "react-icons/lu";
import { LiaMoneyCheckAltSolid } from "react-icons/lia";
import { CustomNextArrow, CustomPrevArrow } from "./components/CustomArrows";
import { ResultTypes } from "../../enums/resultTypes.enum";
import MainContext from "../../../../contexts/MainContext";
import { ToolTypes } from "../../../../enums/toolTypes.enum";

import styles from "./ToolSelectorCarouselWithArrows.module.css";

interface ToolSelectorCarouselWithArrowsProps {
  toolSelected: ResultTypes | undefined;
  setToolSelected: (tool: ResultTypes) => void;
}

const ToolSelectorCarouselWithArrows: React.FC<
  ToolSelectorCarouselWithArrowsProps
> = ({ toolSelected, setToolSelected }) => {
  const mainContext = useContext(MainContext);
  return (
    <>
      <Carousel
        className={styles.carousel_with_arrows}
        prevArrow={<CustomPrevArrow />}
        nextArrow={<CustomNextArrow />}
        arrows
        infinite={false}
        slidesToShow={4}
        slidesToScroll={4}
        speed={800}
        dots={false}
      >
        {mainContext?.teamSelected?.tools.includes(
          ToolTypes.MetadataExtractor
        ) ? (
          <div
            className={`${styles.tool_selector} ${
              toolSelected === ResultTypes.Metadata
                ? styles.tool_selector_selected
                : ""
            }`}
            onClick={() => {
              setToolSelected(ResultTypes.Metadata);
            }}
          >
            <MdOutlineDataObject className={styles.tool_selector_icon} />
            &nbsp;Metadata extraction
          </div>
        ) : undefined}
        {mainContext?.teamSelected?.tools.includes(
          ToolTypes.DocumentDrafting
        ) ? (
          <div
            className={`${styles.tool_selector} ${
              toolSelected === ResultTypes.Drafting
                ? styles.tool_selector_selected
                : ""
            }`}
            onClick={() => {
              setToolSelected(ResultTypes.Drafting);
            }}
          >
            <LuPencil className={styles.tool_selector_icon} />
            &nbsp;Document drafting
          </div>
        ) : undefined}
        {mainContext?.teamSelected?.tools.includes(ToolTypes.Translator) ? (
          <div
            className={`${styles.tool_selector} ${
              toolSelected === ResultTypes.Translation
                ? styles.tool_selector_selected
                : ""
            }`}
            onClick={() => {
              setToolSelected(ResultTypes.Translation);
            }}
          >
            <LuLanguages className={styles.tool_selector_icon} />
            &nbsp;Translation
          </div>
        ) : undefined}
        {mainContext?.teamSelected?.tools.includes(
          ToolTypes.DocumentComparison
        ) ? (
          <div
            className={`${styles.tool_selector} ${
              toolSelected === ResultTypes.Comparison
                ? styles.tool_selector_selected
                : ""
            }`}
            onClick={() => {
              setToolSelected(ResultTypes.Comparison);
            }}
          >
            <TbLayersDifference className={styles.tool_selector_icon} />
            &nbsp;Document comparison
          </div>
        ) : undefined}
        {mainContext?.teamSelected?.tools.includes(
          ToolTypes.ObligationsExtractor
        ) ? (
          <div
            className={`${styles.tool_selector} ${
              toolSelected === ResultTypes.Obligations
                ? styles.tool_selector_selected
                : ""
            }`}
            onClick={() => {
              setToolSelected(ResultTypes.Obligations);
            }}
          >
            <PiHandshake className={styles.tool_selector_icon} />
            &nbsp; Obligations extraction
          </div>
        ) : undefined}
        {mainContext?.teamSelected?.tools.includes(
          ToolTypes.RisksIdentifier
        ) ? (
          <div
            className={`${styles.tool_selector} ${
              toolSelected === ResultTypes.Risks
                ? styles.tool_selector_selected
                : ""
            }`}
            onClick={() => {
              setToolSelected(ResultTypes.Risks);
            }}
          >
            <CgDanger className={styles.tool_selector_icon} />
            &nbsp;Risks identification
          </div>
        ) : undefined}

        {mainContext?.teamSelected?.tools.includes(ToolTypes.InvoiceReview) && (
          <div
            className={`${styles.tool_selector} ${
              toolSelected === ResultTypes.Invoice
                ? styles.tool_selector_selected
                : ""
            }`}
            onClick={() => {
              setToolSelected(ResultTypes.Invoice);
            }}
          >
            <LiaMoneyCheckAltSolid className={styles.tool_selector_icon} />
            &nbsp;Invoice Review
          </div>
        )}

        {mainContext?.teamSelected?.tools.includes(
          ToolTypes.TimelineAnalysis
        ) && (
          <div
            className={`${styles.tool_selector} ${
              toolSelected === ResultTypes.Timeline
                ? styles.tool_selector_selected
                : ""
            }`}
            onClick={() => {
              setToolSelected(ResultTypes.Timeline);
            }}
          >
            <MdOutlineDocumentScanner className={styles.tool_selector_icon} />
            &nbsp;Timeline Analysis
          </div>
        )}
      </Carousel>
      <hr></hr>
    </>
  );
};

export default ToolSelectorCarouselWithArrows;
