import { Tag } from "antd";
import React from "react";
import { LuConstruction } from "react-icons/lu";
import { CSSTransition } from "react-transition-group";
import { FiTool } from "react-icons/fi";
import { MdConstruction } from "react-icons/md";

import styles from "./CustomTool.module.css";

const CustomTool: React.FC = () => {
  return (
    <CSSTransition
      key="custom_tool"
      in={true}
      timeout={260}
      classNames={{
        enter: styles.growEnter,
        enterActive: styles.growEnterActive,
        exit: styles.shrinkExit,
        exitActive: styles.shrinkExitActive,
      }}
      mountOnEnter
      unmountOnExit
    >
      <div className={styles.tool} key="custom_tool">
        <div className={styles.tool_title}>
          <div className={styles.ribbon}>
            <FiTool />
          </div>
          <div style={{ fontSize: 24 }}>Custom Tool</div>
          <Tag className={styles.tool_construction} color="warning">
            <LuConstruction /> In Development
          </Tag>
        </div>
        <div className={styles.tool_content}>
          <MdConstruction /> Working on it ...
        </div>
      </div>
    </CSSTransition>
  );
};

export default CustomTool;
