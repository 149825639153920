import { DraftingTypes } from "./draftingTypes.enum";

type TooltipsMap = {
  [key in DraftingTypes]: string;
};

const draftingDisabledTooltips: TooltipsMap = {
  [DraftingTypes.OneToOne]:
    "Please select the files you want to draft and complete all the required fields from the tool.",
  [DraftingTypes.MultipleToOne]:
    "Please select the files you want to draft and complete all the required fields from the tool.",
  [DraftingTypes.TemplateBased]:
    "Please complete all the required fields from the tool.",
  [DraftingTypes.PromptBased]:
    "Please complete all the required fields from the tool.",
};

export default draftingDisabledTooltips;
