import { Button, Form, Input, Modal } from "antd";
import React, { useContext, useEffect, useState } from "react";

import styles from "./CreateEditTeamtModal.module.css";

import TeamsContext from "../../../../../../contexts/TeamsContext";

import Team from "../../../../../../data/Team";
import { createTeam, editTeam } from "../../../../services/teams.service";

interface CreateEditTeamModalProps {
  isOpen: boolean;
  closeModal: () => void;
  teamToEdit: Team | undefined;
}

export interface TeamForm {
  name: string;
  id: number | undefined;
}

const CreateEditTeamModal: React.FC<CreateEditTeamModalProps> = ({
  isOpen,
  closeModal,
  teamToEdit,
}) => {
  const teamsContext = useContext(TeamsContext);
  const [isLoading, setIsLoading] = useState(false);

  const [teamForm, setTEamForm] = useState<TeamForm>({
    name: "",
    id: undefined,
  });

  useEffect(() => {
    if (teamToEdit) {
      setTEamForm({
        name: teamToEdit.name,
        id: teamToEdit.id,
      });
    } else {
      setTEamForm({
        name: "",
        id: undefined,
      });
    }
  }, [isOpen]);

  async function handleCreateTeam() {
    await createTeam(teamForm.name);
    await teamsContext!.fetchTeams();
    closeModal();
  }

  async function handleEditTeam() {
    await editTeam(teamForm.id!, { name: teamForm.name });
    await teamsContext!.fetchTeams();
    closeModal();
  }

  const onSubmit = async () => {
    setIsLoading(true);
    if (teamForm.id) {
      handleEditTeam();
    } else {
      handleCreateTeam();
    }
    setIsLoading(false);
    closeModal();
  };

  return (
    <Modal
      width="60%"
      centered
      open={isOpen}
      title={[
        <p key="title" className={styles.title}>
          {teamToEdit ? "Edit Team" : "New Team"}
        </p>,
        <p key="subTitle" className={styles.subTitle}>
          {teamToEdit
            ? "Please type new name for your team."
            : "Please type a name for your new team."}
        </p>,
      ]}
      onOk={onSubmit}
      onCancel={closeModal}
      footer={[
        <Button key="back" className={styles.close_button} onClick={closeModal}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          className={styles.submit_button}
          disabled={!teamForm.name}
          loading={isLoading}
          onClick={onSubmit}
        >
          Submit
        </Button>,
      ]}
    >
      <Form className={styles.form} layout="vertical">
        <Form.Item required label="Team name">
          <Input
            value={teamForm.name}
            name="name"
            maxLength={255}
            allowClear
            placeholder="Type the team name"
            onChange={(event) =>
              setTEamForm({ ...teamForm, name: event?.target.value })
            }
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateEditTeamModal;
