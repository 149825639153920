import React from "react";
import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import type { TableColumnsType } from "antd";
import { Tooltip, Table } from "antd";
import { DownCircleOutlined, UpCircleOutlined } from "@ant-design/icons";
import { FaCheckCircle, FaInfoCircle, FaTimesCircle } from "react-icons/fa";
import parseDateString from "../../../../helpers/parseDateString";
import formatDate from "../../../../helpers/formatDate";
import {
  ToolResult,
  RisksResult,
} from "../../interfaces/toolsResults.interface";
import { TableData } from "../../interfaces/tableData.interface";
import ResultActions from "../ResultActions/ResultActions";
import { ResultTypes } from "../../enums/resultTypes.enum";
import { RisksExpandedData } from "./interfaces/risksExpandedData.interface";
import { ToolResultProps } from "../../interfaces/toolResultProps";

import styles from "./RisksResults.module.css";

const RisksResults: React.FC<ToolResultProps> = ({
  isLoading,
  allResults,
  getColumnSearchProps,
  selectRows,
  expandedRowKeys,
  setExpandedRowKeys,
  currentPage,
  setCurrentPage
}) => {
  const navigate = useNavigate();
  const location = useLocation();

const handleExpand = (expanded: boolean, record: TableData) => {
  let newExpandedRowKeys = [...expandedRowKeys];
  if (expanded) {
    newExpandedRowKeys.push(record.key as number);
  } else {
    newExpandedRowKeys = newExpandedRowKeys.filter(key => key !== record.key);
  }
  setExpandedRowKeys(newExpandedRowKeys);

  // Update URL with the expanded rows
  const params = new URLSearchParams(window.location.search);
  if (newExpandedRowKeys.length > 0) {
    params.set("expandedRowKeys", newExpandedRowKeys.join(","));
  } else {
    params.delete("expandedRowKeys");
  }
  navigate({ search: params.toString() }, { replace: true });
};


  const columns: TableColumnsType<TableData> = [
    {
      title: "File",
      dataIndex: "file",
      key: "file",
      ...getColumnSearchProps("file"),
      sorter: (a, b) => a.file.localeCompare(b.file),
      sortDirections: ["ascend", "descend"],
      width: "66%",
    },
    {
      title: "User",
      dataIndex: "user",
      key: "user",
      ...getColumnSearchProps("user"),
      sortDirections: ["ascend", "descend"],
      width: "140px",
      render: (value) => (
        <div title={value} className={styles.user}>
          {value}
        </div>
      ),
    },
    {
      title: "Created at",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: (a, b) =>
        parseDateString(a.createdAt).getTime() -
        parseDateString(b.createdAt).getTime(),
      sortDirections: ["ascend", "descend"],
      width: "140px",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "70px",
      align: "center",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      width: "220px",
      render: (_, record, ___) => (
        <ResultActions record={record} resultType={ResultTypes.Risks} />
      ),
      align: "center",
    },
  ];

  const checkStatus = (result: ToolResult) => {
    if (result.results.length === 0)
      return (
        <Tooltip title="Process failed.">
          <FaTimesCircle color="var(--beautiful-red)" />
        </Tooltip>
      );
    for (
      let risksResultIndex = 0;
      risksResultIndex < result.results.length;
      risksResultIndex++
    ) {
      const risksResult = result.results[risksResultIndex];
      if (
        !risksResult.detected_risk ||
        !risksResult.mitigation ||
        risksResult.source.length === 0
      ) {
        return (
          <Tooltip title="Not all the values were extracted.">
            <FaInfoCircle color="yellow" />
          </Tooltip>
        );
      }
    }
    return (
      <Tooltip title="All risks were identified.">
        <FaCheckCircle color="var(--deloitte-green)" />
      </Tooltip>
    );
  };

  const expandedRowRender = (record: TableData) => {
    const expandedTableColumns: TableColumnsType<RisksExpandedData> = [
      {
        title: "Risk",
        dataIndex: "risk",
        key: "risk",
        align: "center",
        width: "10%",
        sorter: (a, b) => a.risk.localeCompare(b.risk),
        ...getColumnSearchProps("risk"),
      },
      {
        title: "Detected Risk",
        dataIndex: "detected_risks",
        key: "detected_risks",
        align: "center",
        width: "22.5%",
      },
      {
        title: "Mitigation",
        dataIndex: "mitigation",
        key: "mitigation",
        align: "center",
        width: "22.5%",
      },
      {
        title: "Risk Impact",
        dataIndex: "risk_impact",
        key: "risk_impact",
        align: "center",
        width: "22.5%",
      },
      {
        title: "Source",
        dataIndex: "source",
        key: "source",
        align: "center",
        width: "22.5%",
      },
    ];

    const expandedRowResults = allResults.filter(
      (result) => result.id === record.key
    )[0].results;

    const data = expandedRowResults?.map((result: RisksResult) => ({
      key: result.id,
      risk: result.name,
      detected_risks: result.error ? (
        <Tooltip title="Internal server error occurred while identifying the risk.">
          <FaTimesCircle color="var(--beautiful-red)" />
        </Tooltip>
      ) : result.detected_risk ? (
        result.detected_risk
      ) : (
        <Tooltip title="Could not detect the risk.">
          <FaInfoCircle color="yellow" />
        </Tooltip>
      ),
      mitigation: result.error ? (
        <Tooltip title="Internal server error occurred while identifying the mitigation strategy.">
          <FaTimesCircle color="var(--beautiful-red)" />
        </Tooltip>
      ) : result.mitigation ? (
        result.mitigation
      ) : (
        <Tooltip title="Could not provide a mitigation strategy.">
          <FaInfoCircle color="yellow" />
        </Tooltip>
      ),
      risk_impact: result.error ? (
        <Tooltip title="Internal server error occurred while identifying the risk impact.">
          <FaTimesCircle color="var(--beautiful-red)" />
        </Tooltip>
      ) : result.risk_impact ? (
        result.risk_impact
      ) : (
        <Tooltip title="Could not detect the risk impact.">
          <FaInfoCircle color="yellow" />
        </Tooltip>
      ),
      source: result.error ? (
        <Tooltip title="Internal server error occurred while identifying the source.">
          <FaTimesCircle color="var(--beautiful-red)" />
        </Tooltip>
      ) : result.source.length !== 0 ? (
        <p className={styles.source_text}>{result.source}</p>
      ) : (
        <Tooltip title="Could not find the source.">
          <FaInfoCircle color="yellow" />
        </Tooltip>
      ),
    }));

    return expandedRowResults!.length === 0 ? (
      <div className={styles.error_result}>
        <Tooltip title="This process failed.">
          <FaTimesCircle color="var(--beautiful-red)" />
        </Tooltip>
      </div>
    ) : (
      <Table
        bordered
        columns={expandedTableColumns}
        dataSource={data}
        pagination={false}
      />
    );
  };

  return (
    <div className={styles.table}>
      <Table
        bordered
        loading={isLoading}
        sticky={{ offsetHeader: 0 }}
        rowSelection={{
          type: "checkbox",
          onChange: (val) => {
            selectRows(val);
          },
        }}
        size="middle"
        pagination={{
          defaultPageSize: 15,
          current: currentPage, // Add current page control
          onChange: (page) => setCurrentPage(page), // Update current page
        }}
        scroll={{ scrollToFirstRowOnChange: true }}
        columns={columns}
        dataSource={allResults.map((result) => ({
          key: result.id,
          file: result.file,
          user: result.user,
          status: checkStatus(result),
          createdAt: formatDate(result.created_at),
        }))}
        expandable={{
          expandedRowRender: expandedRowRender,
          onExpand: handleExpand,
          expandedRowKeys: expandedRowKeys,
          expandIcon: ({ expanded, onExpand, record }) =>
            expanded ? (
              <UpCircleOutlined
                style={{ fontSize: 16 }}
                onClick={(e) => onExpand(record, e)}
              />
            ) : (
              <DownCircleOutlined
                style={{ fontSize: 16 }}
                onClick={(e) => onExpand(record, e)}
              />
            ),
        }}
      />
    </div>
  );
};

export default RisksResults;
