import React from 'react'
import {
  Table,
  Tooltip,
} from "antd";
import { MdDeleteOutline } from "react-icons/md";

import styles from "./CustomMetadataTable.module.css";

interface CustomMetadataTableProps {
  data: {id: string | number; metadata_parameter: string; metadata_type: string; output_format: string;}[];
  deleteRowItem: (value: string) => void;
}

const CustomMetadataTable: React.FC<CustomMetadataTableProps> = ({ data, deleteRowItem }) => {
  
  return (
    <Table
      pagination={{ pageSize: 6 }}
      locale={{
        emptyText: <div>Custom metadata has not been added.</div>,
      }}
      size="small"
      columns={[
        {
          title: "Custom metadata",
          dataIndex: "customMetadata",
          key: "customMetadata",
          width: "70%",
          ellipsis: { showTitle: false },
          render: (val: string) => (
            <Tooltip placement="topLeft" title={val}>
              {val}
            </Tooltip>
          ),
        },
        {
          title: "Output Format",
          dataIndex: "outputFormat",
          key: "outputFormat",
          width: "22%",
          ellipsis: { showTitle: false },
          render: (val: string) => (
            <Tooltip placement="topLeft" title={val}>
              {val}
            </Tooltip>
          ),
        },
        {
          title: "Actions",
          dataIndex: "actions",
          key: "actions",
          align: 'center',
          width: "8%",
          ellipsis: { showTitle: false },
          render: (val: string) => (
            <span
              className={styles.delete_button}
              onClick={() =>
                deleteRowItem(val)
              }
            >
              <MdDeleteOutline className={styles.delete_icon} />
            </span>
          ),
        },
      ]}
      dataSource={
        data.map((customOption: any) => ({
          key: customOption.id,
          customMetadata: customOption.metadata_parameter,
          outputFormat: `${customOption.metadata_type} ${customOption.output_format
            ? "(" + customOption.output_format + ")"
            : ""
            }`,
          actions: `${customOption.id}`,
        })).reverse()
      }
    ></Table>
  )
}

export default CustomMetadataTable