import { Avatar, Space } from "antd";
import React, { useContext } from "react";
import AuthContext from "../../../../../../contexts/AuthContext";
import styles from "./UserAvatarInDropdownWithUsername.module.css";

interface UserAvatarProps {}

const UserAvatarInDropdownWithUsername: React.FC<UserAvatarProps> = ({}) => {
  const authContext = useContext(AuthContext);

  return (
    <Space className={styles.user}>
      <Avatar
        src={authContext?.user?.profilePicture}
        icon={authContext?.user?.firstname[0]}
      />
      <div>
        <p>{authContext?.user?.fullname}</p>
        <p>{authContext?.user?.email}</p>
      </div>
    </Space>
  );
};

export default UserAvatarInDropdownWithUsername;
