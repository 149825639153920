import React, { ReactNode, useState } from "react";
import { Button, Modal } from "antd";

import styles from "./ConfirmToolModal.module.css";

interface ConfirmToolModalProps {
  isOpen: boolean;
  title: string;
  subTitle: string | ReactNode;
  icon: ReactNode;
  leftPart: { header: string; body: string[] };
  rightPart: { header: string; body: Array<string | null> };
  onClose: () => void;
  action: CallableFunction;
  isSubmitDisabled?: boolean;
}

const ConfirmToolModal: React.FC<ConfirmToolModalProps> = ({
  isOpen,
  title,
  subTitle,
  icon,
  leftPart,
  rightPart,
  onClose,
  action,
  isSubmitDisabled,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async () => {
    setIsLoading(true);
    await action();
    onClose();
  };

  return (
    <Modal
      width="80%"
      centered
      open={isOpen}
      title={[
        <p key="title" className={styles.title}>
          {title}
        </p>,
        <div key="subTitle" className={styles.subTitle}>
          {subTitle}
        </div>,
      ]}
      onOk={onSubmit}
      onCancel={onClose}
      footer={[
        <Button key="back" className={styles.close_button} onClick={onClose}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          className={styles.submit_button}
          loading={isLoading}
          onClick={onSubmit}
          disabled={isSubmitDisabled}
        >
          Submit
        </Button>,
      ]}
    >
      <div className={styles.content}>
        <div className={styles.content_part}>
          <p className={styles.content_header}>{leftPart.header}</p>
          {leftPart.body.map((item, index) => (
            <p key={index} className={styles.content_item}>
              {item}
            </p>
          ))}
        </div>
        <div className={styles.middle_icon}>{icon}</div>
        <div className={styles.content_part}>
          <p className={styles.content_header}>{rightPart.header}</p>
          {rightPart.body.map((item, index) => {
            if (item) {
              return (
                <p key={index} className={styles.content_item}>
                  {item}
                </p>
              );
            }
          })}
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmToolModal;
