function timeSince(dateString: string): string {
  const secondsPerMinute = 60;
  const secondsPerHour = 3600;
  const secondsPerDay = 86400;

  const then = new Date(dateString);
  const now = new Date();
  const elapsedSeconds = Math.floor((now.getTime() - then.getTime()) / 1000);

  if (elapsedSeconds < secondsPerMinute) {
    return `Just now`;
  } else if (elapsedSeconds < secondsPerHour) {
    const minutes = Math.floor(elapsedSeconds / secondsPerMinute);
    return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
  } else if (elapsedSeconds < secondsPerDay) {
    const hours = Math.floor(elapsedSeconds / secondsPerHour);
    return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
  } else {
    return "Over a day ago";
  }
}

export default timeSince;