import React from "react";

import styles from "./EmptySpot.module.css";

const EmptySpot = () => {
  const arrow = require("../../../../images/arrow_down.png");

  return (
    <div className={styles.tools_spot_empty_container}>
      <div className={styles.tools_spot_empty}>
        <p>
          Start by selecting the
          <span className={styles.green_word}> tools </span>
          you want to work with.
        </p>

        <img
          className={styles.arrow_down}
          draggable={false}
          src={arrow}
          alt="arrow_down"
        />
      </div>
    </div>
  );
};

export default EmptySpot;
