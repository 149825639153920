import { LoadingOutlined } from "@ant-design/icons";
import React, { useContext, useEffect, useState } from "react";

import TeamsContext from "../../contexts/TeamsContext";
import styles from "./TeamsPage.module.css";
import Members from "./components/Members/Members";
import SideMenu from "./components/SideMenu/SideMenu";
import Tools from "./components/Tools/Tools";
import Topbar from "./components/Topbar/Topbar";

const TeamsPage: React.FC<{}> = (_) => {
  const teamsContext = useContext(TeamsContext);
  const [pageIsLoading, setPageIsLoading] = useState(true);
  const [menuSelected, setMenuSelected] = useState("members");

  useEffect(() => {
    const fetchTeams = async () => {
      teamsContext?.fetchTeams();
      setPageIsLoading(false);
    };

    fetchTeams();
  }, []);

  return (
    <>
      <div className={styles.page}>
        <SideMenu pageIsLoading={pageIsLoading}></SideMenu>

        <div className={styles.content}>
          <Topbar
            warnBeforeLeaving={false}
            menuSelected={menuSelected}
            setMenuSelected={setMenuSelected}
          ></Topbar>
          {pageIsLoading ? (
            <div className={styles.menu_content}>
              <LoadingOutlined className={styles.loading_icon} />
            </div>
          ) : teamsContext?.teams.length === 0 ? (
            <div className={styles.empty_state}>
              You do not have any teams yet
            </div>
          ) : (
            <div className={styles.body}>
              {menuSelected === "members" ? (
                <Members></Members>
              ) : (
                <Tools></Tools>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default TeamsPage;
