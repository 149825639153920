import React, { useContext, useState } from "react";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { createPortal } from "react-dom";

import TeamsContext from "../../../../../../contexts/TeamsContext";
import styles from "./LeaveTeamModal.module.css";
import { leaveTeam } from "../../../../services/teams.service";

interface _LeaveTeamModalProps {
  onClose: () => void;
}

const LeaveTeamModal: React.FC<_LeaveTeamModalProps> = ({ onClose }) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const teamsContext = useContext(TeamsContext);

  const yesHandler = async () => {
    setIsDeleting(true);
    await leaveTeam(teamsContext?.teamSelected?.id!);
    await teamsContext?.fetchTeams();
    onClose();
  };
  const noHandler = () => onClose();

  return createPortal(
    <>
      <div className={styles.leave_team_modal_overlay}></div>
      <div className={styles.leave_team_modal}>
        <div style={{ fontSize: 20, fontWeight: "bold" }}>
          Are you sure you want to leave this team?
        </div>
        <div style={{ height: 30 }} />
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            disabled={isDeleting}
            variant="dark"
            className={styles.leave_team_modal_close_button}
            onClick={noHandler}>
            <div style={{ fontSize: 14, fontWeight: "bold", color: "#fff" }}>
              No
            </div>
          </Button>
          <div style={{ width: 10 }} />
          <Button
            disabled={isDeleting}
            variant="dark"
            className={styles.leave_team_modal_submit_button}
            onClick={yesHandler}>
            {isDeleting ? (
              <Spinner
                style={{ width: 20, height: 20, borderWidth: 2 }}></Spinner>
            ) : (
              <div style={{ fontSize: 14, fontWeight: "bold", color: "#fff" }}>
                Yes
              </div>
            )}
          </Button>
        </div>
      </div>
    </>,
    document.getElementById("portal")!
  );
};

export default LeaveTeamModal;
