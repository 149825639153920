import React, { useState, useContext, useRef, useEffect } from 'react';
import styles from "./TextView.module.css";
import { Button } from 'antd';
import MainContext from "../contexts/MainContext";
import { CloseOutlined } from '@ant-design/icons';
import { Card, Flex, Spin } from 'antd';
import Markdown from 'markdown-to-jsx'




interface TextViewProps {
    title?: string;
    text?: string;
    scrollToText?: string;
}

const TextView: React.FC<TextViewProps> = ({ title, text, scrollToText }) => {


    const mainContext = useContext(MainContext);
    const textContentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (textContentRef.current && scrollToText && text) {
            // if (!scrollToText) return;
            try {
                const { normalizedText, newLinePositions } = removeAndRecordNewLines(text);
                const normalizedSearchText = scrollToText.replace('…', '').trim();
                let positons = insertHighlights(normalizedText, normalizedSearchText);
                //console.log(positons);
                if (positons[0]) {
                    let [start, end] = positons[0];
                    let textBefore = text.substring(0, start);
                    let textAfter = text.substring(end);
                    let textToHighlight = text.substring(start, end);

                    let textWithNewLines = textBefore + ' \n<div class="highlight-text">' + textToHighlight + '</div> \n' + textAfter;


                    mainContext?.setTextViewContent({ text: textWithNewLines });
                }
                setTimeout(() => {
                    if (!textContentRef.current) return;
                    const highlighted = textContentRef.current.querySelector('.highlight-text');
                    if (highlighted) {
                        highlighted.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    }
                }, 800);
            } catch (e) {
                console.error(e);
            }


        }
    }, [scrollToText]);
    return (

        <div className={styles.flex_box_text_card}>

            <Card title={title} extra={<Button onClick={() => mainContext?.setIsTextViewVisible(false)} size={"small"} shape="circle" icon={<CloseOutlined />} />} >
                <div className={styles.flex_box_text_inner} >
                    {text ? <div className={styles.text_content} ref={textContentRef}>
                        {text && <Markdown  >{text}</Markdown>}
                    </div> : <Flex style={{ "height": "20vh" }} align="center" gap="middle" justify='center'>

                        <Spin size="large" />
                    </Flex>}
                </div>
            </Card>
        </div>
    );
};

function removeAndRecordNewLines(text: string) {
    let newLinePositions = [];
    let normalizedText = text.replace(/(\r\n|\n|\r)/gm, ' ');
    let pos = text.indexOf('\n');
    while (pos > -1) {
        newLinePositions.push(pos);
        pos = text.indexOf('\n', pos + 1);
    }
    return { normalizedText, newLinePositions };
}

function updatePositions(positions: number[], index: number, lengthChange: number) {
    for (let i = 0; i < positions.length; i++) {
        if (positions[i] > index) {
            positions[i] += lengthChange;
        }
    }
}

function restoreNewLines(text: string, positions: number[]): string {
    positions.reverse().forEach(position => {
        text = text.substring(0, position) + '<br/>' + text.substring(position);
    });
    return text;
}
function insertHighlights(text: string, searchText: string) {
    let position = 0;
    let highlightedText = '';
    let searchTextLength = searchText.length;
    let index = text.toLowerCase().indexOf(searchText.toLowerCase());
    //console.log(searchText);
    //console.log(text);
    //console.log(index);

    const positions = findTextPositions(text.toLowerCase(), searchText.toLowerCase())
    //console.log(positions);
    return positions;

}

function normalizeText(text: string): string {
    return text.replace(/\s+/g, '').trim();
}
function normalizeTextAndMapIndices(text: string): [string, number[]] {
    let normalizedText = "";
    let map: number[] = [];
    let index = 0;

    for (let i = 0; i < text.length; i++) {
        if (/\s/.test(text[i])) {
            if (normalizedText.length === 0 || normalizedText[normalizedText.length - 1] !== ' ') {
                normalizedText += ' ';
                map.push(i);
            }
        } else {
            normalizedText += text[i];
            map.push(i);
        }
    }

    return [normalizedText, map];
}

function findTextPositions(bigText: string, searchText: string): Array<[number, number]> {
    const [normalizedBigText, bigTextMap] = normalizeTextAndMapIndices(bigText);
    const [normalizedSearchText] = normalizeTextAndMapIndices(searchText);
    const positions: Array<[number, number]> = [];
    let startIndex = 0;
    while (startIndex < normalizedBigText.length) {
        let index = normalizedBigText.indexOf(normalizedSearchText, startIndex);
        if (index === -1) break;

        let endIndex = index + normalizedSearchText.length - 1;

        // Use the map to translate indices back to the original text
        let originalStart = bigTextMap[index];
        let originalEnd = bigTextMap[endIndex];
        positions.push([originalStart, originalEnd])

        startIndex = index + 1;
    }

    return positions;
}

// Example usage:
// const bigText = "This is    the   example    text with  various spaces.";
// const searchText = "example text";
// const positions = findTextPositions(bigText, searchText);
// //console.log(positions);


export { TextView };
export type { TextViewProps };
