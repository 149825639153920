import React, { useState } from "react";
import { Tooltip, Upload, UploadFile } from "antd";
import { AiOutlineFileAdd } from "react-icons/ai";
import UploadFilesModal from "../UploadFilesModal/UploadFilesModal";

import styles from "./FilesUploader.module.css";

const FilesUploader = () => {
  const [filesToUpload, setFilesToUpload] = useState<UploadFile[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Tooltip title="You can also drop files here directly" placement="bottom">
        <Upload.Dragger
          multiple
          fileList={[]}
          className={styles.file_upload_dragger}
          accept=".docx, .doc, .dot, .edoc, .xlsx, .xls, .csv, .xml, .json, .txt, .pdf, .pptx, .png, .jpg, .jpeg, .tiff, .tif, .eml, .xhtml, .html, .htm, .vsdx"
          beforeUpload={() => false}
          onChange={({ fileList }) => {
            setFilesToUpload(fileList);
            setIsModalOpen(true);
          }}
        >
          <p className={styles.file_upload_dragger_text}>
            <AiOutlineFileAdd />
            Add files
          </p>
        </Upload.Dragger>
      </Tooltip>

      <UploadFilesModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        files={filesToUpload}
        setFiles={setFilesToUpload}
      />
    </>
  );
};

export default FilesUploader;
