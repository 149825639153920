import { useEffect } from "react";

interface useEffectWithIntervalAndConditionParams {
  logic: () => void;
  interval: number;
  condition: boolean;
  dependencies: React.DependencyList;
  additionalUnmountLogic?: () => void;
}

const useEffectWithIntervalAndCondition = (
  params: useEffectWithIntervalAndConditionParams
) => {
  useEffect(() => {
    let isMounted = true;

    params.logic();

    const intervalId = setInterval(() => {
      if (!isMounted || !params.condition) return;
      params.logic();
    }, params.interval);

    return () => {
      isMounted = false;
      clearInterval(intervalId);
      params.additionalUnmountLogic?.();
    };
  }, params.dependencies);
};

export default useEffectWithIntervalAndCondition;
