import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function AppAuthPage() {
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch the token from the API
    const fetchToken = async () => {
      try {
        const response = await fetch('https://soontobethebesttoolin.deloittece.com/api/token/');
        const data = await response.json();

        if (data && data.length > 0) {
          const token = data[0].token;
          sendTokenToLocalServer(token);
        } else {
          console.error('No token received from API');
        }
      } catch (error) {
        console.error('Error fetching token:', error);
      }
    };

    fetchToken();
  }, []);

  const sendTokenToLocalServer = async (token: string) => {
    try {
      const response = await fetch(`http://localhost:8841/auth_callback?token=${token}`);
      if (response.ok) {
        console.log('Token sent to local server successfully.');
        // Navigate to the home page
        navigate('/');
      } else {
        console.error('Failed to send token to local server.');
      }
    } catch (error) {
      console.error('Error sending token to local server:', error);
    }
  };

  return (
    <div style={{ padding: '20px', textAlign: 'center' }}>
      <h1>Authentication in Progress...</h1>
      <p>If you see this message for a long time, please check your internet connection.</p>
    </div>
  );
}

export default AppAuthPage;