import React from 'react';

import styles from './LoadingDots.module.css';

interface LoadingDotsProps {
  style?: React.CSSProperties,
}

const LoadingDots: React.FC<LoadingDotsProps> = props => (
  <div style={props.style} className={styles.loading_dots}>
    <div className={styles.dot} />
    <div className={styles.dot} />
    <div className={styles.dot} />
  </div>
);

export default LoadingDots;