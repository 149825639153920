const styleOptions = [
  {
    label: "Creative",
    value: 0.8,
  },
  {
    label: "Balanced",
    value: 0.4,
  },
  {
    label: "Precise",
    value: 0.0,
  },
];

export default styleOptions;
