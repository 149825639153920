function formatDate(input: string): string {
  const date = new Date(input);

  const optionsDate: Intl.DateTimeFormatOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
  const formattedDate: string = date.toLocaleDateString('en-GB', optionsDate);

  // For the time part
  const optionsTime: Intl.DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit', hour12: false };
  const formattedTime: string = date.toLocaleTimeString('en-GB', optionsTime);

  // Concatenating the date and time parts
  const formattedDateTime: string = `${formattedDate} - ${formattedTime}`;
  return formattedDateTime;
}

export default formatDate;