import { DefaultOptionType } from "antd/lib/cascader";

const outputFormats: DefaultOptionType[] = [
  { value: "content", label: <>Content</> },
  { value: "clause", label: <>Clause</> },
  {
    value: "date",
    label: <>Date</>,
    children: [
      { value: "dd/mm/yyyy", label: <>DD/MM/YYYY</> },
      { value: "dd.mm.yyyy", label: <>DD.MM.YYYY</> },
      { value: "dd-mm-yyyy", label: <>DD-MM-YYYY</> },
      { value: "yyyy-mm-dd", label: <>YYYY-MM-DD</> },
      { value: "mm/dd/yyyy", label: <>MM/DD/YYYY</> },
    ],
  },
  {
    value: "summary",
    label: (
      <>
        Section<br></br>Summary
      </>
    ),
    children: [
      { value: "short", label: <>Short</> },
      { value: "medium", label: <>Medium</> },
      { value: "long", label: <>Long</> },
    ],
  },
  { value: "question", label: <>Question </> },
  {
    value: "value",
    label: <>Value</>,
    children: [
      { value: "percentage", label: <>Percentage</> },
      { value: "whole number", label: <>Whole number</> },
      {
        value: "decimal with 1 digit",
        label: <>Decimal [1 digit]</>,
      },
      {
        value: "decimal with 2 digits",
        label: <>Decimal [2 digits]</>,
      },
      { value: "financial", label: <>Financial</> },
    ],
  },
  { value: "flag", label: <>Flag</> },
  { value: "choice", label: <>Choice</> },
];

export default outputFormats;
