import React, { ReactNode } from "react";

import { Select, Space, Spin, Popconfirm, Empty, Tooltip } from "antd";
import { MdDeleteOutline } from "react-icons/md";

import styles from "./ToolBase.module.css";
import filterOption from "../../../../helpers/selectFilterOption";

interface ToolBaseProps {
  icon: ReactNode;
  title: string;
  getTemplate: (template: any) => void;
  templates: { value: number; label: string }[];
  template: { value: number; label: string } | undefined;
  children: ReactNode;
  fetchingTemplates: boolean;
  deleteTemplate: (data: any) => Promise<void>;
  isDevelopmentMode?: boolean;
}

const ToolBase: React.FC<ToolBaseProps> = ({
  icon,
  title,
  getTemplate,
  templates,
  template,
  fetchingTemplates,
  deleteTemplate,
  isDevelopmentMode,
  children,
}) => {
  return (
    <div className={styles.tool}>
      <div className={styles.tool_header}>
        <div className={styles.tool_title}>
          <div className={styles.ribbon}>{icon}</div>
          <div className={styles.tool_title_text}>{title}</div>
        </div>

        <div className={styles.templates_container}>
          <Tooltip title={isDevelopmentMode ? "In Development" : ""}>
            <Select
              disabled={isDevelopmentMode}
              allowClear
              showSearch
              style={{ width: 300 }}
              placeholder="Select the template"
              // optionFilterProp="children"
              filterOption={filterOption}
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              loading={fetchingTemplates}
              notFoundContent={
                fetchingTemplates ? (
                  <Spin size="small" />
                ) : (
                  <Empty
                    description="No templates"
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                  />
                )
              }
              onChange={(value, option: any) => {
                getTemplate(option);
              }}
              value={template}
              options={templates}
              optionRender={(option) => (
                <Space className={styles.template_option}>
                  {option.label}
                  <Popconfirm
                    title="Are you sure you want to delete this template?"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={async (event) => {
                      event?.stopPropagation();
                      await deleteTemplate(option.data);
                    }}
                  >
                    <span
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    >
                      <MdDeleteOutline className={styles.delete_icon} />
                    </span>
                  </Popconfirm>
                </Space>
              )}
            />
          </Tooltip>
        </div>
      </div>
      <div className={styles.tool_content}>{children}</div>
    </div>
  );
};

export default ToolBase;
