async function downloadFile(response: Response) {
  if (!response.ok) return;

  const blob = await response.blob();
  const downloadUrl = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = downloadUrl;
  const contentDisposition = response.headers.get("Content-Disposition");
  let fileName = "download.pdf";
  if (contentDisposition) {
    const fileNameMatch = contentDisposition.match(
      /filename\*?=(?:(UTF-8'')?(.+))/i
    );
    if (fileNameMatch) {
      if (fileNameMatch[2]) {
        fileName = decodeURIComponent(fileNameMatch[2]);
      } else if (fileNameMatch[1]) {
        fileName = decodeURIComponent(fileNameMatch[1]);
      }
    }
  }
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  link.parentNode!.removeChild(link);
}

export default downloadFile;
