import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import type { ProColumns } from "@ant-design/pro-components";
import { Button, Empty, Popconfirm, Tooltip } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { MdDeleteOutline, MdEdit } from "react-icons/md";

import MultilineCascader from "../../../../../../components/MultilineCascader";
import TeamsContext from "../../../../../../contexts/TeamsContext";
import TypesContext from "../../../../../../contexts/ToolInputsContext";
import { MetadataContractType } from "../../../../../../data/PredefinedToolInputs";
import equalObjects from "../../../../../../helpers/equalObjects";
import generateUUID from "../../../../../../helpers/generateUUID";
import outputFormats from "../../../../../../helpers/outputFormats";
import * as TypesService from "../../services/predefinedData.service";
import CreateEditContractTypeModal from "../CreateEditContractTypeModal/CreateEditContractTypeModal";
import EditableTable from "../EditableTable/EditableTable";
import styles from "./MetadataExtractorInputs.module.css";
interface DataSourceType {
  key: React.Key;
  id: React.Key;
  metadata: string;
  description: string;
  typeAndOutputFormat: string[];
  children?: DataSourceType[];
}

const MetadataExtractorInputs: React.FC<{}> = (_) => {
  const teamsContext = useContext(TeamsContext);
  const typesContext = useContext(TypesContext);

  const [isLoading, setIsLoading] = useState(true);
  const [isTableLoading, setIsTableLoading] = useState<{
    [key: string]: boolean;
  }>({});
  const [editableKeys, setEditableRowKeys] = useState<React.Key[]>([]);

  const [showCreateEditContractTypeModal, setShowCreateEditContractTypeModal] =
    useState(false);
  const [modalContractType, setModalContractType] = useState<
    MetadataContractType | undefined
  >(undefined);

  const isAdmin = () => {
    return teamsContext?.teamSelected?.user_role === "Admin";
  };

  const columns: ({
    metadataContractTypeId,
  }: {
    metadataContractTypeId: React.Key;
  }) => ProColumns<DataSourceType>[] = ({
    metadataContractTypeId,
  }: {
    metadataContractTypeId: React.Key;
  }) => [
    {
      title: "Metadata",
      dataIndex: "metadata",
      valueType: "text",
      formItemProps: () => ({
        rules: [
          {
            required: true,
            max: 100,
            message: "Invalid input: between 0 - 100 characters.",
          },
        ],
      }),
      fieldProps: () => ({ placeholder: "Metadata name" }),
      width: "15vw",
    },
    {
      title: "Description",
      dataIndex: "description",
      valueType: "text",
      formItemProps: () => ({
        rules: [
          { max: 20000, message: "Invalid input: max 20000 characters." },
        ],
      }),
      fieldProps: () => ({ placeholder: "Metadata description" }),
      width: "40vw",
    },
    {
      title: "Type (Output format)",
      key: "typeAndOutputFormat",
      valueType: "cascader",
      dataIndex: "typeAndOutputFormat",
      formItemProps: () => ({
        rules: [{ required: true, message: "Field required." }],
      }),
      renderFormItem: () => (
        <MultilineCascader allowClear={false} options={outputFormats} />
      ),
      width: "10vw",
    },
    {
      title: "Actions",
      valueType: "option",
      width: "10vw",
      render: (text, record, _, action) => [
        <Tooltip key={`${record.id}_edit`} title="Edit" placement="left">
          <Button
            type="link"
            icon={<MdEdit color="var(--deloitte-green)" size="22" />}
            onClick={(e) => {
              e.preventDefault();
              action?.startEditable?.(record.id);
            }}
          />
        </Tooltip>,
        <Tooltip key={`${record.id}_delete`} title="Delete" placement="right">
          <Popconfirm
            title="Are you sure you want to delete this item?"
            okText="Yes"
            cancelText="No"
            onConfirm={async () => {
              handleTableLoading(metadataContractTypeId, true);
              await TypesService.deleteMetadataType(record.id.toString());
              typesContext?.fetchMetadataContractTypes(
                teamsContext?.teamSelected?.id!
              );
              handleTableLoading(metadataContractTypeId, false);
            }}>
            <Button
              type="link"
              icon={<MdDeleteOutline color="var(--beautiful-red)" size="22" />}
            />
          </Popconfirm>
        </Tooltip>,
      ],
      hidden: teamsContext?.teamSelected?.user_role !== "Admin",
    },
  ];

  useEffect(() => {
    const fetchToolsInput = async () => {
      typesContext?.fetchMetadataContractTypes(teamsContext?.teamSelected?.id!);
      setIsLoading(false);
    };
    setIsLoading(true);
    fetchToolsInput();
  }, [teamsContext?.teamSelected]);

  const handleTableLoading = (id: React.Key, loading: boolean) => {
    setIsTableLoading((prevState) => ({
      ...prevState,
      [id.toString()]: loading,
    }));
  };

  return isLoading ? (
    <div
      style={{
        flex: 1,
        display: "flex",
        alignSelf: "center",
        justifyContent: "center",
      }}>
      <LoadingOutlined
        style={{ fontSize: 20, color: "var(--deloitte-green)" }}
      />
    </div>
  ) : (
    <div className={styles.content}>
      {isAdmin() ? (
        <div className={styles.add_contract_type}>
          <Button
            onClick={() => {
              setModalContractType(undefined);
              setShowCreateEditContractTypeModal(true);
            }}
            icon={<PlusOutlined />}
            type="primary">
            Add document type
          </Button>
        </div>
      ) : undefined}
      {typesContext?.metadataContractTypes.map((metadataContractType) => (
        <div
          key={metadataContractType.id}
          className={styles.contract_type_item_content}>
          <div className={styles.contract_type_item_title}>
            <div>{metadataContractType.name}</div>
            {isAdmin() ? (
              <Tooltip title="Edit document type name" placement="top">
                <Button
                  type="link"
                  style={{ marginLeft: 16 }}
                  icon={<MdEdit color="var(--deloitte-green)" size="22" />}
                  onClick={() => {
                    setModalContractType(metadataContractType);
                    setShowCreateEditContractTypeModal(true);
                  }}
                />
              </Tooltip>
            ) : undefined}
            {isAdmin() ? (
              <Tooltip title="Delete document type" placement="top">
                <Popconfirm
                  title="Are you sure you want to delete this item?"
                  okText="Yes"
                  cancelText="No"
                  onConfirm={async () => {
                    await TypesService.deleteMetadataContractType(
                      metadataContractType.id
                    );
                    typesContext?.fetchMetadataContractTypes(
                      teamsContext?.teamSelected?.id!
                    );
                  }}>
                  <Button
                    type="link"
                    icon={
                      <MdDeleteOutline color="var(--beautiful-red)" size="22" />
                    }
                  />
                </Popconfirm>
              </Tooltip>
            ) : undefined}
          </div>
          <EditableTable<DataSourceType>
            className={styles.contract_type_item}
            rowKey="id"
            showHeader
            tableStyle={{ top: 30 }}
            tableLayout="fixed"
            loading={isTableLoading[metadataContractType.id] || false}
            recordCreatorProps={
              isAdmin()
                ? {
                    position: "bottom",
                    record: () => ({
                      id: generateUUID(),
                    }),
                    creatorButtonText: "Add new metadata type",
                  }
                : false
            }
            columns={columns({
              metadataContractTypeId: metadataContractType.id,
            })}
            value={metadataContractType.metadata_types.map(
              (metadataType, index) => ({
                key: index,
                id: metadataType.id,
                metadata: metadataType.name,
                description: metadataType.description,
                typeAndOutputFormat: metadataType.output_format
                  ? [metadataType.type, metadataType.output_format]
                  : [metadataType.type],
              })
            )}
            editable={{
              type: "multiple",
              editableKeys: editableKeys,
              onSave: async (key, data, row) => {
                const isNew = row.key === undefined;
                if (isNew) {
                  handleTableLoading(metadataContractType.id, true);
                  await TypesService?.createMetadataType(
                    metadataContractType.id,
                    {
                      name: data.metadata,
                      description: data.description,
                      type: data.typeAndOutputFormat[0],
                      output_format:
                        data.typeAndOutputFormat.length === 2
                          ? data.typeAndOutputFormat[1]
                          : "",
                    }
                  );
                  typesContext?.fetchMetadataContractTypes(
                    teamsContext?.teamSelected?.id!
                  );
                  handleTableLoading(metadataContractType.id, false);
                  return;
                }
                if (equalObjects(data, row)) return;
                handleTableLoading(metadataContractType.id, true);
                await TypesService.editMetadataType(data.id.toString(), {
                  name: data.metadata,
                  description: data.description,
                  type: data.typeAndOutputFormat[0],
                  output_format:
                    data.typeAndOutputFormat.length === 2
                      ? data.typeAndOutputFormat[1]
                      : "",
                });
                typesContext?.fetchMetadataContractTypes(
                  teamsContext?.teamSelected?.id!
                );
                handleTableLoading(metadataContractType.id, false);
              },
              onChange: setEditableRowKeys,
              onlyAddOneLineAlertMessage:
                "Please add only one item per contract type at a time.",
              cancelText: <div style={{ color: "#c7c7c7" }}>Cancel</div>,
              saveText: (
                <div style={{ color: "var(--deloitte-green)" }}>Save</div>
              ),
              actionRender: (_, __, dom) => [dom.save, dom.cancel],
            }}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="No metadata in this contract type"
                />
              ),
            }}
          />
        </div>
      ))}

      <CreateEditContractTypeModal
        isOpen={showCreateEditContractTypeModal}
        closeModal={async () => {
          typesContext?.fetchMetadataContractTypes(
            teamsContext?.teamSelected?.id!
          );
          setShowCreateEditContractTypeModal(false);
        }}
        contractTypeToEdit={modalContractType}
        createContractTypeFn={TypesService.createMetadataContractType}
        editContractTypeFn={
          TypesService.editMetadataContractType
        }></CreateEditContractTypeModal>
    </div>
  );
};

export default MetadataExtractorInputs;
