import React, { ReactNode, createContext, useEffect, useState, useContext } from "react";
import Team from "../data/Team";

interface TeamContextType {
  teams: Array<Team>;
  setTeams: React.Dispatch<React.SetStateAction<Array<Team>>>;
  teamSelected: Team | undefined;
  setTeamSelected: React.Dispatch<React.SetStateAction<Team | undefined>>;
  fetchTeams: () => Promise<void>;
}

const backendUrl = process.env.REACT_APP_API_URL ?? "";

const TeamsContext = createContext<TeamContextType | undefined>(undefined);

interface TeamsProviderProps {
  children: ReactNode;
}

export const TeamsProvider: React.FC<TeamsProviderProps> = (props) => {
  const [teams, setTeams] = useState<Array<Team>>([]);
  const [teamSelected, setTeamSelected] = useState<Team | undefined>(undefined);
  const teamsContext = useContext(TeamsContext);
  const pathParts = window.location.pathname.split('/');
  const teamId = pathParts[1];
  const projectId = pathParts[2];
  const tool = pathParts[3];
  // useEffect(() => {
  //   if (teams.length === 0) {
  //     // setTeamSelected(undefined);
  //   } else {
  //   setTeamSelected(teamId?teams?.filter(t=>t.id===parseInt(teamId))[0]:undefined);
  //   }

  // }, [teams]);

  const fetchTeams = async () => {
    const response = await fetch(`${backendUrl}/api/teams`, {
      method: "GET",
      credentials: "include",
    });
    const body = await response.json();
    if (body.length === 0) return;

    // setTeamSelected({
    //   id: body[0].id,
    //   name: body[0].name,
    //   user_role: body[0].user_role,
    //   tools: body[0].tools.map(
    //     (tool: { id: string | number; name: string }) => tool.name
    //   ),
    // });
    setTeams(
      body.map(
        (team: {
          id: string | number;
          name: string;
          user_role: string;
          tools: Array<{ id: string | number; name: string }>;
        }) => ({
          id: team.id,
          name: team.name,
          user_role: team.user_role,
          tools: team.tools.map((tool) => tool.name),
        })
      )
    );
  };

  return (
    <TeamsContext.Provider
      value={{
        teams,
        setTeams,
        teamSelected,
        setTeamSelected,
        fetchTeams,
      }}>
      {props.children}
    </TeamsContext.Provider>
  );
};

export default TeamsContext;
