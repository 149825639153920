import { SearchOutlined } from "@ant-design/icons";
import type { GetRef, TableColumnType } from "antd";
import { Button, Input, Space } from "antd";
import type { FilterDropdownProps } from "antd/es/table/interface";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import MainContext from "../../contexts/MainContext";
import ResultsContext from "../../contexts/ResultsContext";
import ToolSelectorCarouselWithArrows from "./components/carousel/ToolSelectorCarouselWithArrows";
import BatchActions from "./components/BatchActions/BatchActions";
import ResultComponents from "./helpers/resultComponents";
import { ToolResult } from "./interfaces/toolsResults.interface";
import { ResultTypes } from "./enums/resultTypes.enum";
import ToolResultTypes from "./helpers/toolResultTypes";
import * as ResultsService from "./services/results.service";
import styles from "./ResultsPage.module.css";
import scrollToView from "../../helpers/scrollToView";

type InputRef = GetRef<typeof Input>;

const ResultsPage: React.FC<{}> = (_) => {
  const navigate = useNavigate();
  const mainContext = useContext(MainContext);
  const resultsContext = useContext(ResultsContext);
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const initialExpandedRowKeys =
    queryParams.get("expandedRowKeys")?.split(",").map(Number) || [];
  const tool = queryParams.get("tool");
  const initialPage = Number(queryParams.get("page")) || 1;

  const [toolSelected, setToolSelected] = useState<ResultTypes | undefined>(
    mainContext?.teamSelected?.tools.length !== 0
      ? tool
        ? (tool as ResultTypes)
        : ToolResultTypes[mainContext?.teamSelected?.tools[0]!]
      : undefined
  );
  const [toolResults, setToolResults] = useState<ToolResult[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState<Array<React.Key>>([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>(
    initialExpandedRowKeys
  );
  const [currentPage, setCurrentPage] = useState(initialPage);
  const searchInput = useRef<InputRef>(null);

  useEffect(() => {
    const fetchToolResults = async () => {
      setSelectedRows([]);
      if (toolSelected && mainContext?.projectSelected) {
        setToolResults(
          await ResultsService.fetchToolResults(
            mainContext.projectSelected.id,
            toolSelected
          )
        );

        const queryParams = new URLSearchParams(location.search);
        const oldTool = queryParams.get("tool");

        let queryString = `?tool=${toolSelected}&page=1`;

        if (oldTool === (toolSelected as string)) {
          queryString += `&expandedRowKeys=${expandedRowKeys.join(
            ","
          )}&page=${currentPage}`;
        } else {
          setExpandedRowKeys([]);
          setCurrentPage(1);
        }

        navigate(
          `/${mainContext?.teamSelected?.id}/${mainContext?.projectSelected.id}/results${queryString}`,
          { replace: true }
        );
        setTimeout(() => {
          scrollToView('[data-row-key="' + Math.max(...expandedRowKeys) + '"]');
        }, 1000);
      }
      setIsLoading(false);
    };
    setIsLoading(true);
    fetchToolResults();
  }, [
    mainContext?.projectSelected,
    toolSelected,
    resultsContext?.fetchingToggle,
  ]);

  useEffect(() => {
    if (
      mainContext?.teamSelected &&
      mainContext?.projectSelected &&
      toolSelected
    ) {
      const queryString = `?tool=${toolSelected}&expandedRowKeys=${expandedRowKeys.join(
        ","
      )}&page=${currentPage}`;
      navigate(
        `/${mainContext?.teamSelected?.id}/${mainContext?.projectSelected?.id}/results${queryString}`,
        { replace: true }
      );
    }
  }, [expandedRowKeys, currentPage]);

  const handleSearch = (
    selectedKeys: string[],
    confirm: FilterDropdownProps["confirm"],
    dataIndex: any
  ) => {
    confirm();
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
  };

  const getColumnSearchProps = (dataIndex: any): TableColumnType<any> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    render: (text) => text,
  });

  const renderResultComponent = () => {
    if (toolSelected) {
      const ResultComponent = ResultComponents[toolSelected];
      return (
        <ResultComponent
          isLoading={isLoading}
          allResults={toolResults}
          getColumnSearchProps={getColumnSearchProps}
          selectRows={setSelectedRows}
          expandedRowKeys={expandedRowKeys}
          setExpandedRowKeys={setExpandedRowKeys}
          currentPage={currentPage} // pass the current page to the component
          setCurrentPage={setCurrentPage} // allow the component to update the page
        />
      );
    } else {
      return <></>;
    }
  };

  return (
    <>
      <div className={styles.content}>
        <ToolSelectorCarouselWithArrows
          toolSelected={toolSelected}
          setToolSelected={setToolSelected}
        ></ToolSelectorCarouselWithArrows>

        <BatchActions toolSelected={toolSelected} selectedRows={selectedRows} />

        {renderResultComponent()}
      </div>
    </>
  );
};

export default ResultsPage;
