import React, { useState, useContext } from "react";
import { createPortal } from "react-dom";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

import styles from "./RemoveMemberModal.module.css";
import TeamsContext from "../../../../../../contexts/TeamsContext";
import { TeamMember } from "../../../../../../data/User";
import { removeTeamMember } from "../../../../services/teams.service";

interface _RemoveMemberModalProps {
  fetchMembers: () => Promise<void>;
  onClose: () => void;
  teamMember: TeamMember;
}

const RemoveMemberModal: React.FC<_RemoveMemberModalProps> = ({
  fetchMembers,
  onClose,
  teamMember,
}) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const teamsContext = useContext(TeamsContext);

  const yesHandler = async () => {
    setIsDeleting(true);
    await removeTeamMember(teamsContext?.teamSelected?.id!, teamMember.id);
    await fetchMembers();
    onClose();
  };
  const noHandler = () => onClose();

  return createPortal(
    <>
      <div className={styles.remove_member_modal_overlay}></div>
      <div className={styles.remove_member_modal}>
        <div style={{ fontSize: 20, fontWeight: "bold" }}>
          Are you sure you want to continue?
        </div>
        <div style={{ height: 8 }} />
        <div style={{ fontSize: 14 }}>
          User "{teamMember.fullname} ({teamMember.username})" will be removed
          from this team!
        </div>
        <div style={{ height: 22 }} />
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            disabled={isDeleting}
            variant="dark"
            className={styles.remove_member_modal_close_button}
            onClick={noHandler}>
            <div style={{ fontSize: 14, fontWeight: "bold", color: "#fff" }}>
              No
            </div>
          </Button>
          <div style={{ width: 10 }} />
          <Button
            disabled={isDeleting}
            variant="dark"
            className={styles.remove_member_modal_submit_button}
            onClick={yesHandler}>
            {isDeleting ? (
              <Spinner
                style={{ width: 20, height: 20, borderWidth: 2 }}></Spinner>
            ) : (
              <div style={{ fontSize: 14, fontWeight: "bold", color: "#fff" }}>
                Yes
              </div>
            )}
          </Button>
        </div>
      </div>
    </>,
    document.getElementById("portal")!
  );
};

export default RemoveMemberModal;
