import { Modal } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

interface ChangePageModalProps {
  url: string;
  showChangePageModal: boolean;
  onClose: () => void;
}

const ChangePageModal: React.FC<ChangePageModalProps> = ({
  url,
  showChangePageModal,
  onClose,
}) => {
  const navigate = useNavigate();

  return (
    <>
      <Modal
        centered
        title="Are you sure you want to leave the page?"
        open={showChangePageModal}
        onOk={() => navigate(url)}
        onCancel={() => onClose()}
        okText="Yes"
        cancelText="No"
      >
        <p>You have unsaved changes.</p>
      </Modal>
    </>
  );
};

export default ChangePageModal;
