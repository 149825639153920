import React from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

import "./CustomArrows.css";
interface ArrowProps {
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
}

const CustomPrevArrow: React.FC<ArrowProps> = ({
  className,
  style,
  onClick,
}) => (
  <div className={className} onClick={onClick}>
    <IoIosArrowBack className="carousel-arrow-icon" size={"24px"} />
  </div>
);

const CustomNextArrow: React.FC<ArrowProps> = ({
  className,
  style,
  onClick,
}) => (
  <div className={className} onClick={onClick}>
    <IoIosArrowForward className="carousel-arrow-icon" size={"24px"} />
  </div>
);

export { CustomNextArrow, CustomPrevArrow };
