async function readBase64(base64Url: string, filename: string) {
  const byteString = atob(base64Url.split(',')[1]);
  const mimeString = base64Url.split(',')[0].split(':')[1].split(';')[0];

  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  const blob = new Blob([ab], { type: mimeString });
  const file = new File([blob], filename, { type: blob.type });

  return file;
}

export default readBase64;