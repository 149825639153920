import { DraftingTypes } from "./draftingTypes.enum";

type DescriptionsMap = {
  [key in DraftingTypes]: string;
};

const descriptions: DescriptionsMap = {
  [DraftingTypes.OneToOne]:
    "This option creates a separate document for each file you upload, ensuring a precise one-to-one match between your input files and the output documents. To use this option, prepare the files you wish to process, ensuring that each file contains the content you wish to convert individually. You can also upload additional files such as Excel spreadsheets, reports or references that will be used as sources for the documents. Upload all these files using the upload interface. Once uploaded, the system will process each file independently, creating a separate document for each input file. This option is ideal for users who need separate, stand-alone documents for each input, such as different reports, articles or papers that should not be merged. For example, if you have several project reports or articles that you need to convert into separate, individual documents, this option will meet your needs by keeping the content of each file separate from the others, while incorporating relevant information from the additional sources. ",
  [DraftingTypes.MultipleToOne]:
    "This scenario processes all uploaded files and process their content into a single, comprehensive document, providing a consolidated view of all your inputs. To use this option, collect all the files you want to process into a single document and upload these files using the upload interface. The system will analyze and process the content from all uploaded files into one cohesive document. This option is ideal for users who need a unified document that aggregates information from multiple sources, such as comprehensive reports, combined meeting notes, or research papers. For instance, if you have several meeting notes or research papers that you want to compile into a single, cohesive document, this option will merge all the inputs to create one comprehensive output. ",
  [DraftingTypes.TemplateBased]:
    "This option allows you to generate drafted documents based on a pre-selected template and a custom prompt, ensuring that the output adheres to specific formats and styles. To use this option, select a template from the available options that best fits the type of document you want to create. Provide a custom prompt if necessary to guide the content generation according to your specific needs. Upload your files, and the system will draft documents that follow the chosen template and incorporate the details from your prompt. This option is ideal for users who require documents that follow a standardized format, such as resumes, business reports, or other formal documents. For example, if you need to create standardized resumes or formal business reports, this option will ensure that the documents adhere to the selected template and custom prompts, maintaining consistency and professional formatting. ",
  [DraftingTypes.PromptBased]:
    "This scenario tailors the generated documents according to a custom prompt, tone, and style specified by you, allowing for a highly personalized and customized output. To use this option, provide a detailed custom prompt outlining the specific content, tone, and style you want the documents to reflect. Upload your files using the upload interface. The system will use your prompt to draft documents that match your specified tone and style, creating a personalized output. This option is ideal for users who need documents with a unique voice or style, such as personalized letters, creative content, or any document requiring a distinct touch. For instance, if you are crafting personalized letters, creative stories, or any document that requires a unique style and tone, this option allows you to customize the output to reflect your specific requirements and preferences. ",
};

export default descriptions;
