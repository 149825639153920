import React, { ReactNode } from "react";
import { CSSTransition } from "react-transition-group";

import styles from "./TransitionToolWrapper.module.css";

interface TransitionToolWrapperProps {
  children: ReactNode;
}

const TransitionToolWrapper: React.FC<TransitionToolWrapperProps> = ({
  children,
}) => {
  return (
    <CSSTransition
      in={true}
      timeout={260}
      classNames={{
        enter: styles.growEnter,
        enterActive: styles.growEnterActive,
        exit: styles.shrinkExit,
        exitActive: styles.shrinkExitActive,
      }}
      mountOnEnter
      unmountOnExit
    >
      {children}
    </CSSTransition>
  );
};

export default TransitionToolWrapper;
