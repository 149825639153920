import React from "react";
import { InboxOutlined } from "@ant-design/icons";
import { Upload } from "antd";
import { DraftingForm } from "../../interfaces/draftingForm.interface";

interface FilesUploaderProps {
  formData: DraftingForm;
  setFormData: (form: DraftingForm) => void;
}

const FilesUploader: React.FC<FilesUploaderProps> = ({
  formData,
  setFormData,
}) => {
  return (
    <Upload.Dragger
      accept=".docx, .doc, .txt, .pdf, .pptx"
      maxCount={4}
      multiple
      fileList={formData.additionalFileList}
      onChange={({ fileList }) =>
        setFormData({ ...formData, additionalFileList: fileList })
      }
      onRemove={(deletedFile) => {
        setFormData({
          ...formData,
          additionalFileList: formData.additionalFileList.filter(
            (file) => file.uid !== deletedFile.uid
          ),
        });
        return false;
      }}
      customRequest={({ file, onSuccess }) => {
        setTimeout(() => {
          if (onSuccess) onSuccess("ok");
        }, 0);
      }}
      beforeUpload={() => false}
      showUploadList={{ showRemoveIcon: true }}
    >
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">
        Click or drag up to 4 files to this area to upload
      </p>
    </Upload.Dragger>
  );
};

export default FilesUploader;
