import React, { useState } from "react";

import styles from "./Bubble.module.css";

interface BubbleProps {
  imageWidth: number;
  imageHeight: number;
  imageUrl: string;
  title: string;
  titleFontSize: number;
  spaceBetweenImgAndTitle: number;
  onClick: () => void;
  disableAnimation?: boolean;
}

const Bubble: React.FC<BubbleProps> = (props) => {
  const [imageUrl, setImageUrl] = useState(props.imageUrl);

  const handleImageError = () => {
    setImageUrl(require("../../../../../../images/unknown_user_icon.jpg"));
  };

  return (
    <div
      onClick={props.onClick}
      className={styles.content}
      style={{ transform: props.disableAnimation ? "none" : undefined }}>
      <img
        src={imageUrl}
        onError={handleImageError}
        alt={props.title}
        className={styles.image}
        style={{ width: props.imageWidth, height: props.imageHeight }}
      />
      <div
        style={{
          fontSize: props.titleFontSize,
          marginTop: props.spaceBetweenImgAndTitle,
        }}>
        {props.title}
      </div>
    </div>
  );
};

export default Bubble;
