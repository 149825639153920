import React from "react";
import { IoIosArrowBack } from "react-icons/io";
import styles from "./SiteTitle.module.css";

interface SiteTitleProps {
  onSideMenuToogle: () => void;
  sideMenuIsOpen: boolean;
}

const SideMenu: React.FC<SiteTitleProps> = ({
  onSideMenuToogle,
  sideMenuIsOpen,
}) => {
  return (
    <>
      <div
        className={`${styles.side_menu} ${!sideMenuIsOpen && styles.closed}`}
      >
        <div className={styles.page_title_container}>
          {sideMenuIsOpen && (
            <div className={styles.page_title}>
              <img
                className={styles.page_title_image}
                src={require("../../../../../../images/documentor_logo.png")}
                alt="DocuMentor"
              />
              <p className={styles.page_title_text}>DocuMentor</p>
            </div>
          )}
          <div
            onClick={onSideMenuToogle}
            className={`${styles.toggle_menu_button} ${
              !sideMenuIsOpen && styles.closed
            }`}
          >
            <IoIosArrowBack />
          </div>
        </div>
      </div>
    </>
  );
};

export default SideMenu;
