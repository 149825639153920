import generateUUID from "../helpers/generateUUID";

type MyTools =
  | MetadataExtractorTool
  | ObligationsExtractorTool
  | RisksIdentifierTool
  | DocumentComparisonTool
  | DocumentDraftingTool
  | DocumentSummarizationTool
  | PortfolioScanningTool
  | TranslationTool;

class MetadataExtractorTool {
  private key = "metadata_extractor";
  private contractType: { id: string; value: string } | null = null;
  private options: {
    key: string | number;
    metadata_parameter: string;
    description: string;
    metadata_type: string;
    output_format: string;
  }[] = [];
  private customOptions: {
    key: string | number;
    metadata_parameter: string;
    description: string;
    metadata_type: string;
    output_format: string;
  }[] = [];
  private language: string = "English";

  getKey() {
    return this.key;
  }

  getContractType() {
    return this.contractType;
  }

  getOptions() {
    return this.options;
  }

  getCustomOptions() {
    return this.customOptions;
  }

  updateContractType(contractType: { id: string; value: string } | null) {
    this.contractType = contractType;
    this.options = [];
  }

  updateOptions(
    options: {
      key: string | number;
      metadata_parameter: string;
      description: string;
      metadata_type: string;
      output_format: string;
    }[]
  ) {
    this.options = options;
  }

  addCustomOption(customOption: {
    metadata_parameter: string;
    description: string;
    metadata_type: string;
    output_format: string;
  }) {
    this.customOptions.push({ ...customOption, key: generateUUID() });
  }

  removeCustomOption(key: string) {
    this.customOptions = this.customOptions.filter(
      (customOption) => customOption.key !== key
    );
  }

  getLanguage() {
    return this.language;
  }

  updateLanguage(language: string = "English") {
    this.language = language;
  }
}

class ObligationsExtractorTool {
  private key = "obligations_extractor";
  private contractType: { id: string; value: string } | null = null;
  private options: {
    key: string | number;
    name: string;
    description: string;
  }[] = [];
  private customOptions: { key: string; name: string }[] = [];
  private language: string = "English";

  getKey() {
    return this.key;
  }

  getContractType() {
    return this.contractType;
  }

  getOptions() {
    return this.options;
  }

  getCustomOptions() {
    return this.customOptions;
  }

  updateContractType(contractType: { id: string; value: string } | null) {
    this.contractType = contractType;
    this.options = [];
  }

  updateOptions(
    options: { key: string | number; name: string; description: string }[]
  ) {
    this.options = options;
  }

  addCustomOption(customOption: { name: string }) {
    this.customOptions.push({ ...customOption, key: generateUUID() });
  }

  removeCustomOption(key: string) {
    this.customOptions = this.customOptions.filter(
      (customOption) => customOption.key !== key
    );
  }

  getLanguage() {
    return this.language;
  }

  updateLanguage(language: string = "English") {
    this.language = language;
  }
}

class RisksIdentifierTool {
  private key = "risks_identifier";
  private contractType: { id: string; value: string } | null = null;
  private options: {
    key: string | number;
    name: string;
    description: string;
  }[] = [];
  private language: string = "English";

  getKey() {
    return this.key;
  }

  getContractType() {
    return this.contractType;
  }

  getOptions() {
    return this.options;
  }

  updateOptions(
    options: { key: string | number; name: string; description: string }[]
  ) {
    this.options = options;
  }

  updateContractType(contractType: { id: string; value: string } | null) {
    this.contractType = contractType;
    this.options = [];
  }

  getLanguage() {
    return this.language;
  }

  updateLanguage(language: string = "English") {
    this.language = language;
  }
}

class DocumentComparisonTool {
  private key = "document_comparison";
  private comparisonType: "playbook" | "regulation" | "contract" | "checklist" =
    "playbook";
  private regulationComparisonType?: "list" | "file";
  private checklistComparisonType?: "list" | "file";
  private regulation?: string;
  private checklist?: string;
  private file: File[] = [];
  private options: { key: string | number; name: string }[] = [];
  private clausesToCheck: string = "";
  private language: string = "English";

  getKey() {
    return this.key;
  }

  getComparisonType() {
    return this.comparisonType;
  }

  updateComparisonType(
    comparisonType: "playbook" | "regulation" | "contract" | "checklist"
  ) {
    this.file = [];
    this.regulationComparisonType = undefined;
    this.checklistComparisonType = undefined;
    this.regulation = undefined;
    this.checklist = undefined;
    if (comparisonType === "regulation")
      this.updateRegulationComparisonType("list");
    if (comparisonType === "checklist")
      this.updateChecklistComparisonType("list");
    this.comparisonType = comparisonType;
  }

  getRegulationComparisonType() {
    return this.regulationComparisonType;
  }

  updateRegulationComparisonType(regulationComparisonType: "list" | "file") {
    this.file = [];
    this.regulation = undefined;
    this.regulationComparisonType = regulationComparisonType;
  }

  getChecklistComparisonType() {
    return this.checklistComparisonType;
  }

  updateChecklistComparisonType(checklistComparisonType: "list" | "file") {
    this.file = [];
    this.checklist = undefined;
    this.checklistComparisonType = checklistComparisonType;
  }

  getRegulation() {
    return this.regulation;
  }

  updateRegulation(regulation: string) {
    this.regulation = regulation;
  }

  getChecklist() {
    return this.checklist;
  }

  updateChecklist(checklist: string) {
    this.checklist = checklist;
  }

  getFile() {
    return this.file;
  }

  updateFile(file: File[]) {
    this.file = file;
  }

  getOptions() {
    return this.options;
  }

  updateOptions(
    options: { key: string | number; name: string; description: string }[]
  ) {
    this.options = options;
  }

  getClausesToCheck() {
    return this.clausesToCheck;
  }

  updateClausesToCheck(clausesToCheck: string) {
    this.clausesToCheck = clausesToCheck;
  }

  getLanguage() {
    return this.language;
  }

  updateLanguage(language: string = "English") {
    this.language = language;
  }
}

class DocumentDraftingTool {
  private key = "document_drafting";
  private contractType: { id: string; value: string } | null = null;
  private customPrompt: string = "";
  private tone: string = "";
  private language: string = "English";
  private anonymizeData: boolean = false;
  private provideSources: boolean = false;

  getKey() {
    return this.key;
  }

  getContractType() {
    return this.contractType;
  }

  updateContractType(contractType: { id: string; value: string } | null) {
    this.contractType = contractType;
  }

  getCustomPrompt() {
    return this.customPrompt;
  }

  updateCustomPrompt(customPrompt: string = "") {
    this.customPrompt = customPrompt;
  }

  getTone() {
    return this.tone;
  }

  updateTone(tone: string = "") {
    this.tone = tone;
  }

  getLanguage() {
    return this.language;
  }

  updateLanguage(language: string = "English") {
    this.language = language;
  }

  getAnonymizeData() {
    return this.anonymizeData;
  }

  updateAnonymizeData(anonymizeData: boolean = false) {
    this.anonymizeData = anonymizeData;
  }

  getProvideSources() {
    return this.provideSources;
  }

  updateProvideSources(provideSources: boolean = false) {
    this.provideSources = provideSources;
  }
}

class DocumentSummarizationTool {
  private key = "document_summarization";
  private contractType: { id: string; value: string } | null = null;
  private scenario: { id: string; value: string } | null = null;
  private customPrompt: string = "";
  private length: number = 400;
  private tone: string = "";
  private language: string = "English";
  private anonymizeData: boolean = false;
  private provideSources: boolean = false;

  getKey() {
    return this.key;
  }

  getContractType() {
    return this.contractType;
  }

  updateContractType(contractType: { id: string; value: string } | null) {
    this.contractType = contractType;
  }

  getScenario() {
    return this.scenario;
  }

  updateScenario(scenario: { id: string; value: string } | null) {
    this.scenario = scenario;
  }

  getCustomPrompt() {
    return this.customPrompt;
  }

  updateCustomPrompt(customPrompt: string = "") {
    this.customPrompt = customPrompt;
  }

  getLength() {
    return this.length;
  }

  updateLength(length: number = 400) {
    this.length = length;
  }

  getTone() {
    return this.tone;
  }

  updateTone(tone: string = "") {
    this.tone = tone;
  }

  getLanguage() {
    return this.language;
  }

  updateLanguage(language: string = "English") {
    this.language = language;
  }

  getAnonymizeData() {
    return this.anonymizeData;
  }

  updateAnonymizeData(anonymizeData: boolean = false) {
    this.anonymizeData = anonymizeData;
  }

  getProvideSources() {
    return this.provideSources;
  }

  updateProvideSources(provideSources: boolean = false) {
    this.provideSources = provideSources;
  }
}

class PortfolioScanningTool {
  private key = "portfolio_scanning";
  private isTimeline: boolean = false;
  private comparisonFeatures: {
    key: string | number;
    portfolio_parameter: string;
  }[] = [];
  private language: string = "English";

  getKey() {
    return this.key;
  }

  getIsTimeline() {
    return this.isTimeline;
  }

  updateIsTimeline(isTimeline: boolean = false) {
    this.isTimeline = isTimeline;
  }

  getComparisonFeatures() {
    return this.comparisonFeatures;
  }

  updateComparisonFeatures(
    features: { key: string | number; portfolio_parameter: string }[]
  ) {
    this.comparisonFeatures = features;
  }

  getLanguage() {
    return this.language;
  }

  updateLanguage(language: string = "English") {
    this.language = language;
  }
}

class TranslationTool {
  private key = "translation_tool";

  private language: string = "English";

  getKey() {
    return this.key;
  }

  getLanguage() {
    return this.language;
  }

  updateLanguage(language: string = "English") {
    this.language = language;
  }
}

interface ToolsReducerAction {
  type:
    | "UPDATE_OPTIONS"
    | "ADD_CUSTOM_OPTION"
    | "REMOVE_CUSTOM_OPTION"
    | "UPDATE_METADATA_LANGUAGE"
    | "UPDATE_OBLIGATIONS_LANGUAGE"
    | "UPDATE_CONTRACT_TYPE"
    | "ADD_TOOL"
    | "REMOVE_TOOL"
    | "UPDATE_COMPARISON_TYPE"
    | "UPDATE_REGULATION_COMPARISON_TYPE"
    | "UPDATE_CHECKLIST_COMPARISON_TYPE"
    | "UPDATE_COMPARISON_REGULATION"
    | "UPDATE_COMPARISON_CHECKLIST"
    | "UPDATE_RISK_LANGUAGE"
    | "UPDATE_FILE"
    | "UPDATE_CLAUSES_TO_CHECK"
    | "UPDATE_COMPARISON_LANGUAGE"
    | "UPDATE_DRAFTING_DOCUMENT_TYPE"
    | "UPDATE_DRAFTING_CUSTOM_PROMPT"
    | "UPDATE_DRAFTING_TONE"
    | "UPDATE_DRAFTING_LANGUAGE"
    | "UPDATE_DRAFTING_ANONYMIZE_DATA"
    | "UPDATE_DRAFTING_PROVIDE_SOURCES"
    | "UPDATE_SUMMARIZATION_DOCUMENT_TYPE"
    | "UPDATE_SUMMARIZATION_SCENARIO"
    | "UPDATE_SUMMARIZATION_CUSTOM_PROMPT"
    | "UPDATE_SUMMARIZATION_LENGTH"
    | "UPDATE_SUMMARIZATION_TONE"
    | "UPDATE_SUMMARIZATION_LANGUAGE"
    | "UPDATE_SUMMARIZATION_ANONYMIZE_DATA"
    | "UPDATE_SUMMARIZATION_PROVIDE_SOURCES"
    | "UPDATE_PORTFOLIO_SCANNING_TIMELINE"
    | "UPDATE_PORTFOLIO_SCANNING_COMPARISON_FEATURES"
    | "UPDATE_PORTFOLIO_SCANNING_LANGUAGE"
    | "CLEAR_STATE";
  key: string;
  options?: {
    key: string | number;
    metadata_parameter: string;
    description: string;
    metadata_type: string;
    output_format: string;
  }[] &
    { key: string | number; name: string }[] &
    { key: string | number; name: string; description: string }[];
  customOption?: {
    key: string;
    metadata_parameter: string;
    description: string;
    metadata_type: string;
    output_format: string;
  } & { key: string; name: string };
  contractType?: { id: string; value: string } | null;
  customOptionKey?: string;
  comparisonType?: "playbook" | "regulation" | "contract" | "checklist";
  regulationComparisonType?: "list" | "file";
  checklistComparisonType?: "list" | "file";
  comparisonRegulation?: string;
  comparisonChecklist?: string;
  file?: File[];
  clausesToCheck?: string;
  language?: string;
  draftingContractType?: { id: string; value: string } | null;
  draftingCustomPrompt?: string;
  draftingTone?: string;
  draftingLanguage?: string;
  draftingAnonymizeData?: boolean;
  draftingProvideSources?: boolean;
  summarizationContractType?: { id: string; value: string } | null;
  summarizationScenario?: { id: string; value: string } | null;
  summarizationCustomPrompt?: string;
  summarizationLength?: number;
  summarizationTone?: string;
  summarizationLanguage?: string;
  summarizationAnonymizeData?: boolean;
  summarizationProvideSources?: boolean;
  portfolioScanningIsTimeline?: boolean;
  portfolioScanningComparisonFeatures?: {
    key: string | number;
    portfolio_parameter: string;
  }[];
}

const toolsReducer = (state: Array<MyTools>, action: ToolsReducerAction) => {
  const oldState = [...state];
  switch (action.type) {
    case "UPDATE_OPTIONS": {
      const toolToEdit = oldState.find(
        (tool) => tool.getKey() === action.key
      ) as
        | MetadataExtractorTool
        | ObligationsExtractorTool
        | RisksIdentifierTool
        | DocumentComparisonTool;
      toolToEdit?.updateOptions(action.options!);
      return [...oldState];
    }
    case "ADD_CUSTOM_OPTION": {
      let toolToEdit = oldState.find((tool) => tool.getKey() === action.key) as
        | MetadataExtractorTool
        | ObligationsExtractorTool;
      toolToEdit?.addCustomOption(action.customOption!);
      return [...oldState];
    }
    case "REMOVE_CUSTOM_OPTION": {
      const toolToEdit = oldState.find(
        (tool) => tool.getKey() === action.key
      ) as MetadataExtractorTool | ObligationsExtractorTool;
      toolToEdit.removeCustomOption(action.customOptionKey!);
      return [...oldState];
    }
    case "UPDATE_METADATA_LANGUAGE": {
      const toolToEdit = oldState.find(
        (tool) => tool.getKey() === "metadata_extractor"
      ) as MetadataExtractorTool;
      toolToEdit?.updateLanguage(action.language!);
      return [...oldState];
    }
    case "UPDATE_OBLIGATIONS_LANGUAGE": {
      const toolToEdit = oldState.find(
        (tool) => tool.getKey() === "obligations_extractor"
      ) as ObligationsExtractorTool;
      toolToEdit?.updateLanguage(action.language!);
      return [...oldState];
    }
    case "UPDATE_RISK_LANGUAGE": {
      const toolToEdit = oldState.find(
        (tool) => tool.getKey() === "risks_identifier"
      ) as RisksIdentifierTool;
      toolToEdit?.updateLanguage(action.language!);
      return [...oldState];
    }
    case "UPDATE_CONTRACT_TYPE": {
      const toolToEdit = oldState.find(
        (tool) => tool.getKey() === action.key
      ) as
        | MetadataExtractorTool
        | ObligationsExtractorTool
        | RisksIdentifierTool;
      toolToEdit?.updateContractType(action.contractType!);
      return [...oldState];
    }
    case "REMOVE_TOOL": {
      const indexToolToDelete = oldState.findIndex(
        (tool) => tool.getKey() === action.key
      );
      const newState = oldState.filter(
        (_, index) => index !== indexToolToDelete
      );
      return [...newState];
    }
    case "ADD_TOOL": {
      if (
        action.key === "metadata_extractor" &&
        !oldState.some((tool) => tool.getKey() === "metadata_extractor")
      )
        return [...oldState, new MetadataExtractorTool()];
      else if (
        action.key === "obligations_extractor" &&
        !oldState.some((tool) => tool.getKey() === "obligations_extractor")
      )
        return [...oldState, new ObligationsExtractorTool()];
      else if (
        action.key === "risks_identifier" &&
        !oldState.some((tool) => tool.getKey() === "risks_identifier")
      )
        return [...oldState, new RisksIdentifierTool()];
      else if (
        action.key === "document_comparison" &&
        !oldState.some((tool) => tool.getKey() === "document_comparison")
      )
        return [...oldState, new DocumentComparisonTool()];
      else if (
        action.key === "document_drafting" &&
        !oldState.some((tool) => tool.getKey() === "document_drafting")
      )
        return [...oldState, new DocumentDraftingTool()];
      else if (
        action.key === "document_summarization" &&
        !oldState.some((tool) => tool.getKey() === "document_summarization")
      )
        return [...oldState, new DocumentSummarizationTool()];
      else if (
        action.key === "portfolio_scanning" &&
        !oldState.some((tool) => tool.getKey() === "portfolio_scanning")
      )
        return [...oldState, new PortfolioScanningTool()];
      else if (
        action.key === "translation_tool" &&
        !oldState.some((tool) => tool.getKey() === "translation_tool")
      )
        return [...oldState, new TranslationTool()];
      else break;
    }
    case "UPDATE_COMPARISON_TYPE": {
      const comparisonTool = oldState.find(
        (tool) => tool.getKey() === "document_comparison"
      ) as DocumentComparisonTool;
      comparisonTool?.updateComparisonType(action.comparisonType!);
      return [...oldState];
    }
    case "UPDATE_REGULATION_COMPARISON_TYPE": {
      const comparisonTool = oldState.find(
        (tool) => tool.getKey() === "document_comparison"
      ) as DocumentComparisonTool;
      comparisonTool?.updateRegulationComparisonType(
        action.regulationComparisonType!
      );
      return [...oldState];
    }
    case "UPDATE_CHECKLIST_COMPARISON_TYPE": {
      const comparisonTool = oldState.find(
        (tool) => tool.getKey() === "document_comparison"
      ) as DocumentComparisonTool;
      comparisonTool?.updateChecklistComparisonType(
        action.checklistComparisonType!
      );
      return [...oldState];
    }
    case "UPDATE_COMPARISON_REGULATION": {
      const comparisonTool = oldState.find(
        (tool) => tool.getKey() === "document_comparison"
      ) as DocumentComparisonTool;
      comparisonTool?.updateRegulation(action.comparisonRegulation!);
      return [...oldState];
    }
    case "UPDATE_COMPARISON_CHECKLIST": {
      const comparisonTool = oldState.find(
        (tool) => tool.getKey() === "document_comparison"
      ) as DocumentComparisonTool;
      comparisonTool?.updateChecklist(action.comparisonChecklist!);
      return [...oldState];
    }
    case "UPDATE_FILE": {
      const toolToEdit = oldState.find(
        (tool) => tool.getKey() === "document_comparison"
      ) as DocumentComparisonTool;
      toolToEdit?.updateFile(action.file!);
      return [...oldState];
    }
    case "UPDATE_CLAUSES_TO_CHECK": {
      const toolToEdit = oldState.find(
        (tool) => tool.getKey() === "document_comparison"
      ) as DocumentComparisonTool;
      toolToEdit?.updateClausesToCheck(action.clausesToCheck!);
      return [...oldState];
    }
    case "UPDATE_COMPARISON_LANGUAGE": {
      const toolToEdit = oldState.find(
        (tool) => tool.getKey() === "document_comparison"
      ) as DocumentComparisonTool;
      toolToEdit?.updateLanguage(action.language!);
      return [...oldState];
    }
    case "UPDATE_DRAFTING_DOCUMENT_TYPE": {
      const toolToEdit = oldState.find(
        (tool) => tool.getKey() === "document_drafting"
      ) as DocumentDraftingTool;
      toolToEdit?.updateContractType(action.draftingContractType!);
      return [...oldState];
    }
    case "UPDATE_DRAFTING_CUSTOM_PROMPT": {
      const toolToEdit = oldState.find(
        (tool) => tool.getKey() === "document_drafting"
      ) as DocumentDraftingTool;
      toolToEdit?.updateCustomPrompt(action.draftingCustomPrompt!);
      return [...oldState];
    }
    case "UPDATE_DRAFTING_TONE": {
      const toolToEdit = oldState.find(
        (tool) => tool.getKey() === "document_drafting"
      ) as DocumentDraftingTool;
      toolToEdit?.updateTone(action.draftingTone!);
      return [...oldState];
    }
    case "UPDATE_DRAFTING_LANGUAGE": {
      const toolToEdit = oldState.find(
        (tool) => tool.getKey() === "document_drafting"
      ) as DocumentDraftingTool;
      toolToEdit?.updateLanguage(action.draftingLanguage!);
      return [...oldState];
    }
    case "UPDATE_DRAFTING_ANONYMIZE_DATA": {
      const toolToEdit = oldState.find(
        (tool) => tool.getKey() === "document_drafting"
      ) as DocumentDraftingTool;
      toolToEdit?.updateAnonymizeData(action.draftingAnonymizeData!);
      return [...oldState];
    }
    case "UPDATE_DRAFTING_PROVIDE_SOURCES": {
      const toolToEdit = oldState.find(
        (tool) => tool.getKey() === "document_drafting"
      ) as DocumentDraftingTool;
      toolToEdit?.updateProvideSources(action.draftingProvideSources!);
      return [...oldState];
    }
    case "UPDATE_SUMMARIZATION_DOCUMENT_TYPE": {
      const toolToEdit = oldState.find(
        (tool) => tool.getKey() === "document_summarization"
      ) as DocumentSummarizationTool;
      toolToEdit?.updateContractType(action.summarizationContractType!);
      return [...oldState];
    }
    case "UPDATE_SUMMARIZATION_SCENARIO": {
      const toolToEdit = oldState.find(
        (tool) => tool.getKey() === "document_summarization"
      ) as DocumentSummarizationTool;
      toolToEdit?.updateScenario(action.summarizationScenario!);
      return [...oldState];
    }
    case "UPDATE_SUMMARIZATION_CUSTOM_PROMPT": {
      const toolToEdit = oldState.find(
        (tool) => tool.getKey() === "document_summarization"
      ) as DocumentSummarizationTool;
      toolToEdit?.updateCustomPrompt(action.summarizationCustomPrompt!);
      return [...oldState];
    }
    case "UPDATE_SUMMARIZATION_LENGTH": {
      const toolToEdit = oldState.find(
        (tool) => tool.getKey() === "document_summarization"
      ) as DocumentSummarizationTool;
      toolToEdit?.updateLength(action.summarizationLength!);
      return [...oldState];
    }
    case "UPDATE_SUMMARIZATION_TONE": {
      const toolToEdit = oldState.find(
        (tool) => tool.getKey() === "document_summarization"
      ) as DocumentSummarizationTool;
      toolToEdit?.updateTone(action.summarizationTone!);
      return [...oldState];
    }
    case "UPDATE_SUMMARIZATION_LANGUAGE": {
      const toolToEdit = oldState.find(
        (tool) => tool.getKey() === "document_summarization"
      ) as DocumentSummarizationTool;
      toolToEdit?.updateLanguage(action.summarizationLanguage!);
      return [...oldState];
    }
    case "UPDATE_SUMMARIZATION_ANONYMIZE_DATA": {
      const toolToEdit = oldState.find(
        (tool) => tool.getKey() === "document_summarization"
      ) as DocumentSummarizationTool;
      toolToEdit?.updateAnonymizeData(action.summarizationAnonymizeData!);
      return [...oldState];
    }
    case "UPDATE_SUMMARIZATION_PROVIDE_SOURCES": {
      const toolToEdit = oldState.find(
        (tool) => tool.getKey() === "document_summarization"
      ) as DocumentSummarizationTool;
      toolToEdit?.updateProvideSources(action.summarizationProvideSources!);
      return [...oldState];
    }
    case "UPDATE_PORTFOLIO_SCANNING_TIMELINE": {
      const toolToEdit = oldState.find(
        (tool) => tool.getKey() === "portfolio_scanning"
      ) as PortfolioScanningTool;
      toolToEdit?.updateIsTimeline(action.portfolioScanningIsTimeline!);
      return [...oldState];
    }
    case "UPDATE_PORTFOLIO_SCANNING_COMPARISON_FEATURES": {
      const toolToEdit = oldState.find(
        (tool) => tool.getKey() === "portfolio_scanning"
      ) as PortfolioScanningTool;
      toolToEdit?.updateComparisonFeatures(
        action.portfolioScanningComparisonFeatures!
      );
      return [...oldState];
    }
    case "UPDATE_PORTFOLIO_SCANNING_LANGUAGE": {
      const toolToEdit = oldState.find(
        (tool) => tool.getKey() === "portfolio_scanning"
      ) as PortfolioScanningTool;
      toolToEdit?.updateLanguage(action.language!);
      return [...oldState];
    }
    case "CLEAR_STATE": {
      return [];
    }
  }
};

export { toolsReducer };
export type { MyTools };
