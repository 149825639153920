import { ResultTypes } from "../enums/resultTypes.enum";
import { ExportFileTypes } from "../enums/exportFileTypes.enum";
import * as ResultsService from "../services/results.service";

type exportFileMap = {
  [key in ResultTypes]: {
    [key in ExportFileTypes]: (id: string | number) => void;
  };
};

const exportFileFunctions: Omit<
  exportFileMap,
  | ResultTypes.Drafting
  | ResultTypes.Translation
  | ResultTypes.Invoice
  | ResultTypes.Timeline
> = {
  [ResultTypes.Metadata]: {
    [ExportFileTypes.XLSX]: (id) =>
      ResultsService.exportFile(id, "export_metadata_XLSX"),
    [ExportFileTypes.DOCX]: (id) =>
      ResultsService.exportFile(id, "export_metadata_DOCX"),
    [ExportFileTypes.PDF]: (id) =>
      ResultsService.exportFile(id, "export_metadata_PDF"),
    [ExportFileTypes.JSON]: (id) =>
      ResultsService.exportFile(id, "export_metadata_JSON"),
  },

  [ResultTypes.Obligations]: {
    [ExportFileTypes.XLSX]: (id) =>
      ResultsService.exportFile(id, "export_obligation_XLSX"),
    [ExportFileTypes.DOCX]: (id) =>
      ResultsService.exportFile(id, "export_obligation_DOCX"),
    [ExportFileTypes.PDF]: (id) =>
      ResultsService.exportFile(id, "export_obligation_PDF"),
    [ExportFileTypes.JSON]: (id) =>
      ResultsService.exportFile(id, "export_obligation_JSON"),
  },
  [ResultTypes.Risks]: {
    [ExportFileTypes.XLSX]: (id) =>
      ResultsService.exportFile(id, "export_risk_XLSX"),
    [ExportFileTypes.DOCX]: (id) =>
      ResultsService.exportFile(id, "export_risk_DOCX"),
    [ExportFileTypes.PDF]: (id) =>
      ResultsService.exportFile(id, "export_risk_PDF"),
    [ExportFileTypes.JSON]: (id) =>
      ResultsService.exportFile(id, "export_risk_JSON"),
  },
  [ResultTypes.Comparison]: {
    [ExportFileTypes.XLSX]: (id) =>
      ResultsService.exportFile(id, "export_comparison_XLSX"),
    [ExportFileTypes.DOCX]: (id) =>
      ResultsService.exportFile(id, "export_comparison_DOCX"),
    [ExportFileTypes.PDF]: (id) =>
      ResultsService.exportFile(id, "export_comparison_PDF"),
    [ExportFileTypes.JSON]: (id) =>
      ResultsService.exportFile(id, "export_comparison_JSON"),
  },
};

export default exportFileFunctions;
