import { LoadingOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import React, { useContext, useState } from "react";
import Button from "react-bootstrap/Button";
import { FaEdit, FaPlus } from "react-icons/fa";
import { FaUserGear } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../../../contexts/AuthContext";
import TeamsContext from "../../../../contexts/TeamsContext";
import Team from "../../../../data/Team";
import styles from "./SideMenu.module.css";
import CreateEditTeamModal from "./components/CreateEditTeamModal/CreateEditTeamModal";

interface SideMenuProps {
  pageIsLoading: boolean;
}

const SideMenu: React.FC<SideMenuProps> = ({ pageIsLoading }) => {
  const authContext = useContext(AuthContext);
  const teamsContext = useContext(TeamsContext);
  const navigate = useNavigate();
  const [showCreateEditTeamModal, setShowCreateEditTeamModal] = useState(false);
  const [modalTeam, setModalTeam] = useState<Team | undefined>(undefined);

  return (
    <>
      <div className={styles.teams_menu}>
        {pageIsLoading ? (
          <div
            className={styles.menu_content}
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LoadingOutlined
              style={{ fontSize: 20, color: "var(--deloitte-green)" }}
            />
          </div>
        ) : (
          <>
            <div className={styles.page_title}>
              <div
                style={{
                  overflowX: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                {authContext?.user?.firstname}'s teams
              </div>
              <Tooltip placement="bottom" title="Create a new team">
                <Button
                  onClick={(e) => {
                    setModalTeam(undefined);
                    setShowCreateEditTeamModal(true);
                  }}
                  variant="dark"
                  className={styles.add_team_button}
                >
                  <FaPlus />
                </Button>
              </Tooltip>
            </div>
            <div className={styles.teams_list}>
              {teamsContext?.teams.length === 0 ? (
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    fontSize: 12,
                    justifyContent: "center",
                    marginTop: 14,
                  }}
                >
                  You do not have any teams yet
                </div>
              ) : (
                teamsContext?.teams.map((team: Team, index: number) => (
                  <div
                    key={team.id}
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <div
                      className={`${styles.teams_list_item} ${
                        teamsContext?.teamSelected?.id === team.id
                          ? styles.teams_list_item_selected
                          : ""
                      }`}
                    >
                      <div
                        onClick={() => {teamsContext?.setTeamSelected(team); }}
                        className={styles.teams_list_item_name}
                        title={team.name}
                      >
                        {team.name}
                      </div>
                      {team.user_role === "Admin" ? (
                        <Tooltip placement="bottom" title="Rename team">
                          <FaEdit
                            onClick={(e) => {
                              setModalTeam(team);
                              setShowCreateEditTeamModal(true);
                            }}
                            className={styles.edit_icon}
                          />
                        </Tooltip>
                      ) : undefined}
                      {team.user_role === "Admin" ? (
                        <Tooltip
                          placement="bottom"
                          title="You are an admin of this team"
                        >
                          <FaUserGear className={styles.admin_icon} />
                        </Tooltip>
                      ) : undefined}
                    </div>
                    <div
                      style={{
                        width: "100%",
                        height: "1px",
                        backgroundColor: "#262730",
                      }}
                    />
                  </div>
                ))
              )}
            </div>
          </>
        )}
      </div>

      <CreateEditTeamModal
        isOpen={showCreateEditTeamModal}
        closeModal={() => {
          setShowCreateEditTeamModal(false);
        }}
        teamToEdit={modalTeam}
      ></CreateEditTeamModal>
    </>
  );
};

export default SideMenu;
