export const dateFormatingOptions = [
  { value: "dd/mm/yyyy", label: "DD/MM/YYYY" },
  { value: "dd.mm.yyyy", label: "DD.MM.YYYY" },
  { value: "dd-mm-yyyy", label: "DD-MM-YYYY" },
  { value: "yyyy-mm-dd", label: "YYYY-MM-DD" },
  { value: "mm/dd/yyyy", label: "MM/DD/YYYY" },
];

export const valueFormatingOptions = [
  { value: "whole number", label: "Whole number" },
  {
    value: "decimal with 1 digit",
    label: "Decimal [1 digit]",
  },
  {
    value: "decimal with 2 digits",
    label: "Decimal [2 digits]",
  },
];
