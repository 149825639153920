import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import type { TableColumnsType } from "antd";
import { Tooltip, Table, Button } from "antd";
import {
  DownCircleOutlined,
  UpCircleOutlined,
  CheckOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import Markdown from "markdown-to-jsx";
import parseDateString from "../../../../helpers/parseDateString";
import formatDate from "../../../../helpers/formatDate";
import { ToolResult } from "../../interfaces/toolsResults.interface";
import { TableData } from "../../interfaces/tableData.interface";
import ResultActions from "../ResultActions/ResultActions";
import { ResultTypes } from "../../enums/resultTypes.enum";
import { ToolResultProps } from "../../interfaces/toolResultProps";
import handleCopyToClipboard from "../../helpers/handleCopyToClipboard";

import styles from "./DraftingResults.module.css";

const DraftingResults: React.FC<
  ToolResultProps & {
    expandedRowKeys: number[];
    setExpandedRowKeys: React.Dispatch<React.SetStateAction<number[]>>;
  }
> = ({
  isLoading,
  allResults,
  getColumnSearchProps,
  selectRows,
  expandedRowKeys,
  setExpandedRowKeys,
  currentPage,
  setCurrentPage,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isCopiedDraftingText, setIsCopiedDraftingText] = useState(false);
  const resultTextRef = useRef<HTMLDivElement>(null);

  const handleExpand = (expanded: boolean, record: TableData) => {
    let newExpandedRowKeys = [...expandedRowKeys];
    if (expanded) {
      newExpandedRowKeys.push(record.key as number);
    } else {
      newExpandedRowKeys = newExpandedRowKeys.filter(
        (key) => key !== record.key
      );
    }
    setExpandedRowKeys(newExpandedRowKeys);

    // Update URL with the expanded rows
    const params = new URLSearchParams(window.location.search);
    if (newExpandedRowKeys.length > 0) {
      params.set("expandedRowKeys", newExpandedRowKeys.join(","));
    } else {
      params.delete("expandedRowKeys");
    }
    navigate({ search: params.toString() }, { replace: true });
  };

  const columns: TableColumnsType<TableData> = [
    {
      title: "Title",
      dataIndex: "file",
      key: "file",
      ...getColumnSearchProps("file"),
      sorter: (a, b) => a.file.localeCompare(b.file),
      sortDirections: ["ascend", "descend"],
      width: "66%",
    },
    {
      title: "User",
      dataIndex: "user",
      key: "user",
      ...getColumnSearchProps("user"),
      sortDirections: ["ascend", "descend"],
      width: "140px",
      render: (value) => (
        <div title={value} className={styles.user}>
          {value}
        </div>
      ),
    },
    {
      title: "Created at",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: (a, b) =>
        parseDateString(a.createdAt).getTime() -
        parseDateString(b.createdAt).getTime(),
      sortDirections: ["ascend", "descend"],
      width: "140px",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "70px",
      align: "center",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      width: "80px",
      render: (_, record, ___) => (
        <ResultActions record={record} resultType={ResultTypes.Drafting} />
      ),
      align: "center",
    },
  ];

  const checkStatus = (result: ToolResult) => {
    if (result.results.length === 0)
      return (
        <Tooltip title="Process failed.">
          <FaTimesCircle color="var(--beautiful-red)" />
        </Tooltip>
      );
    return (
      <Tooltip title="Process was successful.">
        <FaCheckCircle color="var(--deloitte-green)" />
      </Tooltip>
    );
  };

  const expandedRowRender = (record: TableData) => {
    const expandedRowResult = allResults.find(
      (result) => result.id === record.key
    )?.results[0];

    return expandedRowResult ? (
      <div>
        <div className={styles.document_drafting_expanded_result_header}>
          <p className={styles.document_drafting_expanded_result_title}>
            Drafting Result
          </p>
          {isCopiedDraftingText ? (
            <p>
              <CheckOutlined /> Copied!
            </p>
          ) : (
            <Button
              type="dashed"
              icon={<CopyOutlined />}
              onClick={async () => {
                await handleCopyToClipboard(resultTextRef);
                setIsCopiedDraftingText(true);
                setTimeout(() => {
                  setIsCopiedDraftingText(false);
                }, 3000);
              }}
            >
              Copy text
            </Button>
          )}
        </div>

        <div ref={resultTextRef}>
          <Markdown
            options={{
              overrides: {
                p: {
                  component: "p",
                  props: {
                    style: {
                      marginBottom: "1em",
                    },
                  },
                },
              },
            }}
            className={styles.document_drafting_expanded_result_content}
          >
            {expandedRowResult.draft}
          </Markdown>
        </div>
      </div>
    ) : (
      <div className={styles.error_result}>
        <Tooltip title="This process failed.">
          <FaTimesCircle color="var(--beautiful-red)" />
        </Tooltip>
      </div>
    );
  };

  return (
    <div className={styles.table}>
      <Table
        bordered
        loading={isLoading}
        sticky={{ offsetHeader: 0 }}
        rowSelection={{
          type: "checkbox",
          onChange: (val) => {
            selectRows(val);
          },
        }}
        size="middle"
        pagination={{
          defaultPageSize: 15,
          current: currentPage, // Add current page control
          onChange: (page) => setCurrentPage(page), // Update current page
        }}
        scroll={{ scrollToFirstRowOnChange: true }}
        columns={columns}
        dataSource={allResults.map((result) => ({
          key: result.id,
          file: result.file,
          user: result.user,
          status: checkStatus(result),
          createdAt: formatDate(result.created_at),
        }))}
        expandable={{
          expandedRowRender: expandedRowRender,
          onExpand: handleExpand,
          expandedRowKeys: expandedRowKeys,
          expandIcon: ({ expanded, onExpand, record }) =>
            expanded ? (
              <UpCircleOutlined
                style={{ fontSize: 16 }}
                onClick={(e) => onExpand(record, e)}
              />
            ) : (
              <DownCircleOutlined
                style={{ fontSize: 16 }}
                onClick={(e) => onExpand(record, e)}
              />
            ),
        }}
      />
    </div>
  );
};

export default DraftingResults;
