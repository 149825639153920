import getCookie from "../../../helpers/getCookie";

const backendUrl = process.env.REACT_APP_API_URL ?? "";

export const fetchTeamMembers = async (teamId: string | number) => {
  const response = await fetch(`${backendUrl}/api/team_members/${teamId}`, {
    method: "GET",
    credentials: "include",
  });
  let body = await response.json();

  return body.map(
    (user: {
      id: string | number;
      user: {
        email: string;
        first_name: string;
        last_name: string;
        username: string;
      };
      profile_picture: string;
      role: string;
    }) => ({
      id: user.id,
      email: user.user.email ? user.user.email : "Unknown",
      firstname: user.user.first_name ? user.user.first_name : "Unknown",
      lastname: user.user.last_name ? user.user.last_name : "Unknown",
      fullname: `${user.user.last_name ? user.user.last_name : "Unknown"}, ${
        user.user.first_name ? user.user.first_name : "Unknown"
      }`,
      username: user.user.username ? user.user.username : "Unknown",
      profilePicture: user.profile_picture,
      role: user.role,
    })
  );
};

export const removeTeamMember = async (
  teamId: string | number,
  memberId: string | number
) => {
  const response = await fetch(
    `${backendUrl}/api/teams/${teamId}/members/${memberId}`,
    {
      method: "DELETE",
      headers: { "X-CSRFToken": getCookie("csrftoken")! },
      credentials: "include",
    }
  );
};

export const leaveTeam = async (teamId: string | number) => {
  await fetch(`${backendUrl}/api/leave_team/${teamId}`, {
    method: "DELETE",
    headers: { "X-CSRFToken": getCookie("csrftoken")! },
    credentials: "include",
  });
};

export const giveAdminRole = async (
  teamId: string | number,
  memberId: string | number
) => {
  await fetch(`${backendUrl}/api/teams/${teamId}/make_admin/${memberId}`, {
    method: "PATCH",
    headers: { "X-CSRFToken": getCookie("csrftoken")! },
    credentials: "include",
  });
};

export const inviteTeamMember = async (
  teamId: string | number,
  memberId: string | number
) => {
  await fetch(`${backendUrl}/api/teams/${teamId}/invite_member/${memberId}`, {
    method: "POST",
    headers: { "X-CSRFToken": getCookie("csrftoken")! },
    credentials: "include",
  });
};

export const acceptTeamInvitation = async (notificationId: string | number) => {
  await fetch(`${backendUrl}/api/accept_invitation/${notificationId}`, {
    method: "POST",
    headers: { "X-CSRFToken": getCookie("csrftoken")! },
    credentials: "include",
  });
};

export const declineTeamInvitation = async (
  notificationId: string | number
) => {
  await fetch(`${backendUrl}/api/cancel_invitation/${notificationId}`, {
    method: "PATCH",
    headers: { "X-CSRFToken": getCookie("csrftoken")! },
    credentials: "include",
  });
};

export const createTeam = async (name: string) => {
  const formData = new FormData();
  formData.append("name", name);

  await fetch(`${backendUrl}/api/create_team/`, {
    method: "POST",
    body: formData,
    headers: { "X-CSRFToken": getCookie("csrftoken")! },
    credentials: "include",
  });
};

export const editTeam = async (id: string | number, data: { name: string }) => {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("X-CSRFToken", getCookie("csrftoken")!);
  const body = JSON.stringify({ name: data.name });
  await fetch(`${backendUrl}/api/edit_team/${id}`, {
    method: "PATCH",
    body,
    headers,
    credentials: "include",
  });
};

export const deleteTeam = async (id: string | number) => {
  await fetch(`${backendUrl}/api/delete_team/${id}`, {
    method: "DELETE",
    headers: { "X-CSRFToken": getCookie("csrftoken")! },
    credentials: "include",
  });
};
