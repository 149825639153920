import React, { useState, useEffect } from "react";
import { Button, Modal, Input } from "antd";

import styles from "./CreateTemplateModal.module.css";

interface CreateTemplateModalProps {
  isOpen: boolean;
  title: string;
  subTitle: string;
  onClose: () => void;
  action: (templateName: string) => void;
  predefinedValue: string;
}

const CreateTemplateModal: React.FC<CreateTemplateModalProps> = ({
  isOpen,
  title,
  subTitle,
  onClose,
  action,
  predefinedValue = "",
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [templateName, setTemplateName] = useState(predefinedValue);

  useEffect(() => {
    setTemplateName(predefinedValue);
  }, [predefinedValue]);

  const onSubmit = async () => {
    setIsLoading(true);
    await action(templateName);
    setIsLoading(false);
    onClose();
  };

  return (
    <Modal
      width="60%"
      centered
      open={isOpen}
      title={[
        <p key="title" className={styles.title}>
          {title}
        </p>,
        <p key="subTitle" className={styles.subTitle}>
          {subTitle}
        </p>,
      ]}
      onOk={onSubmit}
      onCancel={onClose}
      footer={[
        <Button key="back" className={styles.close_button} onClick={onClose}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          className={styles.submit_button}
          loading={isLoading}
          onClick={onSubmit}>
          Submit
        </Button>,
      ]}>
      <div className={styles.content}>
        <Input
          autoFocus
          placeholder="Type the template name"
          value={templateName}
          onChange={(event) => setTemplateName(event.target.value)}
        />
      </div>
    </Modal>
  );
};

export default CreateTemplateModal;
