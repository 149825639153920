import { Checkbox, Form, Input, Select, Slider, Tag, Tooltip } from "antd";
import React, { Reducer, useContext, useReducer } from "react";
import MainContext from "../../../../contexts/MainContext";
import filterOption from "../../../../helpers/selectFilterOption";

import { CgFileDocument } from "react-icons/cg";
import { LuConstruction } from "react-icons/lu";
import {
  MyTools,
  toolsReducer,
} from "../../../../reducers/ToolsSelectedReducer";
import styles from "./DocumentSummarizationTool.module.css";

import { CSSTransition } from "react-transition-group";

import { Button as ButtonAntd } from "antd";

const DocumentSummarizationTool: React.FC = () => {
  const mainContext = useContext(MainContext);

  const [toolsSelected, toolsSelectedDispatch] = useReducer<Reducer<any, any>>(
    toolsReducer,
    []
  );

  const checkIfToolIsSelected = (
    toolInput:
      | "metadata_extractor"
      | "obligations_extractor"
      | "risks_identifier"
      | "document_comparison"
      | "document_drafting"
      | "document_summarization"
      | "translation_tool"
  ) => toolsSelected.some((tool: MyTools) => tool.getKey() === toolInput);

  const filterSelectedTools = (
    toolInput:
      | "metadata_extractor"
      | "obligations_extractor"
      | "risks_identifier"
      | "document_comparison"
      | "document_drafting"
      | "document_summarization"
      | "translation_tool"
  ) => toolsSelected.filter((tool: MyTools) => tool.getKey() === toolInput)[0];

  return (
    <>
      <CSSTransition
        key="document_summarization"
        in={true}
        timeout={260}
        classNames={{
          enter: styles.growEnter,
          enterActive: styles.growEnterActive,
          exit: styles.shrinkExit,
          exitActive: styles.shrinkExitActive,
        }}
        mountOnEnter
        unmountOnExit
      >
        <div className={styles.tool} key="document_summarization">
          <div className={styles.tool_title}>
            <div className={styles.ribbon}>
              <CgFileDocument />
            </div>
            <div style={{ fontSize: 24 }}>Document summarization</div>
            <Tag className={styles.tool_construction} color="warning">
              <LuConstruction /> In Development
            </Tag>
          </div>
          <div className={styles.tool_content}>
            <Form
              style={{
                padding: "10px 2px",
                display: "flex",
                flexDirection: "column",
              }}
              layout="vertical"
            >
              <Form.Item label="Document type *">
                <Select
                  showSearch
                  allowClear
                  filterOption={filterOption}
                  placeholder="Select document type"
                  /*
                onChange={
                  checkIfToolIsSelected("document_summarization")
                    ? (value: string, option: any) =>
                        toolsSelectedDispatch({
                          type: "UPDATE_SUMMARIZATION_DOCUMENT_TYPE",
                          summarizationContractType: option
                            ? { id: option.value, value: option.label }
                            : null,
                        })
                    : undefined
                }
                value={
                  checkIfToolIsSelected("document_summarization")
                    ? filterSelectedTools(
                        "document_summarization"
                      ).getContractType()
                      ? filterSelectedTools(
                          "document_summarization"
                        ).getContractType().value
                      : null
                    : undefined
                }  */
                  /* options={
             
                  checkIfToolIsSelected("document_summarization")
                    ? mainContext?.summarizationDocumentTypes.map(
                        (contractType: any, index: number) => ({
                          value: index,
                          label: contractType,
                        })
                      )
                    : undefined
                   
                } */
                  options={[{ label: "Example document type", value: "1" }]}
                />
              </Form.Item>
              <Form.Item label="Scenario *">
                <Select
                  disabled={
                    checkIfToolIsSelected("document_summarization")
                      ? filterSelectedTools(
                          "document_summarization"
                        ).getContractType() === null
                      : undefined
                  }
                  size="middle"
                  style={{ width: "100%" }}
                  allowClear
                  showSearch
                  filterOption={filterOption}
                  placeholder="Select the scenario"
                  onChange={
                    checkIfToolIsSelected("document_summarization")
                      ? (value: string[], option: any) =>
                          toolsSelectedDispatch({
                            type: "UPDATE_SUMMARIZATION_SCENARIO",
                            summarizationScenario: option
                              ? { id: option.value, value: option.label }
                              : null,
                          })
                      : undefined
                  }
                  value={
                    checkIfToolIsSelected("document_summarization")
                      ? filterSelectedTools(
                          "document_summarization"
                        ).getScenario()
                        ? filterSelectedTools(
                            "document_summarization"
                          ).getScenario().value
                        : null
                      : undefined
                  }
                  options={
                    checkIfToolIsSelected("document_summarization")
                      ? mainContext?.summarizationDocumentTypes.map(
                          (summarizationDocumentType) => ({
                            value: parseInt(
                              summarizationDocumentType.id.toString()
                            ),
                            label: summarizationDocumentType.name,
                          })
                        )
                      : undefined
                  }
                />
              </Form.Item>
              <Form.Item label="Summarization length *">
                <Slider
                  range={false}
                  marks={{
                    100: "100",
                    200: "200",
                    300: "300",
                    400: "400",
                    500: "500",
                    600: "600",
                    700: "700",
                    800: "800",
                  }}
                  tooltip={{ open: false }}
                  step={100}
                  min={100}
                  max={800}
                  value={
                    checkIfToolIsSelected("document_summarization")
                      ? filterSelectedTools(
                          "document_summarization"
                        ).getLength()
                      : undefined
                  }
                  onChange={
                    checkIfToolIsSelected("document_summarization")
                      ? (value) =>
                          toolsSelectedDispatch({
                            type: "UPDATE_SUMMARIZATION_LENGTH",
                            summarizationLength: value,
                          })
                      : undefined
                  }
                />
              </Form.Item>
              <div
                style={{
                  backgroundColor: "#151515",
                  height: 1,
                  borderRadius: 30,
                }}
              />
              <div style={{ height: 20 }} />
              <Form.Item label="Key Details, custom prompt">
                <Input.TextArea
                  autoSize={{ minRows: 3, maxRows: 5 }}
                  maxLength={100}
                  allowClear
                  value={
                    checkIfToolIsSelected("document_summarization")
                      ? filterSelectedTools(
                          "document_summarization"
                        ).getCustomPrompt()
                      : undefined
                  }
                  onChange={
                    checkIfToolIsSelected("document_summarization")
                      ? (e: React.ChangeEvent<HTMLTextAreaElement>) =>
                          toolsSelectedDispatch({
                            type: "UPDATE_SUMMARIZATION_CUSTOM_PROMPT",
                            summarizationCustomPrompt: e.target.value,
                          })
                      : undefined
                  }
                  placeholder="Type some key details / custom prompt"
                />
              </Form.Item>
              <Form.Item label="Tone">
                <Select
                  showSearch
                  allowClear
                  filterOption={filterOption}
                  placeholder="Define the tone"
                  onChange={
                    checkIfToolIsSelected("document_summarization")
                      ? (value: string, option: any) =>
                          toolsSelectedDispatch({
                            type: "UPDATE_SUMMARIZATION_TONE",
                            summarizationTone: option ? option.label : null,
                          })
                      : undefined
                  }
                  value={
                    checkIfToolIsSelected("document_summarization")
                      ? filterSelectedTools("document_summarization").getTone()
                        ? filterSelectedTools(
                            "document_summarization"
                          ).getTone()
                        : null
                      : undefined
                  }
                  options={
                    checkIfToolIsSelected("document_summarization")
                      ? mainContext?.summarizationTones.map(
                          (summarizationTone) => ({
                            value: parseInt(summarizationTone.id.toString()),
                            label: summarizationTone.name,
                          })
                        )
                      : undefined
                  }
                />
              </Form.Item>
              <Form.Item label="Language of the output">
                <Select
                  showSearch
                  filterOption={filterOption}
                  placeholder="Select the language of the output"
                  onChange={
                    checkIfToolIsSelected("document_summarization")
                      ? (value: string, option: any) =>
                          toolsSelectedDispatch({
                            type: "UPDATE_SUMMARIZATION_LANGUAGE",
                            summarizationLanguage: option ? option.label : "",
                          })
                      : undefined
                  }
                  value={
                    checkIfToolIsSelected("document_summarization")
                      ? filterSelectedTools(
                          "document_summarization"
                        ).getLanguage()
                      : undefined
                  }
                  options={
                    checkIfToolIsSelected("document_summarization")
                      ? [
                          { label: "English", value: 0 },
                          { label: "German", value: 1 },
                          { label: "French", value: 2 },
                          { label: "Spanish", value: 3 },
                          { label: "Polish", value: 4 },
                          { label: "Czech", value: 5 },
                          { label: "Bulgarian", value: 6 },
                          { label: "Japanese", value: 7 },
                          { label: "Hungarian", value: 8 },
                          { label: "Russian", value: 9 },
                          { label: "Ukranian", value: 10 },
                          { label: "Greek", value: 11 },
                          { label: "Turkish", value: 12 },
                          { label: "Italian", value: 13 },
                          { label: "Dutch", value: 14 },
                          { label: "Portuguese", value: 15 },
                          { label: "Swedish", value: 16 },
                          { label: "Finnish", value: 17 },
                          { label: "Danish", value: 18 },
                          { label: "Norwegian", value: 19 },
                          { label: "Slovak", value: 20 },
                          { label: "Romanian", value: 21 },
                          { label: "Croatian", value: 22 },
                          { label: "Serbian", value: 23 },
                          { label: "Slovenian", value: 24 },
                          { label: "Lithuanian", value: 25 },
                          { label: "Latvian", value: 26 },
                          { label: "Estonian", value: 27 },
                          { label: "Arabic", value: 28 },
                          { label: "Hebrew", value: 29 },
                          { label: "Chinese", value: 30 },
                          { label: "Korean", value: 31 },
                          { label: "Thai", value: 32 },
                          { label: "Vietnamese", value: 33 },
                          { label: "Indonesian", value: 34 },
                        ]
                      : undefined
                  }
                />
              </Form.Item>
              <div
                style={{
                  backgroundColor: "#151515",
                  height: 1,
                  borderRadius: 30,
                }}
              />
              <div style={{ height: 20 }} />
              {/* <Checkbox
              style={{ marginLeft: 4 }}
              onChange={checkIfToolIsSelected('document_summarization') ? (e) => toolsSelectedDispatch({ type: 'UPDATE_SUMMARIZATION_ANONYMIZE_DATA', summarizationAnonymizeData: e.target.checked }) : undefined}
              checked={checkIfToolIsSelected('document_summarization') ? (filterSelectedTools('document_summarization').getAnonymizeData()) : undefined}
              children="Anonymize data"
            /> */}
              <div style={{ height: 10 }} />
              <Checkbox
                style={{ marginLeft: 4 }}
                onChange={
                  checkIfToolIsSelected("document_summarization")
                    ? (e) =>
                        toolsSelectedDispatch({
                          type: "UPDATE_SUMMARIZATION_PROVIDE_SOURCES",
                          summarizationProvideSources: e.target.checked,
                        })
                    : undefined
                }
                checked={
                  checkIfToolIsSelected("document_summarization")
                    ? filterSelectedTools(
                        "document_summarization"
                      ).getProvideSources()
                    : undefined
                }
                children="Provide sources"
              />
              <div style={{ height: 20 }} />
              <Tooltip
                placement="bottom"
                // title={checkIfToolIsSelected('document_comparison') ? (filesSelected.length === 0 || (filterSelectedTools('document_comparison').getFile().length === 0 && filterSelectedTools('document_comparison').getRegulation() === undefined && filterSelectedTools('document_comparison').getChecklist() === undefined)) ? "Please select the files you want to compare and complete all the required fields from the tool." : undefined : undefined}
                title="In development..."
              >
                <ButtonAntd
                  // disabled={checkIfToolIsSelected('document_comparison') ? (filesSelected.length === 0 || (filterSelectedTools('document_comparison').getFile().length === 0 && filterSelectedTools('document_comparison').getRegulation() === undefined && filterSelectedTools('document_comparison').getChecklist() === undefined)) : false}
                  disabled={true}
                  // onClick={checkIfToolIsSelected('document_comparison') ? () => {
                  //   if (filesSelected.length === 0 || (filterSelectedTools('document_comparison').getFile().length === 0 && filterSelectedTools('document_comparison').getRegulation() === undefined && filterSelectedTools('document_comparison').getChecklist() === undefined)) return;
                  //   setShowCompareDocumentsModal(true);
                  // } : undefined}
                  // loading={showCompareDocumentsModal}
                  icon={<CgFileDocument style={{ width: 20, height: 20 }} />}
                  style={{
                    alignSelf: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  type="primary"
                  size="middle"
                >
                  Summarize Documents
                </ButtonAntd>
              </Tooltip>
            </Form>
          </div>
        </div>
      </CSSTransition>
    </>
  );
};

export default DocumentSummarizationTool;
