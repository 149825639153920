import {
  PSComparisonFeature,
  SummarizationContractType,
  SummarizationToneType,
} from "../../../../../data/PredefinedToolInputs";

import getCookie from "../../../../../helpers/getCookie";

const backendUrl = process.env.REACT_APP_API_URL ?? "";

export const deleteMetadataContractType = async (id: string | number) => {
  await fetch(`${backendUrl}/api/delete_metadata_contract_type/${id}`, {
    method: "DELETE",
    headers: { "X-CSRFToken": getCookie("csrftoken")! },
    credentials: "include",
  });
};

export const editMetadataContractType = async (
  id: string | number,
  data: { name: string }
) => {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("X-CSRFToken", getCookie("csrftoken")!);
  const body = JSON.stringify({ name: data.name });
  await fetch(`${backendUrl}/api/edit_metadata_contract_type/${id}`, {
    method: "PATCH",
    body,
    headers,
    credentials: "include",
  });
};

export const createMetadataContractType = async (
  data: { name: string },
  teamId: string | number
) => {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("X-CSRFToken", getCookie("csrftoken")!);
  const body = JSON.stringify({ name: data.name });
  await fetch(`${backendUrl}/api/create_metadata_contract_type/${teamId}`, {
    method: "POST",
    body,
    headers,
    credentials: "include",
  });
};

export const deleteMetadataType = async (id: string | number) => {
  await fetch(`${backendUrl}/api/delete_metadata_type/${id}`, {
    method: "DELETE",
    headers: { "X-CSRFToken": getCookie("csrftoken")! },
    credentials: "include",
  });
};

export const editMetadataType = async (
  id: string | number,
  data: {
    name: string;
    description: string;
    type: string;
    output_format: string;
  }
) => {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("X-CSRFToken", getCookie("csrftoken")!);
  const body = JSON.stringify({
    name: data.name,
    description: data.description,
    type: data.type,
    output_format: data.output_format,
  });
  await fetch(`${backendUrl}/api/edit_metadata_type/${id}`, {
    method: "PATCH",
    body,
    headers,
    credentials: "include",
  });
};

export const createMetadataType = async (
  metadataContractTypeId: string | number,
  data: {
    name: string;
    description: string;
    type: string;
    output_format: string;
  }
) => {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("X-CSRFToken", getCookie("csrftoken")!);
  const body = JSON.stringify({
    name: data.name,
    description: data.description,
    type: data.type,
    output_format: data.output_format,
  });
  await fetch(
    `${backendUrl}/api/create_metadata_type/${metadataContractTypeId}`,
    { method: "POST", body, headers, credentials: "include" }
  );
};

export const deleteObligationContractType = async (id: string | number) => {
  await fetch(`${backendUrl}/api/delete_obligation_contract_type/${id}`, {
    method: "DELETE",
    headers: { "X-CSRFToken": getCookie("csrftoken")! },
    credentials: "include",
  });
};

export const editObligationContractType = async (
  id: string | number,
  data: { name: string }
) => {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("X-CSRFToken", getCookie("csrftoken")!);
  const body = JSON.stringify({ name: data.name });
  await fetch(`${backendUrl}/api/edit_obligation_contract_type/${id}`, {
    method: "PATCH",
    body,
    headers,
    credentials: "include",
  });
};

export const createObligationContractType = async (
  data: { name: string },
  teamId: string | number
) => {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("X-CSRFToken", getCookie("csrftoken")!);
  const body = JSON.stringify({ name: data.name });
  await fetch(`${backendUrl}/api/create_obligation_contract_type/${teamId}`, {
    method: "POST",
    body,
    headers,
    credentials: "include",
  });
};

export const deleteObligationType = async (id: string | number) => {
  await fetch(`${backendUrl}/api/delete_obligation_type/${id}`, {
    method: "DELETE",
    headers: { "X-CSRFToken": getCookie("csrftoken")! },
    credentials: "include",
  });
};

export const createObligationType = async (
  obligationContractTypeId: string | number,
  data: { name: string; description: string }
) => {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("X-CSRFToken", getCookie("csrftoken")!);
  const body = JSON.stringify(data);
  await fetch(
    `${backendUrl}/api/create_obligation_type/${obligationContractTypeId}`,
    { method: "POST", body, headers, credentials: "include" }
  );
};

export const editObligationType = async (
  id: string | number,
  data: { name: string; description: string }
) => {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("X-CSRFToken", getCookie("csrftoken")!);
  const body = JSON.stringify({
    name: data.name,
    description: data.description,
  });
  await fetch(`${backendUrl}/api/edit_obligation_type/${id}`, {
    method: "PATCH",
    body,
    headers,
    credentials: "include",
  });
};

export const deleteRiskContractType = async (id: string | number) => {
  await fetch(`${backendUrl}/api/delete_risk_contract_type/${id}`, {
    method: "DELETE",
    headers: { "X-CSRFToken": getCookie("csrftoken")! },
    credentials: "include",
  });
};

export const createRiskContractType = async (
  data: { name: string },
  teamId: string | number
) => {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("X-CSRFToken", getCookie("csrftoken")!);
  const body = JSON.stringify({ name: data.name });
  await fetch(`${backendUrl}/api/create_risk_contract_type/${teamId}`, {
    method: "POST",
    body,
    headers,
    credentials: "include",
  });
};

export const editRiskContractType = async (
  id: string | number,
  data: { name: string }
) => {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("X-CSRFToken", getCookie("csrftoken")!);
  const body = JSON.stringify({ name: data.name });
  await fetch(`${backendUrl}/api/edit_risk_contract_type/${id}`, {
    method: "PATCH",
    body,
    headers,
    credentials: "include",
  });
};

export const deleteRiskType = async (id: string | number) => {
  await fetch(`${backendUrl}/api/delete_risk_type/${id}`, {
    method: "DELETE",
    headers: { "X-CSRFToken": getCookie("csrftoken")! },
    credentials: "include",
  });
};

export const createRiskType = async (
  riskContractTypeId: string | number,
  data: { name: string; description: string }
) => {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("X-CSRFToken", getCookie("csrftoken")!);
  const body = JSON.stringify({
    name: data.name,
    description: data.description,
  });
  await fetch(`${backendUrl}/api/create_risk_type/${riskContractTypeId}`, {
    method: "POST",
    body,
    headers,
    credentials: "include",
  });
};

export const editRiskType = async (
  id: string | number,
  data: { name: string; description: string }
) => {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("X-CSRFToken", getCookie("csrftoken")!);
  const body = JSON.stringify({
    name: data.name,
    description: data.description,
  });
  await fetch(`${backendUrl}/api/edit_risk_type/${id}`, {
    method: "PATCH",
    body,
    headers,
    credentials: "include",
  });
};

export const deleteComparisonRegulationType = async (id: string | number) => {
  await fetch(`${backendUrl}/api/delete_comparison_regulation_type/${id}`, {
    method: "DELETE",
    headers: { "X-CSRFToken": getCookie("csrftoken")! },
    credentials: "include",
  });
};

export const editComparisonRegulationType = async (
  id: string | number,
  data: { name: string }
) => {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("X-CSRFToken", getCookie("csrftoken")!);
  const body = JSON.stringify({ name: data.name });
  await fetch(`${backendUrl}/api/edit_comparison_regulation_type/${id}`, {
    method: "PATCH",
    body,
    headers,
    credentials: "include",
  });
};

export const createComparisonRegulationType = async (
  data: { name: string },
  teamId: string | number
) => {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("X-CSRFToken", getCookie("csrftoken")!);
  const body = JSON.stringify({ name: data.name });
  await fetch(`${backendUrl}/api/create_comparison_regulation_type/${teamId}`, {
    method: "POST",
    body,
    headers,
    credentials: "include",
  });
};

export const deleteComparisonChecklistType = async (id: string | number) => {
  await fetch(`${backendUrl}/api/delete_comparison_checklist_type/${id}`, {
    method: "DELETE",
    headers: { "X-CSRFToken": getCookie("csrftoken")! },
    credentials: "include",
  });
};

export const editComparisonChecklistType = async (
  id: string | number,
  data: { name: string }
) => {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("X-CSRFToken", getCookie("csrftoken")!);
  const body = JSON.stringify({ name: data.name });
  await fetch(`${backendUrl}/api/edit_comparison_checklist_type/${id}`, {
    method: "PATCH",
    body,
    headers,
    credentials: "include",
  });
};

export const createComparisonChecklistType = async (
  data: { name: string },
  teamId: string | number
) => {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("X-CSRFToken", getCookie("csrftoken")!);
  const body = JSON.stringify({ name: data.name });
  await fetch(`${backendUrl}/api/create_comparison_checklist_type/${teamId}`, {
    method: "POST",
    body,
    headers,
    credentials: "include",
  });
};

export const fetchSummarizationContractTypes = async (
  teamId: string | number
) => {
  const response = await fetch(
    `${backendUrl}/api/summarization_contract_types/${teamId}`,
    { method: "GET", credentials: "include" }
  );
  const body: SummarizationContractType[] = await response.json();

  return body;
};

export const fetchSummarizationToneTypes = async (teamId: string | number) => {
  const response = await fetch(
    `${backendUrl}/api/summarization_tone_types/${teamId}`,
    { method: "GET", credentials: "include" }
  );
  const body: SummarizationToneType[] = await response.json();

  return body;
};

export const fetchComparisonRegulations = async (teamId: string | number) => {
  const response = await fetch(
    `${backendUrl}/api/comparison_regulation_types/${teamId}`,
    { method: "GET", credentials: "include" }
  );
  const body = await response.json();

  return body;
};

export const fetchComparisonChecklists = async (teamId: string | number) => {
  const response = await fetch(
    `${backendUrl}/api/comparison_checklist_types/${teamId}`,
    { method: "GET", credentials: "include" }
  );
  const body = await response.json();

  return body;
};

export const fetchDraftingTemplateType = async (teamId: string | number) => {
  const response = await fetch(
    `${backendUrl}/api/get_drafting_template/${teamId}`,
    { method: "GET", credentials: "include" }
  );
  const body = await response.json();

  return body;
};

export const deleteDraftingTemplateType = async (id: string | number) => {
  await fetch(`${backendUrl}/api/delete_drafting_template/${id}`, {
    method: "DELETE",
    headers: { "X-CSRFToken": getCookie("csrftoken")! },
    credentials: "include",
  });
};

export const editDraftingTemplateType = async (
  id: string | number,
  data: { name: string; description: string }
) => {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("X-CSRFToken", getCookie("csrftoken")!);
  const body = JSON.stringify({
    name: data.name,
    description: data.description,
  });
  await fetch(`${backendUrl}/api/edit_drafting_template/${id}`, {
    method: "PATCH",
    body,
    headers,
    credentials: "include",
  });
};

export const createDraftingTemplateType = async (
  data: {
    name: string;
    description: string;
    file: any;
    baseline: string;
  },
  teamId: string | number
) => {
  const formData = new FormData();
  formData.append("name", data.name);
  formData.append("description", data.description);
  formData.append("file", data.file);
  formData.append("baseline", data.baseline ?? "weak");

  fetch(`${backendUrl}/api/create_drafting_template/${teamId}`, {
    method: "POST",
    body: formData,
    headers: { "X-CSRFToken": getCookie("csrftoken")! },
    credentials: "include",
  });
};
