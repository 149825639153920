import React, { useContext } from "react";
import UserAvatar from "./components/UserAvatar/UserAvatar";
import NotificationsBell from "./components/NotificationsBell/NotificationsBell";
import MainContext from "../../../../contexts/MainContext";
import Help from "./components/Help/Help";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import styles from "./Topbar.module.css";
import { useEffect } from "react";
const Topbar: React.FC = () => {
  const mainContext = useContext(MainContext);
  const navigate = useNavigate();

  const location  = useLocation();

  const queryString = window.location.search;

   const navigateToPage = () => {
    console.log("Topbar navigateToPage")
    navigate(`/${mainContext?.teamSelected?.id}/${mainContext?.projectSelected?.id}/${mainContext?.menuItemSelected.toLowerCase()}` );

  
  }
  
  useEffect(() => {
    // Only navigate if the current path doesn't match the selected menu item
    if (mainContext?.teamSelected && mainContext?.projectSelected){
      console.log("Topbar")
      navigateToPage();

    }
  }, [mainContext?.menuItemSelected ]);



  return (
    <div className={styles.topbar}>
      {mainContext?.projectSelected ? (
        <>
          <ul className={styles.navigation}>
            <li
              className={styles.navigation_item}
              onClick={() => {mainContext?.setMenuItemSelected("TOOLS");  }}
            >
              <button
                className={`${styles.navigation_item_text} ${
                  mainContext?.menuItemSelected === "TOOLS"
                    ? styles.navigation_item_selected
                    : ""
                }`}
              >
                Tools
              </button>
            </li>
            <li
              className={styles.navigation_item}
              onClick={() => {mainContext?.setMenuItemSelected("RESULTS");  }}
            >
              <button
                className={`${styles.navigation_item_text} ${
                  mainContext?.menuItemSelected === "RESULTS"
                    ? styles.navigation_item_selected
                    : ""
                }`}
              >
                Results
              </button>
            </li>
            <li
              className={styles.navigation_item}
              onClick={() => {mainContext?.setMenuItemSelected("SEARCH");  }}
            >
              <button
                className={`${styles.navigation_item_text} ${
                  mainContext?.menuItemSelected === "SEARCH"
                    ? styles.navigation_item_selected
                    : ""
                }`}
              >
                AI Agent
              </button>
            </li>
          </ul>
          <p
            className={styles.navigation_project_name}
            title={mainContext?.projectSelected?.name}
          >
            {mainContext?.projectSelected?.name}
          </p>
        </>
      ) : (
        <div></div>
      )}

      <ul className={styles.navigation}>
        <li className={styles.navigation_item}>
          <Help />
        </li>
        <li className={styles.navigation_item}>
          <NotificationsBell />
        </li>
        <li className={styles.navigation_item}>
          <UserAvatar />
        </li>
      </ul>
    </div>
  );
};

export default Topbar;
