import { Dropdown, MenuProps, Tooltip, Popconfirm } from "antd";
import React, { useContext } from "react";
import { GoBell, GoBellFill } from "react-icons/go";
import { MdOutlineMarkEmailRead } from "react-icons/md";
import { LuMailX } from "react-icons/lu";
import MainContext from "../../../../contexts/MainContext";
import Notification_ from "../../../../data/Notification_";
import displayNumberUntil from "../../../../helpers/displayNumberUntil";
import AdminRoleNotification from "../../../../widgets/notifications/AdminRoleNotification";
import TeamInvitationAcceptedNotification from "../../../../widgets/notifications/TeamInvitationAcceptedNotification";
import TeamInvitationDeclinedNotification from "../../../../widgets/notifications/TeamInvitationDeclinedNotification";
import TeamInvitationPendingNotification from "../../../../widgets/notifications/TeamInvitationPendingNotification";
import ToolProcessingFailedNotification from "../../../../widgets/notifications/ToolProcessingFailedNotification";
import ToolProcessingOngoingNotification from "../../../../widgets/notifications/ToolProcessingOngoingNotification";
import ToolProcessingSucceededNotification from "../../../../widgets/notifications/ToolProcessingSucceededNotification";

import styles from "./NotificationsBell.module.css";

interface NotificationComponentsMap {
  [key: string]: React.ComponentType<{
    notification: Notification_;
    key: string | number;
  }>;
}

const NotificationsBell = () => {
  const mainContext = useContext(MainContext);

  const notificationComponents: NotificationComponentsMap = {
    tool_process_failed: ToolProcessingFailedNotification,
    tool_process_succeeded: ToolProcessingSucceededNotification,
    tool_process_ongoing: ToolProcessingOngoingNotification,
    admin_role: AdminRoleNotification,
    team_invitation_pending: TeamInvitationPendingNotification,
    team_invitation_declined: TeamInvitationDeclinedNotification,
    team_invitation_accepted: TeamInvitationAcceptedNotification,
  };

  const renderNotificationsItem = (
    notification: Notification_
  ): React.ReactNode => {
    const NotificationComponent = notificationComponents[notification.type];
    return (
      NotificationComponent && (
        <NotificationComponent
          key={notification.id}
          notification={notification}
        />
      )
    );
  };

  const notificationItems =
    mainContext?.notifications.length === 0
      ? [
          {
            key: 0,
            label: <p className={styles.empty_state}>No new notifications</p>,
          },
        ]
      : mainContext!.notifications.map((notification, index) => ({
          label: renderNotificationsItem(notification),
          key: index,
        }));

  const items: MenuProps["items"] = [
    {
      key: "title",
      label: (
        <div className={styles.header}>
          <p className={styles.title}>
            <span>Notifications</span>
            {mainContext?.notifications.some(
              (notification) => notification.active
            ) && (
              <span className={styles.title_notifications_counter}>
                {displayNumberUntil(
                  mainContext?.notifications.filter(
                    (notification) => notification.active
                  ).length!,
                  99
                )}
              </span>
            )}
          </p>
          <p>
            {mainContext?.notifications.some(
              (notification) => notification.active
            ) && (
              <Tooltip zIndex={9002} title="Mark all as read">
                <MdOutlineMarkEmailRead
                  onClick={(e) => {
                    mainContext?.readAllNotifications();
                    e.stopPropagation();
                  }}
                  className={styles.read_all_notifications_icon}
                />
              </Tooltip>
            )}
            {mainContext?.notifications.length !== 0 && (
              <Tooltip zIndex={9002} title="Delete all notifications">
                <Popconfirm
                  placement="left"
                  zIndex={9002}
                  title="Are you sure you want to delete all your notifications?"
                  okText="Yes"
                  cancelText="No"
                  onConfirm={(e) => {
                    mainContext?.deleteAllNotifications();
                    e!.stopPropagation();
                  }}>
                  <LuMailX className={styles.clear_notifications_icon} />
                </Popconfirm>
              </Tooltip>
            )}
          </p>
        </div>
      ),
      type: "group",
    },
    {
      type: "divider",
    },
    ...notificationItems,
  ];

  return (
    <Dropdown
      overlayClassName={styles.overlay}
      menu={{ items }}
      trigger={["click"]}
      placement="bottomRight">
      <div className={styles.notification_bell_container}>
        {mainContext?.notifications.some(
          (notification) => notification.active
        ) ? (
          <GoBellFill style={{ width: 24, height: 24 }} />
        ) : (
          <GoBell style={{ width: 24, height: 24 }} />
        )}

        {mainContext?.notifications.some(
          (notification) => notification.active
        ) && (
          <div className={styles.notifications_counter}>
            {displayNumberUntil(
              mainContext?.notifications.filter(
                (notification) => notification.active
              ).length!,
              99
            )}
          </div>
        )}
      </div>
    </Dropdown>
  );
};

export default NotificationsBell;
