export enum ResultTypes {
  Metadata = "metadata",
  Obligations = "obligations",
  Risks = "risks",
  Comparison = "comparison",
  Drafting = "drafting",
  Translation = "translation",
  Invoice = "invoice",
  Timeline = "timeline",
}
