import { ConfigProvider, theme } from "antd";
import "bootstrap/dist/css/bootstrap.min.css";
import ReactDOM from "react-dom/client";
import { RouterProvider, createBrowserRouter, useNavigate, useLocation } from "react-router-dom";
import  {ToolTypes}  from "./enums/toolTypes.enum";
import AppAuthPage from "./pages/MainPage/AppAuthPage";
import "./colors.css";
import styles from "./index.module.css";
import NotFoundPage from "./pages/NotFoundPage";
import TeamsPage from "./pages/TeamsPage/TeamsPage";
import MainPage from "./pages/MainPage/MainPage";
import ToolsPage from "./pages/ToolsPage/ToolsPage";
import ResultsPage from "./pages/ResultsPage/ResultsPage";
import SearchPage from "./pages/SearchPage/SearchPage";
import App from "./App"; // The wrapper component that uses location
import { Navigate } from "react-router-dom";
import { useEffect } from "react";

const RedirectComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      if (location.pathname === '/') {
        const lastPath = localStorage.getItem('lastPath');
      if (lastPath && lastPath !== '/') {
        navigate(lastPath, { replace: true });
        window.location.reload();
      }
    } else {
      localStorage.setItem('lastPath', location.pathname + location.search);
    }}, 1000);
  }, [location, navigate]);

  return null;
};

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <>
        <RedirectComponent />
        <MainPage />
      </>
    ),
    children: [
      {
        path: "/:teamId/:projectId/tools",
        element: <ToolsPage />,
      },

      {
        path: "/:teamId",
        element: <ToolsPage />,
      },
      {
        path: "/:teamId/:projectId/results",
        element: <ResultsPage />,
      },
      // {
      //   path: "/:teamId/:projectId/results",
      //   element: <Navigate to="metadata" />,  // Redirect to default tool if not provided
      // },

      {
        path: "/:teamId/:projectId/search",
        element: <SearchPage />,
      },
    ],
  },
  {
    path: "/teams",
    element: <TeamsPage />,
  },
  {
    path: "/authApp",
    element: <AppAuthPage />,
  },
  {
    path: "*",
    element: <NotFoundPage />,
  },
]);
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <div className={styles.body}>
    <ConfigProvider
      theme={{
        algorithm: theme.darkAlgorithm,
        token: {
          colorPrimary: "#86bc25",
        },
        components: {
          Dropdown: {
            zIndexPopup: 9001,
          },
        },
      }}
    >
      <App>
      <RouterProvider router={router}/>
      </App>
  
    </ConfigProvider>
  </div>
);