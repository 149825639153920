import { Button, Form, Input, Modal } from "antd";
import React, { useContext, useEffect, useState } from "react";

import styles from "./CreateEditContractTypeModal.module.css";

import TeamsContext from "../../../../../../contexts/TeamsContext";
import { TypeBase } from "../../../../../../data/PredefinedToolInputs";

interface CreateEditContractTypeModalProps {
  isOpen: boolean;
  closeModal: () => void;
  contractTypeToEdit: TypeBase | undefined;
  createContractTypeFn: (
    data: {
      name: string;
    },
    teamId: string | number
  ) => Promise<void>;
  editContractTypeFn: (
    id: string | number,
    data: {
      name: string;
    }
  ) => Promise<void>;
}

export interface ContractTypeForm {
  name: string;
  id: number | string | undefined;
}

const CreateEditContractTypeModal: React.FC<
  CreateEditContractTypeModalProps
> = ({
  isOpen,
  closeModal,
  contractTypeToEdit,
  createContractTypeFn,
  editContractTypeFn,
}) => {
  const teamsContext = useContext(TeamsContext);

  const [isLoading, setIsLoading] = useState(false);
  const [typeForm, setTypeForm] = useState<ContractTypeForm>({
    name: "",
    id: undefined,
  });

  useEffect(() => {
    if (contractTypeToEdit) {
      setTypeForm({
        name: contractTypeToEdit.name,
        id: contractTypeToEdit.id,
      });
    } else {
      setTypeForm({
        name: "",
        id: undefined,
      });
    }
  }, [isOpen]);

  async function handleCreateDocumentType() {
    createContractTypeFn(
      { name: typeForm.name },
      teamsContext?.teamSelected?.id!
    );
    closeModal();
  }

  async function handleEditDocumentType() {
    await editContractTypeFn(typeForm.id!, {
      name: typeForm.name,
    });

    closeModal();
  }

  const onSubmit = async () => {
    setIsLoading(true);
    if (typeForm.id) {
      handleEditDocumentType();
    } else {
      handleCreateDocumentType();
    }
    setIsLoading(false);
    closeModal();
  };

  return (
    <Modal
      width="60%"
      centered
      open={isOpen}
      title={[
        <p key="title" className={styles.title}>
          {contractTypeToEdit ? "Edit document type" : "Add document type"}
        </p>,
        <p key="subTitle" className={styles.subTitle}>
          {contractTypeToEdit
            ? "Please type a name for your new document type."
            : "Please type the new name for this document type."}
        </p>,
      ]}
      onOk={onSubmit}
      onCancel={closeModal}
      footer={[
        <Button key="back" className={styles.close_button} onClick={closeModal}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          className={styles.submit_button}
          disabled={!typeForm.name}
          loading={isLoading}
          onClick={onSubmit}
        >
          Submit
        </Button>,
      ]}
    >
      <Form className={styles.form} layout="vertical">
        <Form.Item required label="Document type name">
          <Input
            value={typeForm.name}
            name="name"
            allowClear
            placeholder="Type the document type name"
            onChange={(event) =>
              setTypeForm({ ...typeForm, name: event?.target.value })
            }
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateEditContractTypeModal;
