import { ResultTypes } from "../enums/resultTypes.enum";
import * as ResultsService from "../services/results.service";

type BatchExportXLSXMap = {
  [key in ResultTypes]: (csrfToken: string, selectedRows: React.Key[]) => void;
};

const batchExportXLSXFunctions: Omit<
  BatchExportXLSXMap,
  | ResultTypes.Drafting
  | ResultTypes.Translation
  | ResultTypes.Invoice
  | ResultTypes.Timeline
> = {
  [ResultTypes.Metadata]: (csrfToken, selectedRows) =>
    ResultsService.batchExport(
      csrfToken,
      selectedRows,
      "batch_export_metadata_XLSX"
    ),
  [ResultTypes.Obligations]: (csrfToken, selectedRows) =>
    ResultsService.batchExport(
      csrfToken,
      selectedRows,
      "batch_export_obligation_XLSX"
    ),
  [ResultTypes.Risks]: (csrfToken, selectedRows) =>
    ResultsService.batchExport(
      csrfToken,
      selectedRows,
      "batch_export_risk_XLSX"
    ),
  [ResultTypes.Comparison]: (csrfToken, selectedRows) =>
    ResultsService.batchExport(
      csrfToken,
      selectedRows,
      "batch_export_comparison_XLSX"
    ),
};

export default batchExportXLSXFunctions;
