import { Avatar, Dropdown, MenuProps } from "antd";
import React, { useContext } from "react";
import AuthContext from "../../../../contexts/AuthContext";

interface UserAvatarProps {
  items: MenuProps["items"];
}

const UserAvatar: React.FC<UserAvatarProps> = ({ items }) => {
  const authContext = useContext(AuthContext);

  return (
    <>
      <Dropdown menu={{ items }} trigger={["click"]} placement="bottomRight">
        <Avatar
          src={authContext?.user?.profilePicture}
          icon={authContext?.user?.firstname[0]}
        />
      </Dropdown>
    </>
  );
};

export default UserAvatar;
