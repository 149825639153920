import React, { ReactNode, createContext, useState } from "react";
import {
  ComparisonChecklist,
  ComparisonRegulation,
  DraftingTemplateType,
  MetadataContractType,
  ObligationContractType,
  RiskContractType,
} from "../data/PredefinedToolInputs";

const backendUrl = process.env.REACT_APP_API_URL ?? "";

interface TypesContextType {
  metadataContractTypes: Array<MetadataContractType>;
  fetchMetadataContractTypes: (teamId: string | number) => void;
  obligationContractTypes: Array<ObligationContractType>;
  fetchObligationContractTypes: (teamId: string | number) => void;
  riskContractTypes: Array<RiskContractType>;
  fetchRiskContractTypes: (teamId: string | number) => void;
  comparisonRegulationTypes: Array<ComparisonRegulation>;
  fetchComparisonRegulations: (teamId: string | number) => Promise<void>;
  comparisonChecklistTypes: Array<ComparisonChecklist>;
  fetchComparisonChecklists: (teamId: string | number) => Promise<void>;
  draftingTemplateTypes: Array<DraftingTemplateType>;
  fetchDraftingTemplateTypes: (teamId: string | number) => Promise<void>;
}

const TypesContext = createContext<TypesContextType | undefined>(undefined);

interface TypesProviderProps {
  children: ReactNode;
}

export const TypesProvider: React.FC<TypesProviderProps> = (props) => {
  const [metadataContractTypes, setMetadataContractTypes] = useState<
    Array<MetadataContractType>
  >([]);

  const [obligationContractTypes, setObligationContractTypes] = useState<
    Array<ObligationContractType>
  >([]);

  const [riskContractTypes, setRiskContractTypes] = useState<
    Array<RiskContractType>
  >([]);

  const [comparisonRegulationTypes, setComparisonRegulationTypes] = useState<
    Array<ComparisonRegulation>
  >([]);

  const [comparisonChecklistTypes, setComparisonChecklistTypes] = useState<
    Array<ComparisonChecklist>
  >([]);

  const [draftingTemplateTypes, setDraftingTemplateTypes] = useState<
    Array<DraftingTemplateType>
  >([]);

  const fetchMetadataContractTypes = async (teamId: string | number) => {
    const response = await fetch(
      `${backendUrl}/api/metadata_contract_types/${teamId}`,
      { method: "GET", credentials: "include" }
    );
    const body: MetadataContractType[] = await response.json();
    setMetadataContractTypes(body);
    return body;
  };

  const fetchObligationContractTypes = async (teamId: string | number) => {
    const response = await fetch(
      `${backendUrl}/api/obligation_contract_types/${teamId}`,
      { method: "GET", credentials: "include" }
    );
    const body: ObligationContractType[] = await response.json();
    setObligationContractTypes(body);
    return body;
  };

  const fetchRiskContractTypes = async (teamId: string | number) => {
    const response = await fetch(
      `${backendUrl}/api/risk_contract_types/${teamId}`,
      { method: "GET", credentials: "include" }
    );
    const body: RiskContractType[] = await response.json();
    setRiskContractTypes(body);
    return body;
  };

  const fetchComparisonRegulations = async (teamId: string | number) => {
    const response = await fetch(
      `${backendUrl}/api/comparison_regulation_types/${teamId}`,
      { method: "GET", credentials: "include" }
    );
    const body = await response.json();

    setComparisonRegulationTypes(body);
    return body;
  };

  const fetchComparisonChecklists = async (teamId: string | number) => {
    const response = await fetch(
      `${backendUrl}/api/comparison_checklist_types/${teamId}`,
      { method: "GET", credentials: "include" }
    );
    const body = await response.json();

    setComparisonChecklistTypes(body);
    return body;
  };

  const fetchDraftingTemplateType = async (teamId: string | number) => {
    const response = await fetch(
      `${backendUrl}/api/get_drafting_template/${teamId}`,
      { method: "GET", credentials: "include" }
    );
    const body = await response.json();

    setDraftingTemplateTypes(body);
    return body;
  };

  return (
    <TypesContext.Provider
      value={{
        metadataContractTypes,
        fetchMetadataContractTypes,
        obligationContractTypes,
        fetchObligationContractTypes,
        riskContractTypes,
        fetchRiskContractTypes,
        comparisonRegulationTypes,
        fetchComparisonRegulations,
        comparisonChecklistTypes,
        fetchComparisonChecklists,
        draftingTemplateTypes,
        fetchDraftingTemplateTypes: fetchDraftingTemplateType,
      }}
    >
      {props.children}
    </TypesContext.Provider>
  );
};

export default TypesContext;
