import React, { useContext, useEffect, useRef, useState } from "react";
import { Button } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import { ToolTypes } from "../../../../enums/toolTypes.enum";
import MainContext from "../../../../contexts/MainContext";
import ToolButton from "./components/ToolButton/ToolButton";
import toolsButtonData from "./helpers/toolsButtonData";

import styles from "./ToolsBar.module.css";

const ToolsBar = () => {
  const mainContext = useContext(MainContext);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const renderIconComponent = (tool: ToolTypes) => {
    const IconComponent = toolsButtonData[tool].icon;
    return <IconComponent className={styles.tool_icon} />;
  };

  return (
    <div className={styles.tools_bar_container}>
      <div ref={dropdownRef} className={styles.tools_bar}>
        {mainContext?.teamSelected?.tools.slice(0, 4).map((tool) => (
          <ToolButton
            key={tool}
            toolType={tool}
            icon={renderIconComponent(tool)}
            title={toolsButtonData[tool].title}
            inDevMode={toolsButtonData[tool].inDevMode}
          />
        ))}

        <Button
          type="text"
          icon={<MoreOutlined />}
          onClick={() => setIsDropdownOpen((prev) => !prev)}
        />
        <div
          className={`${!isDropdownOpen ? styles.closed : ""} ${
            styles.additional_tools
          }`}
        >
          {mainContext?.teamSelected?.tools.slice(4).map((tool) => (
            <div key={tool} className={styles.tool_button_container}>
              <ToolButton
                toolType={tool}
                icon={renderIconComponent(tool)}
                title={toolsButtonData[tool].title}
                inDevMode={toolsButtonData[tool].inDevMode}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ToolsBar;
