import React, { useContext, useState } from "react";
import { Button, Checkbox, Form, Input, Select, Tooltip } from "antd";
import { ClearOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import MainContext from "../../../../contexts/MainContext";
import ToolsContext from "../../../../contexts/ToolsContext";
import filterOption from "../../../../helpers/selectFilterOption";
import { MdOutlineDocumentScanner } from "react-icons/md";
import { ImInsertTemplate } from "react-icons/im";
import TransitionToolWrapper from "../TransitionToolWrapper/TransitionToolWrapper";
import ToolBase from "../ToolBase/ToolBase";
import TemplatesContext from "../../../../contexts/TemplatesContext";
import getCookie from "../../../../helpers/getCookie";
import { TimelineForm } from "./interfaces/timelineForm.interface";
import languagesOptions from "../../../../helpers/languagesOptions";
import CreateTemplateModal from "../CreateTemplateModal/CreateTemplateModal";
import ConfirmToolModal from "../ConfirmToolModal/ConfirmToolModal";
import { ToolTypes } from "../../../../enums/toolTypes.enum";
import * as ToolsService from "../../services/tools.service";

import styles from "./TimelineAnalysisTool.module.css";

const TimelineAnalysisTool: React.FC = () => {
  const mainContext = useContext(MainContext);
  const templatesContext = useContext(TemplatesContext);
  const toolsContext = useContext(ToolsContext);

  const csrfToken = getCookie("csrftoken") ?? "";

  const [template, setTemplate] = useState<
    { value: number; label: string } | undefined
  >(undefined);
  const [templates, setTemplates] = useState<
    Array<{ value: number; label: string }>
  >([]);
  const [fetchingTemplates, setFetchingTemplates] = useState(false);

  const [formData, setFormData] = useState<TimelineForm>({
    isProvideTimeline: false,
    orderingCriteria: {
      id: "Effective Date",
      name: "Effective Date",
    },
    clausesToCompare: "",
    language: "English",
  });

  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const [showCreateTemplateModal, setShowCreateTemplateModal] = useState(false);

  const [resultName, setResultName] = useState<string | undefined>(undefined);

  const getTemplate = async (template: { value: number; label: string }) => {
    setTemplate(template);
    if (!template) {
      setFormData({
        isProvideTimeline: false,
        orderingCriteria: {
          id: "Effective Date",
          name: "Effective Date",
        },
        clausesToCompare: "",
        language: "English",
      });
      return;
    }
  };

  const createTemplate = async (templateName: string) => {
    console.log("CREATE TEMPLATE");
  };

  const updateTemplate = async (templateName: string) => {
    console.log("UPDATE TEMPLATE");
  };

  const deleteTemplate = async (data: { value: number; label: string }) => {
    console.log("DELETE TEMPLATE");
    setTemplates((templates) =>
      templates.filter((template) => template.value !== data.value)
    );
    setTemplate(undefined);
  };

  const renderResultNameField = () => (
    <Form.Item
      required
      label="Result name"
      className={styles.result_name_field}>
      <Input
        allowClear
        placeholder="Ex.: Declaration of Independence"
        value={resultName}
        onChange={(event) => setResultName(event.target.value)}
      />
    </Form.Item>
  );

  return (
    <>
      <TransitionToolWrapper>
        <ToolBase
          icon={<MdOutlineDocumentScanner />}
          title="Timeline analysis"
          template={template}
          getTemplate={getTemplate}
          fetchingTemplates={fetchingTemplates}
          templates={templates}
          deleteTemplate={deleteTemplate}
          isDevelopmentMode={true}>
          <Form className={styles.form} layout="vertical">
            <Form.Item>
              <Checkbox
                checked={formData.isProvideTimeline}
                onChange={() =>
                  setFormData({
                    ...formData,
                    isProvideTimeline: !formData.isProvideTimeline,
                  })
                }>
                Provide timeline
              </Checkbox>
            </Form.Item>

            <Form.Item
              label={
                <>
                  <span className={styles.form_item_label}>
                    Ordering criteria
                  </span>
                  <Tooltip
                    overlayInnerStyle={{ width: "400px" }}
                    title={
                      <>
                        <p className={styles.tooltip_hint_title}>
                          What is "Ordering criteria"?
                        </p>
                        <p>
                          Arrange your documents in order based on criteria such
                          as effective date, signature date, and more. Customize
                          the view to quickly identify the most relevant or
                          recent documents.
                        </p>
                      </>
                    }>
                    <QuestionCircleOutlined />
                  </Tooltip>
                </>
              }>
              <Select
                disabled={!formData.isProvideTimeline}
                showSearch
                filterOption={filterOption}
                placeholder="Select ordering criteria"
                onChange={(value: string, option: any) =>
                  setFormData({
                    ...formData,
                    orderingCriteria: { id: option.value, name: option.label },
                  })
                }
                value={formData.orderingCriteria.name}
                options={[
                  {
                    value: "Effective Date",
                    label: "Effective Date",
                  },
                  {
                    value: "Signature Date",
                    label: "Signature Date",
                  },
                  {
                    value: "Create Date",
                    label: "Create Date",
                  },
                ]}
              />
            </Form.Item>

            <Form.Item
              label={
                <>
                  <span className={styles.form_item_label}>Custom prompt</span>
                  <Tooltip
                    overlayInnerStyle={{ width: "400px" }}
                    title={
                      <>
                        <p className={styles.tooltip_hint_title}>
                          What would you like to analyze?
                        </p>
                        <p>
                          Enter the metrics (e.g., KPI, SLA) or any relevant
                          criteria that will be used to analyze your documents.
                        </p>
                      </>
                    }>
                    <QuestionCircleOutlined />
                  </Tooltip>
                </>
              }>
              <Input
                allowClear
                placeholder="Type custom prompt to analyze"
                value={formData.clausesToCompare}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setFormData({
                    ...formData,
                    clausesToCompare: event.target.value,
                  })
                }
              />
            </Form.Item>

            <Form.Item
              className={styles.language_input}
              label="Language of the output">
              <Select
                showSearch
                filterOption={filterOption}
                placeholder="Select the language of the output"
                onChange={(value: string, option: any) =>
                  setFormData({
                    ...formData,
                    language: option.label,
                  })
                }
                value={formData.language}
                options={languagesOptions}
              />
            </Form.Item>

            <div className={styles.tool_footer}>
              <Button
                type="primary"
                ghost
                size="middle"
                icon={<ClearOutlined className={styles.button_icon} />}
                disabled={
                  (!formData.isProvideTimeline && !formData.clausesToCompare) ||
                  !!template
                }
                onClick={() => {
                  setFormData({
                    isProvideTimeline: false,
                    orderingCriteria: {
                      id: "Effective Date",
                      name: "Effective Date",
                    },
                    clausesToCompare: "",
                    language: "English",
                  });
                }}>
                Clear All Fields
              </Button>

              <Tooltip
                placement="bottom"
                title={
                  toolsContext?.selectedFiles.length === 0 ||
                  (!formData.isProvideTimeline && !formData.clausesToCompare) ||
                  !formData.language
                    ? "Please select the files you want to work with and provide timeline or type custom prompt."
                    : ""
                }>
                <Button
                  type="primary"
                  size="middle"
                  icon={
                    <MdOutlineDocumentScanner className={styles.button_icon} />
                  }
                  disabled={
                    toolsContext?.selectedFiles.length === 0 ||
                    (!formData.isProvideTimeline &&
                      !formData.clausesToCompare) ||
                    !formData.language
                  }
                  onClick={() => setShowSubmitModal(true)}
                  loading={showSubmitModal}>
                  Analyze
                </Button>
              </Tooltip>

              <Tooltip title="In Development">
                <Button
                  type="primary"
                  ghost
                  size="middle"
                  icon={<ImInsertTemplate className={styles.button_icon} />}
                  // disabled={isTemplatingDisabled[draftingType]}
                  disabled
                  onClick={() => setShowCreateTemplateModal(true)}>
                  {template ? "Update Template" : "Create Template"}
                </Button>
              </Tooltip>
            </div>
          </Form>
        </ToolBase>
      </TransitionToolWrapper>

      <ConfirmToolModal
        isOpen={showSubmitModal}
        title="Analyze"
        subTitle={
          <>
            {renderResultNameField()}
            <p>Proceed by analyzing from these files:</p>
          </>
        }
        icon={<MdOutlineDocumentScanner className={styles.modal_icon} />}
        leftPart={{
          header: "Files",
          body: toolsContext?.selectedFiles.map((file) => file.name)!,
        }}
        rightPart={{
          header: "Options",
          body: [
            formData.isProvideTimeline ? formData.orderingCriteria.name : null,
            formData.clausesToCompare ? formData.clausesToCompare : null,
          ],
        }}
        onClose={() => setShowSubmitModal(false)}
        action={async () => {
          toolsContext?.setSelectedTools((prev) =>
            prev.filter((tool) => tool !== ToolTypes.TimelineAnalysis)
          );

          await ToolsService.analyzeTimeline(
            csrfToken,
            toolsContext?.selectedFiles.map((file) => file.id)!,
            formData.clausesToCompare,
            formData.isProvideTimeline,
            [formData.orderingCriteria.name],
            formData.language,
            resultName!
          );

          await mainContext?.fetchNotifications();
        }}
        isSubmitDisabled={!resultName}
      />

      <CreateTemplateModal
        isOpen={showCreateTemplateModal}
        title={
          template
            ? "Update Timeline Analysis Template"
            : "New Timeline Analysis Template"
        }
        subTitle={
          template
            ? "Type a new name for this template"
            : "Please type a name for new timeline analysis template."
        }
        onClose={() => setShowCreateTemplateModal(false)}
        action={template ? updateTemplate : createTemplate}
        predefinedValue={template ? template.label : ""}
      />
    </>
  );
};

export default TimelineAnalysisTool;
