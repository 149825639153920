import React from "react";
import { Modal } from "antd";

interface DeleteResultsModalProps {
  isOpen: boolean;
  isDeleting: boolean;
  onDeleteResults: () => void;
  onCancelDelete: () => void;
}

const DeleteResultsModal: React.FC<DeleteResultsModalProps> = ({
  isOpen,
  isDeleting,
  onDeleteResults,
  onCancelDelete,
}) => {
  return (
    <Modal
      centered
      title="Are you sure you want to continue?"
      open={isOpen}
      onOk={onDeleteResults}
      confirmLoading={isDeleting}
      okText="Yes"
      okButtonProps={{ style: { backgroundColor: "var(--beautiful-red)" } }}
      onCancel={onCancelDelete}
      cancelText="No"
      cancelButtonProps={{ disabled: isDeleting }}
    >
      <p>This action cannot be undone!</p>
    </Modal>
  );
};

export default DeleteResultsModal;
