import React, { useState, useContext } from "react";
import { createPortal } from "react-dom";
import Button from "react-bootstrap/Button";
import { IoIosClose } from "react-icons/io";
import { FaUserPlus } from "react-icons/fa";

import styles from "./InviteMemberModal.module.css";
import MainContext from "../../../../../../contexts/MainContext";
import TeamsContext from "../../../../../../contexts/TeamsContext";
import SearchBar from "../../../../../../widgets/SearchBar";
import { UserValue } from "../../../../../../data/User";
import { inviteTeamMember } from "../../../../services/teams.service";

interface _InviteMemberModalProps {
  onClose: () => void;
}

const InviteMemberModal: React.FC<_InviteMemberModalProps> = ({ onClose }) => {
  const [value, setValue] = useState<UserValue[]>([]);
  const mainContext = useContext(MainContext);
  const teamsContext = useContext(TeamsContext);

  const yesHandler = async () => {
    inviteTeamMember(teamsContext?.teamSelected?.id!, value[0].value);
    onClose();
  };

  const noHandler = () => onClose();

  return createPortal(
    <>
      <div className={styles.invite_member_modal_overlay}></div>
      <div className={styles.invite_member_modal}>
        <div
          onClick={onClose}
          className={styles.invite_member_modal_close_button}>
          <IoIosClose style={{ width: 30, height: 30 }} />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "10px 20px 30px 20px",
          }}>
          <FaUserPlus
            style={{ width: 30, height: 30, color: "var(--deloitte-green)" }}
          />
          <div style={{ height: 10 }} />
          <div>Invite a user to this team</div>
        </div>
        <SearchBar
          value={value}
          showSearch
          placeholder="Search user"
          fetchOptions={mainContext?.searchUsers!}
          onChange={(newValue) => {
            setValue([newValue] as UserValue[]);
          }}
          style={{ width: "100%" }}
        />
        <div style={{ height: 16 }} />
        <Button
          onClick={value.length === 0 ? () => {} : yesHandler}
          disabled={value.length === 0}
          size="sm"
          variant="dark"
          className={styles.invite_member_modal_submit_button}>
          {value.length === 0
            ? "Select an user"
            : `Invite ${
                value[0].label.toString().match(/\(([^)]+)\)/)![1]
              } to the team`}
        </Button>
      </div>
    </>,
    document.getElementById("portal")!
  );
};

export default InviteMemberModal;
