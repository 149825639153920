import { Tag } from "antd";
import React from "react";
import { LuConstruction } from "react-icons/lu";
import { CSSTransition } from "react-transition-group";
import { CgFeed } from "react-icons/cg";
import { MdConstruction } from "react-icons/md";

import styles from "./DocumentStandardizationTool.module.css";

const DocumentStandardizationTool: React.FC = () => {
  return (
    <CSSTransition
      key="document_standardization"
      in={true}
      timeout={260}
      classNames={{
        enter: styles.growEnter,
        enterActive: styles.growEnterActive,
        exit: styles.shrinkExit,
        exitActive: styles.shrinkExitActive,
      }}
      mountOnEnter
      unmountOnExit
    >
      <div className={styles.tool} key="document_standardization">
        <div className={styles.tool_title}>
          <div className={styles.ribbon}>
            <CgFeed />
          </div>
          <div style={{ fontSize: 24 }}>Document Standardization</div>
          <Tag className={styles.tool_construction} color="warning">
            <LuConstruction /> In Development
          </Tag>
        </div>
        <div className={styles.tool_content}>
          <MdConstruction /> Working on it ...
        </div>
      </div>
    </CSSTransition>
  );
};

export default DocumentStandardizationTool;
