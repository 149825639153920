import { LoadingOutlined } from "@ant-design/icons";
import type { ProColumns } from "@ant-design/pro-components";
import { Button, Empty, Popconfirm, Tooltip } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { MdDeleteOutline, MdEdit } from "react-icons/md";

import TeamsContext from "../../../../../../contexts/TeamsContext";
import equalObjects from "../../../../../../helpers/equalObjects";
import generateUUID from "../../../../../../helpers/generateUUID";
import EditableTable from "../EditableTable/EditableTable";
import styles from "./DocumentComparisonInputs.module.css";

import TypesContext from "../../../../../../contexts/ToolInputsContext";
import * as TypesService from "../../services/predefinedData.service";

interface DataSourceRegulationType {
  key: React.Key;
  id: React.Key;
  regulation: string;
}

interface DataSourceChecklistType {
  key: React.Key;
  id: React.Key;
  checklist: string;
}

const DocumentComparisonInputs: React.FC<{}> = (_) => {
  const teamsContext = useContext(TeamsContext);
  const typesContext = useContext(TypesContext);
  const [isLoading, setIsLoading] = useState(true);
  const [isRegulationTableLoading, setIsRegulationTableLoading] =
    useState(false);
  const [isChecklistTableLoading, setIsChecklistTableLoading] = useState(false);
  const [editableRegulationKeys, setEditableRegulationRowKeys] = useState<
    React.Key[]
  >([]);
  const [editableChecklistKeys, setEditableChecklistRowKeys] = useState<
    React.Key[]
  >([]);

  const columnsRegulation: ProColumns<DataSourceRegulationType>[] = [
    {
      title: "Regulation",
      dataIndex: "regulation",
      valueType: "text",
      formItemProps: () => ({
        rules: [
          {
            required: true,
            max: 100,
            message: "Invalid input: between 0 - 100 characters.",
          },
        ],
      }),
      fieldProps: () => ({ placeholder: "Regulation name" }),
      width: "90%",
    },
    {
      title: "Actions",
      valueType: "option",
      width: "10%",
      render: (text, record, _, action) => [
        <Tooltip key={`${record.id}_edit`} title="Edit" placement="left">
          <Button
            type="link"
            icon={<MdEdit color="var(--deloitte-green)" size="22" />}
            onClick={(e) => {
              e.preventDefault();
              action?.startEditable?.(record.id);
            }}
          />
        </Tooltip>,
        <Tooltip key={`${record.id}_delete`} title="Delete" placement="right">
          <Popconfirm
            title="Are you sure you want to delete this item?"
            okText="Yes"
            cancelText="No"
            onConfirm={async () => {
              setIsRegulationTableLoading(true);
              await TypesService?.deleteComparisonRegulationType(
                record.id.toString()
              );
              setIsRegulationTableLoading(false);
            }}>
            <Button
              type="link"
              icon={<MdDeleteOutline color="var(--beautiful-red)" size="22" />}
            />
          </Popconfirm>
        </Tooltip>,
      ],
      hidden: true,
    },
  ];

  const columnsChecklist: ProColumns<DataSourceChecklistType>[] = [
    {
      title: "Checklist",
      dataIndex: "checklist",
      valueType: "text",
      formItemProps: () => ({
        rules: [
          {
            required: true,
            max: 100,
            message: "Invalid input: between 0 - 100 characters.",
          },
        ],
      }),
      fieldProps: () => ({ placeholder: "Checklist name" }),
      width: "90%",
    },
    {
      title: "Actions",
      valueType: "option",
      width: "10%",
      render: (text, record, _, action) => [
        <Tooltip key={`${record.id}_edit`} title="Edit" placement="left">
          <Button
            type="link"
            icon={<MdEdit color="var(--deloitte-green)" size="22" />}
            onClick={(e) => {
              e.preventDefault();
              action?.startEditable?.(record.id);
            }}
          />
        </Tooltip>,
        <Tooltip key={`${record.id}_delete`} title="Delete" placement="right">
          <Popconfirm
            title="Are you sure you want to delete this item?"
            okText="Yes"
            cancelText="No"
            onConfirm={async () => {
              setIsChecklistTableLoading(true);
              await TypesService?.deleteComparisonChecklistType(
                record.id.toString()
              );
              setIsChecklistTableLoading(false);
            }}>
            <Button
              type="link"
              icon={<MdDeleteOutline color="var(--beautiful-red)" size="22" />}
            />
          </Popconfirm>
        </Tooltip>,
      ],
      hidden: true,
    },
  ];

  useEffect(() => {
    const fetchToolsInput = async () => {
      await typesContext?.fetchComparisonRegulations(
        teamsContext?.teamSelected?.id!
      );
      await typesContext?.fetchComparisonChecklists(
        teamsContext?.teamSelected?.id!
      );
      setIsLoading(false);
    };
    setIsLoading(true);
    fetchToolsInput();
  }, [teamsContext?.teamSelected]);

  return isLoading ? (
    <div
      style={{
        flex: 1,
        display: "flex",
        alignSelf: "center",
        justifyContent: "center",
      }}>
      <LoadingOutlined
        style={{ fontSize: 20, color: "var(--deloitte-green)" }}
      />
    </div>
  ) : (
    <div className={styles.content}>
      <div className={styles.item_content}>
        <div className={styles.item_title}>Regulations</div>
        <EditableTable<DataSourceRegulationType>
          className={styles.item}
          rowKey="id"
          showHeader
          tableStyle={{ top: 30 }}
          tableLayout="fixed"
          loading={isRegulationTableLoading}
          recordCreatorProps={
            false
              ? {
                  position: "bottom",
                  record: () => ({
                    id: generateUUID(),
                  }),
                  creatorButtonText: "Add new regulation type",
                }
              : false
          }
          columns={columnsRegulation}
          value={typesContext?.comparisonRegulationTypes.map(
            (regulationType, index) => ({
              key: index,
              id: regulationType.id,
              regulation: regulationType.name,
            })
          )}
          editable={{
            type: "multiple",
            editableKeys: editableRegulationKeys,
            onSave: async (key, data, row) => {
              const isNew = row.key === undefined;
              if (isNew) {
                setIsRegulationTableLoading(true);
                await TypesService?.createComparisonRegulationType(
                  {
                    name: data.regulation,
                  },
                  teamsContext?.teamSelected?.id!
                );
                setIsRegulationTableLoading(false);
                return;
              }
              if (equalObjects(data, row)) return;
              setIsRegulationTableLoading(true);
              await TypesService?.editComparisonRegulationType(
                data.id.toString(),
                { name: data.regulation }
              );
              setIsRegulationTableLoading(false);
            },
            onChange: setEditableRegulationRowKeys,
            onlyAddOneLineAlertMessage:
              "Please add only one item per table at a time.",
            cancelText: <div style={{ color: "#c7c7c7" }}>Cancel</div>,
            saveText: (
              <div style={{ color: "var(--deloitte-green)" }}>Save</div>
            ),
            actionRender: (_, __, dom) => [dom.save, dom.cancel],
          }}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="No regulation types yet"
              />
            ),
          }}
        />
      </div>
      <div className={styles.item_content}>
        <div className={styles.item_title}>Checklists</div>
        <EditableTable<DataSourceChecklistType>
          className={styles.item}
          rowKey="id"
          showHeader
          tableStyle={{ top: 30 }}
          tableLayout="fixed"
          loading={isChecklistTableLoading}
          recordCreatorProps={
            false
              ? {
                  position: "bottom",
                  record: () => ({
                    id: generateUUID(),
                  }),
                  creatorButtonText: "Add new checklist",
                }
              : false
          }
          columns={columnsChecklist}
          value={typesContext?.comparisonChecklistTypes.map(
            (checklistType, index) => ({
              key: index,
              id: checklistType.id,
              checklist: checklistType.name,
            })
          )}
          editable={{
            type: "multiple",
            editableKeys: editableChecklistKeys,
            onSave: async (key, data, row) => {
              const isNew = row.key === undefined;
              if (isNew) {
                setIsChecklistTableLoading(true);
                await TypesService?.createComparisonChecklistType(
                  {
                    name: data.checklist,
                  },
                  teamsContext?.teamSelected?.id!
                );
                setIsChecklistTableLoading(false);
                return;
              }
              if (equalObjects(data, row)) return;
              setIsChecklistTableLoading(true);
              await TypesService?.editComparisonChecklistType(
                data.id.toString(),
                { name: data.checklist }
              );
              setIsChecklistTableLoading(false);
            },
            onChange: setEditableChecklistRowKeys,
            onlyAddOneLineAlertMessage:
              "Please add only one item per table at a time.",
            cancelText: <div style={{ color: "#c7c7c7" }}>Cancel</div>,
            saveText: (
              <div style={{ color: "var(--deloitte-green)" }}>Save</div>
            ),
            actionRender: (_, __, dom) => [dom.save, dom.cancel],
          }}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="No checklists yet"
              />
            ),
          }}
        />
      </div>
    </div>
  );
};

export default DocumentComparisonInputs;
