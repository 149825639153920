import { LoadingOutlined, MoreOutlined } from "@ant-design/icons";
import { Button, Dropdown, List, Tooltip } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { FaUserGear } from "react-icons/fa6";

import AuthContext from "../../../../contexts/AuthContext";
import TeamsContext from "../../../../contexts/TeamsContext";

import { TeamMember } from "../../../../data/User";
import Bubble from "./components/Bubble/Bubble";
import GiveAdminModal from "./components/GiveAdminModal/GiveAdminModal";
import InviteMemberModal from "./components/InviteMemberModal/InviteMemberModal";
import LeaveTeamModal from "./components/LeaveTeamModal/LeaveTeamModal";
import RemoveMemberModal from "./components/RemoveMemberModal/RemoveMemberModal";
import { fetchTeamMembers } from "../../services/teams.service";
import styles from "./Members.module.css";

const Members: React.FC<{}> = ({}) => {
  const authContext = useContext(AuthContext);
  const teamsContext = useContext(TeamsContext);
  const [isLoading, setIsLoading] = useState(true);
  const [teamMembers, setTeamMembers] = useState<TeamMember[]>([]);
  const [showRemoveMemberModal, setShowRemoveMemberModal] = useState<
    TeamMember | undefined
  >(undefined);
  const [showLeaveTeamModal, setShowLeaveTeamModal] = useState(false);
  const [showGiveAdminModal, setShowGiveAdminModal] = useState<
    TeamMember | undefined
  >(undefined);
  const [showInviteMemberModal, setShowInviteMemberModal] = useState(false);

  const fetchMembers = async () => {
    if (teamsContext?.teamSelected && teamsContext?.teams.length !== 0) {
      setTeamMembers(await fetchTeamMembers(teamsContext.teamSelected.id));
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    fetchMembers();
  }, [teamsContext?.teamSelected]);

  const renderMembersLayout = () =>
    isLoading ? (
      <div
        style={{
          flex: 1,
          display: "flex",
          alignSelf: "center",
          justifyContent: "center",
        }}>
        <LoadingOutlined
          style={{ fontSize: 20, color: "var(--deloitte-green)" }}
        />
      </div>
    ) : (
      <div className={styles.members_tab_content}>
        <div className={styles.members_tab_content_title}>
          <div
            style={{
              fontSize: 24,
              fontWeight: "bold",
              padding: 20,
              paddingLeft: 40,
            }}>
            Members
          </div>
          {teamsContext?.teamSelected?.user_role === "Admin" && (
            <Button
              style={{ marginRight: 10 }}
              onClick={() => setShowInviteMemberModal(true)}
              type="primary">
              + Invite
            </Button>
          )}
          <Button
            onClick={async () =>
              setTeamMembers(
                await fetchTeamMembers(teamsContext?.teamSelected?.id!)
              )
            }>
            Refresh
          </Button>
        </div>
        <div className={styles.members_list}>
          <List
            pagination={false}
            dataSource={teamMembers}
            renderItem={(teamMember, _) => (
              <List.Item
                key={`${teamMember.id}`}
                style={{ paddingLeft: 20, paddingRight: 20 }}>
                <List.Item.Meta
                  style={{ alignItems: "center", justifyContent: "center" }}
                  avatar={
                    <Bubble
                      imageWidth={38}
                      imageHeight={38}
                      onClick={() => {}}
                      imageUrl={
                        teamMember.profilePicture ??
                        require("../../../../images/unknown_user_icon.jpg")
                      }
                      spaceBetweenImgAndTitle={0}
                      title=""
                      titleFontSize={0}></Bubble>
                  }
                  title={
                    <div style={{ display: "flex" }}>
                      {`${teamMember.fullname}`} <div style={{ width: 10 }} />{" "}
                      <div>
                        {teamMember.role === "Admin" ? (
                          <Tooltip placement="bottom" title="Admin">
                            <FaUserGear className={styles.admin_icon} />
                          </Tooltip>
                        ) : undefined}
                      </div>
                    </div>
                  }
                  description={teamMember.username}
                />
                {teamsContext?.teamSelected?.user_role === "Admin" ? (
                  <Dropdown
                    trigger={["click"]}
                    menu={{
                      items:
                        authContext?.user?.id === teamMember.id
                          ? [
                              {
                                key: "1",
                                label: (
                                  <div
                                    style={{ color: "var(--beautiful-red)" }}>
                                    Leave team
                                  </div>
                                ),
                                onClick: () => setShowLeaveTeamModal(true),
                              },
                            ]
                          : teamMember?.role !== "Admin"
                          ? [
                              {
                                key: "1",
                                label: <div>Make admin</div>,
                                onClick: () =>
                                  setShowGiveAdminModal(teamMember),
                              },
                              {
                                key: "2",
                                label: (
                                  <div
                                    style={{ color: "var(--beautiful-red)" }}>
                                    Uninvite member
                                  </div>
                                ),
                                onClick: () =>
                                  setShowRemoveMemberModal(teamMember),
                              },
                            ]
                          : [
                              {
                                key: "1",
                                label: (
                                  <div
                                    style={{ color: "var(--beautiful-red)" }}>
                                    Uninvite member
                                  </div>
                                ),
                                onClick: () =>
                                  setShowRemoveMemberModal(teamMember),
                              },
                            ],
                    }}
                    placement="bottomRight">
                    <Button type="text" icon={<MoreOutlined />}></Button>
                  </Dropdown>
                ) : authContext?.user?.id === teamMember.id ? (
                  <Dropdown
                    trigger={["click"]}
                    menu={{
                      items: [
                        {
                          key: "1",
                          label: (
                            <div style={{ color: "var(--beautiful-red)" }}>
                              Leave team
                            </div>
                          ),
                          onClick: () => setShowLeaveTeamModal(true),
                        },
                      ],
                    }}
                    placement="bottomRight">
                    <Button type="text" icon={<MoreOutlined />}></Button>
                  </Dropdown>
                ) : undefined}
              </List.Item>
            )}
          />
        </div>
      </div>
    );

  return (
    <div className={styles.content}>
      <div className={styles.body}>{renderMembersLayout()}</div>
      {showRemoveMemberModal ? (
        <RemoveMemberModal
          fetchMembers={fetchMembers}
          onClose={() => setShowRemoveMemberModal(undefined)}
          teamMember={showRemoveMemberModal}
        />
      ) : undefined}
      {showLeaveTeamModal ? (
        <LeaveTeamModal onClose={() => setShowLeaveTeamModal(false)} />
      ) : undefined}
      {showGiveAdminModal ? (
        <GiveAdminModal
          fetchMembers={fetchMembers}
          onClose={() => setShowGiveAdminModal(undefined)}
          teamMember={showGiveAdminModal}
        />
      ) : undefined}
      {showInviteMemberModal ? (
        <InviteMemberModal onClose={() => setShowInviteMemberModal(false)} />
      ) : undefined}
    </div>
  );
};

export default Members;
