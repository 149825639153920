import { Input, Modal, Tooltip } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { MdDeleteOutline, MdEdit } from "react-icons/md";
import MainContext from "../../../../../../contexts/MainContext";
import ToolsContext from "../../../../../../contexts/ToolsContext";
import Project from "../../../../../../data/Project";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./ProjectsList.module.css";

interface ProjectsListProps {
  onEdit: (p: Project) => void;
}

const ProjectsList: React.FC<ProjectsListProps> = ({ onEdit }) => {
  const { teamId, projectId } = useParams<{ teamId: string; projectId: string }>(); // Access params from the URL

  const mainContext = useContext(MainContext);
  const toolsContext = useContext(ToolsContext);
  const navigate = useNavigate();
  const [projectsByDate, setProjectsByDate] = useState<
    { period: string; data: Array<Project> }[]
  >([]);
  const [changeProject, setChangeProject] = useState<Project | undefined>(
    undefined
  );
  const [deleteProject, setDeleteProject] = useState<Project | undefined>(
    undefined
  );
  const [isDeleting, setIsDeleting] = useState(false);

  const [projectFilterText, setProjectFilterText] = useState("");

  useEffect(() => {
    getProjectsByDate();
  }, [mainContext?.projects, projectFilterText]);

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);

    if (date.toDateString() === today.toDateString()) {
      return "Today";
    } else if (date.toDateString() === yesterday.toDateString()) {
      return "Yesterday";
    } else {
      return "Older";
    }
  };

  const getProjectsByDate = () => {
    const projectsByPeriod: { period: string; data: Array<Project> }[] = [];

    mainContext?.projects.forEach((project: any) => {
      if (!project.name.toLowerCase().includes(projectFilterText.toLowerCase()))
        return;
      const period = `${formatDate(project.updated_at)}`;

      let periodGroup = projectsByPeriod.find(
        (group) => group.period === period
      );

      if (!periodGroup) {
        periodGroup = { period, data: [] };
        projectsByPeriod.push(periodGroup);
      }

      periodGroup.data.push(project);
    });

    setProjectsByDate(projectsByPeriod);
  };

  const handleChangeProjectModalOk = () => {
    mainContext?.setProjectSelected(changeProject);
    navigate(`/${mainContext?.teamSelected?.id}/${changeProject?.id}/tools`);
    setChangeProject(undefined);
  };

  const handleChangeProjectModalCancel = () => {
    setChangeProject(undefined);
  };

  const handleDeleteProjectModalOk = async () => {
    setIsDeleting(true);
    await mainContext?.deleteProject(deleteProject!.id);
    setDeleteProject(undefined);
    setIsDeleting(false);
  };

  const handleDeleteProjectModalCancel = () => {
    setDeleteProject(undefined);
  };

  return (
    <>
      <Input
        className={styles.search_input}
        value={projectFilterText}
        placeholder="Search projects"
        allowClear
        size="middle"
        onChange={(e) => {
          setProjectFilterText(e.target.value);
        }}
      />
      {mainContext?.projects.length === 0 ? (
        <p className={styles.projects_empty}>No projects yet</p>
      ) : (
        <div>
          {projectsByDate.map((group) => (
            <div key={group.period}>
              <p className={styles.period}>{group.period}</p>
              {group.data.map((project) => (
                <p
                  key={project.id}
                  className={`${styles.menu_item} ${
                    mainContext?.projectSelected?.id === project.id
                      ? styles.menu_item_selected
                      : ""
                  }`}
                  onClick={() => {
                    if (mainContext?.projectSelected?.id === project.id) return;
                    if (
                      toolsContext?.selectedFiles.length !== 0 ||
                      toolsContext.selectedTools.length !== 0
                    ) {
                      setChangeProject(project);
                    } else {
                     mainContext?.setProjectSelected(project);
                     navigate(`/${mainContext?.teamSelected?.id}/${project.id}/tools`);
                    }
                  }}
                >
                  <Tooltip title={project.description} placement="top">
                    <span className={styles.menu_item_content}>
                      {project.name}
                    </span>
                  </Tooltip>
                  <span
                    className={styles.menu_item_icon_wrapper}
                    onClick={(e) => {
                      onEdit(project);
                    }}
                  >
                    <MdEdit className={styles.menu_item_icon} />
                  </span>
                  <span
                    className={styles.menu_item_icon_wrapper}
                    onClick={(e) => {
                      e.stopPropagation();
                      setDeleteProject(project);
                    }}
                  >
                    <MdDeleteOutline className={styles.menu_item_icon} />
                  </span>
                </p>
              ))}
            </div>
          ))}
        </div>
      )}

      <Modal
        centered
        title="Are you sure you want to change the project?"
        open={!!changeProject}
        onOk={handleChangeProjectModalOk}
        onCancel={handleChangeProjectModalCancel}
        okText="Yes"
        cancelText="No"
      >
        <p>You have unsaved changes.</p>
      </Modal>

      <Modal
        centered
        title="Are you sure you want to continue?"
        open={!!deleteProject}
        onOk={handleDeleteProjectModalOk}
        confirmLoading={isDeleting}
        okText="Yes"
        okButtonProps={{ style: { backgroundColor: "var(--beautiful-red)" } }}
        onCancel={handleDeleteProjectModalCancel}
        cancelText="No"
        cancelButtonProps={{ disabled: isDeleting }}
      >
        <p>Project {deleteProject?.name} will be deleted!</p>
      </Modal>
    </>
  );
};

export default ProjectsList;
