import React, {Children, ReactNode} from "react";
import { useLocation } from "react-router-dom";
import { AuthProvider } from "./contexts/AuthContext";
import { MainProvider } from "./contexts/MainContext";
import { TeamsProvider } from "./contexts/TeamsContext";
import { TemplatesProvider } from "./contexts/TemplatesContext";
import { TypesProvider } from "./contexts/ToolInputsContext";
import { ResultsProvider } from "./contexts/ResultsContext";
import { ToolsProvider } from "./contexts/ToolsContext";
import MainPage from "./pages/MainPage/MainPage";


const App: React.FC<{ children: ReactNode }> = ({children}) => {
 

  return (
    <AuthProvider  >
      <MainProvider>
        <TeamsProvider>
          <TypesProvider>
            <TemplatesProvider>
              <ToolsProvider>
                <ResultsProvider>
                    {children}
                </ResultsProvider>
              </ToolsProvider>
            </TemplatesProvider>
          </TypesProvider>
        </TeamsProvider>
      </MainProvider>
    </AuthProvider>
  );
};

export default App;