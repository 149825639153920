const toneOptions = [
  {
    label: "Formal",
    value: 0,
    description: "For official and professional documents.",
  },
  {
    label: "Informal",
    value: 1,
    description: "For casual and friendly communication.",
  },
  {
    label: "Neutral",
    value: 2,
    description: "For a balanced and objective style.",
  },
  {
    label: "Persuasive",
    value: 3,
    description: "To convince or influence the reader.",
  },
  {
    label: "Concise",
    value: 4,
    description: "For clear and to-the-point communication.",
  },
  {
    label: "Descriptive",
    value: 5,
    description: "For detailed and vivid descriptions.",
  },
  {
    label: "Empathetic",
    value: 6,
    description: "To convey understanding and compassion.",
  },
];

export default toneOptions;
