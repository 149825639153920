import { InboxOutlined } from "@ant-design/icons";
import { Upload, UploadFile } from "antd";
import React from "react";

interface ComparisonForm {
  fileList: UploadFile<any>[];
  comparisonOption: { id: number; name: string } | undefined;
  // outputElements: {label: string; value: number;}[];
  clausesToCheck: string | undefined;
  language: string;
}

interface FileUploaderProps {
  documentType: string;
  formData: ComparisonForm;
  setFormData: (form: ComparisonForm) => void;
}

const FileUploader: React.FC<FileUploaderProps> = ({
  documentType,
  formData,
  setFormData,
}) => {
  return (
    <Upload.Dragger
      accept=".docx, .doc, .txt, .pdf, .pptx"
      fileList={formData.fileList}
      onChange={({ file }) => {
        const latestFileList = file ? [file] : [];
        setFormData({ ...formData, fileList: latestFileList });
      }}
      onRemove={() => {
        setFormData({ ...formData, fileList: [] });
        return false;
      }}
      customRequest={({ file, onSuccess }) => {
        setTimeout(() => {
          if (onSuccess) onSuccess("ok");
        }, 0);
      }}
      beforeUpload={() => false}
      showUploadList={{ showRemoveIcon: true }}
    >
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">
        Click or drag {documentType} file to this area to upload
      </p>
    </Upload.Dragger>
  );
};

export default FileUploader;
